export const photos = [
    {
        id: 4064305,
        width: 7940,
        height: 4981,
        url: "https://www.pexels.com/photo/macro-photography-of-green-leaf-4064305/",
        photographer: "Petr Ganaj",
        photographer_url: "https://www.pexels.com/@ganajp",
        photographer_id: 2392193,
        avg_color: "#70B601",
        src: {
            original:
                "https://images.pexels.com/photos/4064305/pexels-photo-4064305.jpeg",
            large2x:
                "https://images.pexels.com/photos/4064305/pexels-photo-4064305.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940",
            large: "https://images.pexels.com/photos/4064305/pexels-photo-4064305.jpeg?auto=compress&cs=tinysrgb&h=650&w=940",
            medium: "https://images.pexels.com/photos/4064305/pexels-photo-4064305.jpeg?auto=compress&cs=tinysrgb&h=350",
            small: "https://images.pexels.com/photos/4064305/pexels-photo-4064305.jpeg?auto=compress&cs=tinysrgb&h=130",
            portrait:
                "https://images.pexels.com/photos/4064305/pexels-photo-4064305.jpeg?auto=compress&cs=tinysrgb&fit=crop&h=1200&w=800",
            landscape:
                "https://images.pexels.com/photos/4064305/pexels-photo-4064305.jpeg?auto=compress&cs=tinysrgb&fit=crop&h=627&w=1200",
            tiny: "https://images.pexels.com/photos/4064305/pexels-photo-4064305.jpeg?auto=compress&cs=tinysrgb&dpr=1&fit=crop&h=200&w=280",
        },
        liked: false,
        alt: "Macro Photography of Green Leaf",
    },
    {
        id: 17379455,
        width: 7360,
        height: 4912,
        url: "https://www.pexels.com/photo/beautifully-textured-leaves-of-a-friendship-plant-17379455/",
        photographer: "David K",
        photographer_url: "https://www.pexels.com/@david-k-277044920",
        photographer_id: 277044920,
        avg_color: "#273416",
        src: {
            original:
                "https://images.pexels.com/photos/17379455/pexels-photo-17379455.jpeg",
            large2x:
                "https://images.pexels.com/photos/17379455/pexels-photo-17379455.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940",
            large: "https://images.pexels.com/photos/17379455/pexels-photo-17379455.jpeg?auto=compress&cs=tinysrgb&h=650&w=940",
            medium: "https://images.pexels.com/photos/17379455/pexels-photo-17379455.jpeg?auto=compress&cs=tinysrgb&h=350",
            small: "https://images.pexels.com/photos/17379455/pexels-photo-17379455.jpeg?auto=compress&cs=tinysrgb&h=130",
            portrait:
                "https://images.pexels.com/photos/17379455/pexels-photo-17379455.jpeg?auto=compress&cs=tinysrgb&fit=crop&h=1200&w=800",
            landscape:
                "https://images.pexels.com/photos/17379455/pexels-photo-17379455.jpeg?auto=compress&cs=tinysrgb&fit=crop&h=627&w=1200",
            tiny: "https://images.pexels.com/photos/17379455/pexels-photo-17379455.jpeg?auto=compress&cs=tinysrgb&dpr=1&fit=crop&h=200&w=280",
        },
        liked: false,
        alt: "nettle",
    },
    {
        id: 17366260,
        width: 7008,
        height: 4672,
        url: "https://www.pexels.com/photo/black-and-white-bokeh-lights-17366260/",
        photographer: "ALTEREDSNAPS",
        photographer_url: "https://www.pexels.com/@alteredsnaps",
        photographer_id: 198854184,
        avg_color: "#6B7073",
        src: {
            original:
                "https://images.pexels.com/photos/17366260/pexels-photo-17366260.jpeg",
            large2x:
                "https://images.pexels.com/photos/17366260/pexels-photo-17366260.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940",
            large: "https://images.pexels.com/photos/17366260/pexels-photo-17366260.jpeg?auto=compress&cs=tinysrgb&h=650&w=940",
            medium: "https://images.pexels.com/photos/17366260/pexels-photo-17366260.jpeg?auto=compress&cs=tinysrgb&h=350",
            small: "https://images.pexels.com/photos/17366260/pexels-photo-17366260.jpeg?auto=compress&cs=tinysrgb&h=130",
            portrait:
                "https://images.pexels.com/photos/17366260/pexels-photo-17366260.jpeg?auto=compress&cs=tinysrgb&fit=crop&h=1200&w=800",
            landscape:
                "https://images.pexels.com/photos/17366260/pexels-photo-17366260.jpeg?auto=compress&cs=tinysrgb&fit=crop&h=627&w=1200",
            tiny: "https://images.pexels.com/photos/17366260/pexels-photo-17366260.jpeg?auto=compress&cs=tinysrgb&dpr=1&fit=crop&h=200&w=280",
        },
        liked: false,
        alt: "Free stock photo of abstract, art, blur",
    },
    {
        id: 36717,
        width: 1920,
        height: 1195,
        url: "https://www.pexels.com/photo/silhouette-of-tree-near-body-of-water-during-golden-hour-36717/",
        photographer: "Pixabay",
        photographer_url: "https://www.pexels.com/@pixabay",
        photographer_id: 2659,
        avg_color: "#4A1E33",
        src: {
            original:
                "https://images.pexels.com/photos/36717/amazing-animal-beautiful-beautifull.jpg",
            large2x:
                "https://images.pexels.com/photos/36717/amazing-animal-beautiful-beautifull.jpg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940",
            large: "https://images.pexels.com/photos/36717/amazing-animal-beautiful-beautifull.jpg?auto=compress&cs=tinysrgb&h=650&w=940",
            medium: "https://images.pexels.com/photos/36717/amazing-animal-beautiful-beautifull.jpg?auto=compress&cs=tinysrgb&h=350",
            small: "https://images.pexels.com/photos/36717/amazing-animal-beautiful-beautifull.jpg?auto=compress&cs=tinysrgb&h=130",
            portrait:
                "https://images.pexels.com/photos/36717/amazing-animal-beautiful-beautifull.jpg?auto=compress&cs=tinysrgb&fit=crop&h=1200&w=800",
            landscape:
                "https://images.pexels.com/photos/36717/amazing-animal-beautiful-beautifull.jpg?auto=compress&cs=tinysrgb&fit=crop&h=627&w=1200",
            tiny: "https://images.pexels.com/photos/36717/amazing-animal-beautiful-beautifull.jpg?auto=compress&cs=tinysrgb&dpr=1&fit=crop&h=200&w=280",
        },
        liked: false,
        alt: "Silhouette of Tree Near Body of Water during Golden Hour",
    },
    {
        id: 15286,
        width: 2500,
        height: 1667,
        url: "https://www.pexels.com/photo/person-walking-between-green-forest-trees-15286/",
        photographer: "Luis del Río",
        photographer_url: "https://www.pexels.com/@luisdelrio",
        photographer_id: 1081,
        avg_color: "#283419",
        src: {
            original: "https://images.pexels.com/photos/15286/pexels-photo.jpg",
            large2x:
                "https://images.pexels.com/photos/15286/pexels-photo.jpg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940",
            large: "https://images.pexels.com/photos/15286/pexels-photo.jpg?auto=compress&cs=tinysrgb&h=650&w=940",
            medium: "https://images.pexels.com/photos/15286/pexels-photo.jpg?auto=compress&cs=tinysrgb&h=350",
            small: "https://images.pexels.com/photos/15286/pexels-photo.jpg?auto=compress&cs=tinysrgb&h=130",
            portrait:
                "https://images.pexels.com/photos/15286/pexels-photo.jpg?auto=compress&cs=tinysrgb&fit=crop&h=1200&w=800",
            landscape:
                "https://images.pexels.com/photos/15286/pexels-photo.jpg?auto=compress&cs=tinysrgb&fit=crop&h=627&w=1200",
            tiny: "https://images.pexels.com/photos/15286/pexels-photo.jpg?auto=compress&cs=tinysrgb&dpr=1&fit=crop&h=200&w=280",
        },
        liked: false,
        alt: "Person Walking Between Green Forest Trees",
    },
    {
        id: 268533,
        width: 1920,
        height: 1278,
        url: "https://www.pexels.com/photo/green-tree-268533/",
        photographer: "Pixabay",
        photographer_url: "https://www.pexels.com/@pixabay",
        photographer_id: 2659,
        avg_color: "#414D62",
        src: {
            original:
                "https://images.pexels.com/photos/268533/pexels-photo-268533.jpeg",
            large2x:
                "https://images.pexels.com/photos/268533/pexels-photo-268533.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940",
            large: "https://images.pexels.com/photos/268533/pexels-photo-268533.jpeg?auto=compress&cs=tinysrgb&h=650&w=940",
            medium: "https://images.pexels.com/photos/268533/pexels-photo-268533.jpeg?auto=compress&cs=tinysrgb&h=350",
            small: "https://images.pexels.com/photos/268533/pexels-photo-268533.jpeg?auto=compress&cs=tinysrgb&h=130",
            portrait:
                "https://images.pexels.com/photos/268533/pexels-photo-268533.jpeg?auto=compress&cs=tinysrgb&fit=crop&h=1200&w=800",
            landscape:
                "https://images.pexels.com/photos/268533/pexels-photo-268533.jpeg?auto=compress&cs=tinysrgb&fit=crop&h=627&w=1200",
            tiny: "https://images.pexels.com/photos/268533/pexels-photo-268533.jpeg?auto=compress&cs=tinysrgb&dpr=1&fit=crop&h=200&w=280",
        },
        liked: false,
        alt: "Green Tree",
    },
    {
        id: 1287145,
        width: 3648,
        height: 2432,
        url: "https://www.pexels.com/photo/snowy-mountain-1287145/",
        photographer: "eberhard grossgasteiger",
        photographer_url: "https://www.pexels.com/@eberhardgross",
        photographer_id: 121938,
        avg_color: "#8F9A9F",
        src: {
            original:
                "https://images.pexels.com/photos/1287145/pexels-photo-1287145.jpeg",
            large2x:
                "https://images.pexels.com/photos/1287145/pexels-photo-1287145.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940",
            large: "https://images.pexels.com/photos/1287145/pexels-photo-1287145.jpeg?auto=compress&cs=tinysrgb&h=650&w=940",
            medium: "https://images.pexels.com/photos/1287145/pexels-photo-1287145.jpeg?auto=compress&cs=tinysrgb&h=350",
            small: "https://images.pexels.com/photos/1287145/pexels-photo-1287145.jpeg?auto=compress&cs=tinysrgb&h=130",
            portrait:
                "https://images.pexels.com/photos/1287145/pexels-photo-1287145.jpeg?auto=compress&cs=tinysrgb&fit=crop&h=1200&w=800",
            landscape:
                "https://images.pexels.com/photos/1287145/pexels-photo-1287145.jpeg?auto=compress&cs=tinysrgb&fit=crop&h=627&w=1200",
            tiny: "https://images.pexels.com/photos/1287145/pexels-photo-1287145.jpeg?auto=compress&cs=tinysrgb&dpr=1&fit=crop&h=200&w=280",
        },
        liked: false,
        alt: "Snowy Mountain",
    },
    {
        id: 4737484,
        width: 4288,
        height: 2848,
        url: "https://www.pexels.com/photo/blue-and-white-sky-with-stars-4737484/",
        photographer: "Rafael Cerqueira",
        photographer_url: "https://www.pexels.com/@incrediblerafa",
        photographer_id: 1449589,
        avg_color: "#0B3C69",
        src: {
            original:
                "https://images.pexels.com/photos/4737484/pexels-photo-4737484.jpeg",
            large2x:
                "https://images.pexels.com/photos/4737484/pexels-photo-4737484.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940",
            large: "https://images.pexels.com/photos/4737484/pexels-photo-4737484.jpeg?auto=compress&cs=tinysrgb&h=650&w=940",
            medium: "https://images.pexels.com/photos/4737484/pexels-photo-4737484.jpeg?auto=compress&cs=tinysrgb&h=350",
            small: "https://images.pexels.com/photos/4737484/pexels-photo-4737484.jpeg?auto=compress&cs=tinysrgb&h=130",
            portrait:
                "https://images.pexels.com/photos/4737484/pexels-photo-4737484.jpeg?auto=compress&cs=tinysrgb&fit=crop&h=1200&w=800",
            landscape:
                "https://images.pexels.com/photos/4737484/pexels-photo-4737484.jpeg?auto=compress&cs=tinysrgb&fit=crop&h=627&w=1200",
            tiny: "https://images.pexels.com/photos/4737484/pexels-photo-4737484.jpeg?auto=compress&cs=tinysrgb&dpr=1&fit=crop&h=200&w=280",
        },
        liked: false,
        alt: "Blue and White Sky With Stars",
    },
    {
        id: 2150,
        width: 2500,
        height: 1794,
        url: "https://www.pexels.com/photo/gray-and-black-galaxy-wallpaper-2150/",
        photographer: "Pixabay",
        photographer_url: "https://www.pexels.com/@pixabay",
        photographer_id: 2659,
        avg_color: "#383539",
        src: {
            original:
                "https://images.pexels.com/photos/2150/sky-space-dark-galaxy.jpg",
            large2x:
                "https://images.pexels.com/photos/2150/sky-space-dark-galaxy.jpg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940",
            large: "https://images.pexels.com/photos/2150/sky-space-dark-galaxy.jpg?auto=compress&cs=tinysrgb&h=650&w=940",
            medium: "https://images.pexels.com/photos/2150/sky-space-dark-galaxy.jpg?auto=compress&cs=tinysrgb&h=350",
            small: "https://images.pexels.com/photos/2150/sky-space-dark-galaxy.jpg?auto=compress&cs=tinysrgb&h=130",
            portrait:
                "https://images.pexels.com/photos/2150/sky-space-dark-galaxy.jpg?auto=compress&cs=tinysrgb&fit=crop&h=1200&w=800",
            landscape:
                "https://images.pexels.com/photos/2150/sky-space-dark-galaxy.jpg?auto=compress&cs=tinysrgb&fit=crop&h=627&w=1200",
            tiny: "https://images.pexels.com/photos/2150/sky-space-dark-galaxy.jpg?auto=compress&cs=tinysrgb&dpr=1&fit=crop&h=200&w=280",
        },
        liked: false,
        alt: "Gray and Black Galaxy Wallpaper",
    },
    {
        id: 624015,
        width: 4216,
        height: 2848,
        url: "https://www.pexels.com/photo/aurora-borealis-624015/",
        photographer: "Frans van Heerden",
        photographer_url: "https://www.pexels.com/@frans-van-heerden-201846",
        photographer_id: 201846,
        avg_color: "#71839F",
        src: {
            original:
                "https://images.pexels.com/photos/624015/pexels-photo-624015.jpeg",
            large2x:
                "https://images.pexels.com/photos/624015/pexels-photo-624015.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940",
            large: "https://images.pexels.com/photos/624015/pexels-photo-624015.jpeg?auto=compress&cs=tinysrgb&h=650&w=940",
            medium: "https://images.pexels.com/photos/624015/pexels-photo-624015.jpeg?auto=compress&cs=tinysrgb&h=350",
            small: "https://images.pexels.com/photos/624015/pexels-photo-624015.jpeg?auto=compress&cs=tinysrgb&h=130",
            portrait:
                "https://images.pexels.com/photos/624015/pexels-photo-624015.jpeg?auto=compress&cs=tinysrgb&fit=crop&h=1200&w=800",
            landscape:
                "https://images.pexels.com/photos/624015/pexels-photo-624015.jpeg?auto=compress&cs=tinysrgb&fit=crop&h=627&w=1200",
            tiny: "https://images.pexels.com/photos/624015/pexels-photo-624015.jpeg?auto=compress&cs=tinysrgb&dpr=1&fit=crop&h=200&w=280",
        },
        liked: false,
        alt: "Aurora Borealis",
    },
    {
        id: 2387793,
        width: 6000,
        height: 4000,
        url: "https://www.pexels.com/photo/black-sand-dunes-2387793/",
        photographer: "Adrien Olichon",
        photographer_url: "https://www.pexels.com/@adrien-olichon-1257089",
        photographer_id: 1257089,
        avg_color: "#131313",
        src: {
            original:
                "https://images.pexels.com/photos/2387793/pexels-photo-2387793.jpeg",
            large2x:
                "https://images.pexels.com/photos/2387793/pexels-photo-2387793.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940",
            large: "https://images.pexels.com/photos/2387793/pexels-photo-2387793.jpeg?auto=compress&cs=tinysrgb&h=650&w=940",
            medium: "https://images.pexels.com/photos/2387793/pexels-photo-2387793.jpeg?auto=compress&cs=tinysrgb&h=350",
            small: "https://images.pexels.com/photos/2387793/pexels-photo-2387793.jpeg?auto=compress&cs=tinysrgb&h=130",
            portrait:
                "https://images.pexels.com/photos/2387793/pexels-photo-2387793.jpeg?auto=compress&cs=tinysrgb&fit=crop&h=1200&w=800",
            landscape:
                "https://images.pexels.com/photos/2387793/pexels-photo-2387793.jpeg?auto=compress&cs=tinysrgb&fit=crop&h=627&w=1200",
            tiny: "https://images.pexels.com/photos/2387793/pexels-photo-2387793.jpeg?auto=compress&cs=tinysrgb&dpr=1&fit=crop&h=200&w=280",
        },
        liked: false,
        alt: "Black Sand Dunes",
    },
    {
        id: 1072179,
        width: 4042,
        height: 2695,
        url: "https://www.pexels.com/photo/green-leaves-1072179/",
        photographer: "Cátia Matos",
        photographer_url: "https://www.pexels.com/@catiamatos",
        photographer_id: 363003,
        avg_color: "#111B19",
        src: {
            original:
                "https://images.pexels.com/photos/1072179/pexels-photo-1072179.jpeg",
            large2x:
                "https://images.pexels.com/photos/1072179/pexels-photo-1072179.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940",
            large: "https://images.pexels.com/photos/1072179/pexels-photo-1072179.jpeg?auto=compress&cs=tinysrgb&h=650&w=940",
            medium: "https://images.pexels.com/photos/1072179/pexels-photo-1072179.jpeg?auto=compress&cs=tinysrgb&h=350",
            small: "https://images.pexels.com/photos/1072179/pexels-photo-1072179.jpeg?auto=compress&cs=tinysrgb&h=130",
            portrait:
                "https://images.pexels.com/photos/1072179/pexels-photo-1072179.jpeg?auto=compress&cs=tinysrgb&fit=crop&h=1200&w=800",
            landscape:
                "https://images.pexels.com/photos/1072179/pexels-photo-1072179.jpeg?auto=compress&cs=tinysrgb&fit=crop&h=627&w=1200",
            tiny: "https://images.pexels.com/photos/1072179/pexels-photo-1072179.jpeg?auto=compress&cs=tinysrgb&dpr=1&fit=crop&h=200&w=280",
        },
        liked: false,
        alt: "Green Leaves",
    },
    {
        id: 325185,
        width: 5472,
        height: 3648,
        url: "https://www.pexels.com/photo/view-of-cityscape-325185/",
        photographer: "Aleksandar Pasaric",
        photographer_url: "https://www.pexels.com/@apasaric",
        photographer_id: 105079,
        avg_color: "#CBD0D6",
        src: {
            original:
                "https://images.pexels.com/photos/325185/pexels-photo-325185.jpeg",
            large2x:
                "https://images.pexels.com/photos/325185/pexels-photo-325185.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940",
            large: "https://images.pexels.com/photos/325185/pexels-photo-325185.jpeg?auto=compress&cs=tinysrgb&h=650&w=940",
            medium: "https://images.pexels.com/photos/325185/pexels-photo-325185.jpeg?auto=compress&cs=tinysrgb&h=350",
            small: "https://images.pexels.com/photos/325185/pexels-photo-325185.jpeg?auto=compress&cs=tinysrgb&h=130",
            portrait:
                "https://images.pexels.com/photos/325185/pexels-photo-325185.jpeg?auto=compress&cs=tinysrgb&fit=crop&h=1200&w=800",
            landscape:
                "https://images.pexels.com/photos/325185/pexels-photo-325185.jpeg?auto=compress&cs=tinysrgb&fit=crop&h=627&w=1200",
            tiny: "https://images.pexels.com/photos/325185/pexels-photo-325185.jpeg?auto=compress&cs=tinysrgb&dpr=1&fit=crop&h=200&w=280",
        },
        liked: false,
        alt: "View of Cityscape",
    },
    {
        id: 2559941,
        width: 5472,
        height: 3648,
        url: "https://www.pexels.com/photo/brown-mountains-2559941/",
        photographer: "Roberto Nickson",
        photographer_url: "https://www.pexels.com/@rpnickson",
        photographer_id: 1268114,
        avg_color: "#58524A",
        src: {
            original:
                "https://images.pexels.com/photos/2559941/pexels-photo-2559941.jpeg",
            large2x:
                "https://images.pexels.com/photos/2559941/pexels-photo-2559941.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940",
            large: "https://images.pexels.com/photos/2559941/pexels-photo-2559941.jpeg?auto=compress&cs=tinysrgb&h=650&w=940",
            medium: "https://images.pexels.com/photos/2559941/pexels-photo-2559941.jpeg?auto=compress&cs=tinysrgb&h=350",
            small: "https://images.pexels.com/photos/2559941/pexels-photo-2559941.jpeg?auto=compress&cs=tinysrgb&h=130",
            portrait:
                "https://images.pexels.com/photos/2559941/pexels-photo-2559941.jpeg?auto=compress&cs=tinysrgb&fit=crop&h=1200&w=800",
            landscape:
                "https://images.pexels.com/photos/2559941/pexels-photo-2559941.jpeg?auto=compress&cs=tinysrgb&fit=crop&h=627&w=1200",
            tiny: "https://images.pexels.com/photos/2559941/pexels-photo-2559941.jpeg?auto=compress&cs=tinysrgb&dpr=1&fit=crop&h=200&w=280",
        },
        liked: false,
        alt: "Brown Mountains",
    },
    {
        id: 3293148,
        width: 5832,
        height: 3888,
        url: "https://www.pexels.com/photo/silhouette-of-a-person-on-a-swing-3293148/",
        photographer: "Asad Photo Maldives",
        photographer_url: "https://www.pexels.com/@asadphotography",
        photographer_id: 45786,
        avg_color: "#374962",
        src: {
            original:
                "https://images.pexels.com/photos/3293148/pexels-photo-3293148.jpeg",
            large2x:
                "https://images.pexels.com/photos/3293148/pexels-photo-3293148.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940",
            large: "https://images.pexels.com/photos/3293148/pexels-photo-3293148.jpeg?auto=compress&cs=tinysrgb&h=650&w=940",
            medium: "https://images.pexels.com/photos/3293148/pexels-photo-3293148.jpeg?auto=compress&cs=tinysrgb&h=350",
            small: "https://images.pexels.com/photos/3293148/pexels-photo-3293148.jpeg?auto=compress&cs=tinysrgb&h=130",
            portrait:
                "https://images.pexels.com/photos/3293148/pexels-photo-3293148.jpeg?auto=compress&cs=tinysrgb&fit=crop&h=1200&w=800",
            landscape:
                "https://images.pexels.com/photos/3293148/pexels-photo-3293148.jpeg?auto=compress&cs=tinysrgb&fit=crop&h=627&w=1200",
            tiny: "https://images.pexels.com/photos/3293148/pexels-photo-3293148.jpeg?auto=compress&cs=tinysrgb&dpr=1&fit=crop&h=200&w=280",
        },
        liked: false,
        alt: "Silhouette Of A Person On A Swing",
    },
    {
        id: 33041,
        width: 4167,
        height: 2541,
        url: "https://www.pexels.com/photo/antelope-canyon-33041/",
        photographer: "Paul IJsendoorn",
        photographer_url: "https://www.pexels.com/@photospublic",
        photographer_id: 148531,
        avg_color: "#7F4C3E",
        src: {
            original:
                "https://images.pexels.com/photos/33041/antelope-canyon-lower-canyon-arizona.jpg",
            large2x:
                "https://images.pexels.com/photos/33041/antelope-canyon-lower-canyon-arizona.jpg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940",
            large: "https://images.pexels.com/photos/33041/antelope-canyon-lower-canyon-arizona.jpg?auto=compress&cs=tinysrgb&h=650&w=940",
            medium: "https://images.pexels.com/photos/33041/antelope-canyon-lower-canyon-arizona.jpg?auto=compress&cs=tinysrgb&h=350",
            small: "https://images.pexels.com/photos/33041/antelope-canyon-lower-canyon-arizona.jpg?auto=compress&cs=tinysrgb&h=130",
            portrait:
                "https://images.pexels.com/photos/33041/antelope-canyon-lower-canyon-arizona.jpg?auto=compress&cs=tinysrgb&fit=crop&h=1200&w=800",
            landscape:
                "https://images.pexels.com/photos/33041/antelope-canyon-lower-canyon-arizona.jpg?auto=compress&cs=tinysrgb&fit=crop&h=627&w=1200",
            tiny: "https://images.pexels.com/photos/33041/antelope-canyon-lower-canyon-arizona.jpg?auto=compress&cs=tinysrgb&dpr=1&fit=crop&h=200&w=280",
        },
        liked: false,
        alt: "Antelope Canyon",
    },
    {
        id: 924824,
        width: 6144,
        height: 4096,
        url: "https://www.pexels.com/photo/time-lapse-photo-of-stars-on-night-924824/",
        photographer: "Jakub Novacek",
        photographer_url: "https://www.pexels.com/@kubiceknov",
        photographer_id: 172969,
        avg_color: "#222028",
        src: {
            original:
                "https://images.pexels.com/photos/924824/pexels-photo-924824.jpeg",
            large2x:
                "https://images.pexels.com/photos/924824/pexels-photo-924824.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940",
            large: "https://images.pexels.com/photos/924824/pexels-photo-924824.jpeg?auto=compress&cs=tinysrgb&h=650&w=940",
            medium: "https://images.pexels.com/photos/924824/pexels-photo-924824.jpeg?auto=compress&cs=tinysrgb&h=350",
            small: "https://images.pexels.com/photos/924824/pexels-photo-924824.jpeg?auto=compress&cs=tinysrgb&h=130",
            portrait:
                "https://images.pexels.com/photos/924824/pexels-photo-924824.jpeg?auto=compress&cs=tinysrgb&fit=crop&h=1200&w=800",
            landscape:
                "https://images.pexels.com/photos/924824/pexels-photo-924824.jpeg?auto=compress&cs=tinysrgb&fit=crop&h=627&w=1200",
            tiny: "https://images.pexels.com/photos/924824/pexels-photo-924824.jpeg?auto=compress&cs=tinysrgb&dpr=1&fit=crop&h=200&w=280",
        },
        liked: false,
        alt: "Time Lapse Photo of Stars on Night",
    },
    {
        id: 2387873,
        width: 5472,
        height: 3648,
        url: "https://www.pexels.com/photo/three-men-standing-near-waterfalls-2387873/",
        photographer: "Sam Kolder",
        photographer_url: "https://www.pexels.com/@samkolder",
        photographer_id: 1257221,
        avg_color: "#5A5659",
        src: {
            original:
                "https://images.pexels.com/photos/2387873/pexels-photo-2387873.jpeg",
            large2x:
                "https://images.pexels.com/photos/2387873/pexels-photo-2387873.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940",
            large: "https://images.pexels.com/photos/2387873/pexels-photo-2387873.jpeg?auto=compress&cs=tinysrgb&h=650&w=940",
            medium: "https://images.pexels.com/photos/2387873/pexels-photo-2387873.jpeg?auto=compress&cs=tinysrgb&h=350",
            small: "https://images.pexels.com/photos/2387873/pexels-photo-2387873.jpeg?auto=compress&cs=tinysrgb&h=130",
            portrait:
                "https://images.pexels.com/photos/2387873/pexels-photo-2387873.jpeg?auto=compress&cs=tinysrgb&fit=crop&h=1200&w=800",
            landscape:
                "https://images.pexels.com/photos/2387873/pexels-photo-2387873.jpeg?auto=compress&cs=tinysrgb&fit=crop&h=627&w=1200",
            tiny: "https://images.pexels.com/photos/2387873/pexels-photo-2387873.jpeg?auto=compress&cs=tinysrgb&dpr=1&fit=crop&h=200&w=280",
        },
        liked: false,
        alt: "Three Men Standing Near Waterfalls",
    },
    {
        id: 1631677,
        width: 4469,
        height: 2979,
        url: "https://www.pexels.com/photo/calm-body-of-water-during-golden-hour-1631677/",
        photographer: "Abdullah Ghatasheh",
        photographer_url: "https://www.pexels.com/@abdghat",
        photographer_id: 770062,
        avg_color: "#918F97",
        src: {
            original:
                "https://images.pexels.com/photos/1631677/pexels-photo-1631677.jpeg",
            large2x:
                "https://images.pexels.com/photos/1631677/pexels-photo-1631677.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940",
            large: "https://images.pexels.com/photos/1631677/pexels-photo-1631677.jpeg?auto=compress&cs=tinysrgb&h=650&w=940",
            medium: "https://images.pexels.com/photos/1631677/pexels-photo-1631677.jpeg?auto=compress&cs=tinysrgb&h=350",
            small: "https://images.pexels.com/photos/1631677/pexels-photo-1631677.jpeg?auto=compress&cs=tinysrgb&h=130",
            portrait:
                "https://images.pexels.com/photos/1631677/pexels-photo-1631677.jpeg?auto=compress&cs=tinysrgb&fit=crop&h=1200&w=800",
            landscape:
                "https://images.pexels.com/photos/1631677/pexels-photo-1631677.jpeg?auto=compress&cs=tinysrgb&fit=crop&h=627&w=1200",
            tiny: "https://images.pexels.com/photos/1631677/pexels-photo-1631677.jpeg?auto=compress&cs=tinysrgb&dpr=1&fit=crop&h=200&w=280",
        },
        liked: false,
        alt: "Calm Body Of Water During Golden Hour",
    },
    {
        id: 956999,
        width: 6472,
        height: 3641,
        url: "https://www.pexels.com/photo/blue-and-purple-cosmic-sky-956999/",
        photographer: "Felix Mittermeier",
        photographer_url: "https://www.pexels.com/@felixmittermeier",
        photographer_id: 344572,
        avg_color: "#1C283D",
        src: {
            original:
                "https://images.pexels.com/photos/956999/milky-way-starry-sky-night-sky-star-956999.jpeg",
            large2x:
                "https://images.pexels.com/photos/956999/milky-way-starry-sky-night-sky-star-956999.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940",
            large: "https://images.pexels.com/photos/956999/milky-way-starry-sky-night-sky-star-956999.jpeg?auto=compress&cs=tinysrgb&h=650&w=940",
            medium: "https://images.pexels.com/photos/956999/milky-way-starry-sky-night-sky-star-956999.jpeg?auto=compress&cs=tinysrgb&h=350",
            small: "https://images.pexels.com/photos/956999/milky-way-starry-sky-night-sky-star-956999.jpeg?auto=compress&cs=tinysrgb&h=130",
            portrait:
                "https://images.pexels.com/photos/956999/milky-way-starry-sky-night-sky-star-956999.jpeg?auto=compress&cs=tinysrgb&fit=crop&h=1200&w=800",
            landscape:
                "https://images.pexels.com/photos/956999/milky-way-starry-sky-night-sky-star-956999.jpeg?auto=compress&cs=tinysrgb&fit=crop&h=627&w=1200",
            tiny: "https://images.pexels.com/photos/956999/milky-way-starry-sky-night-sky-star-956999.jpeg?auto=compress&cs=tinysrgb&dpr=1&fit=crop&h=200&w=280",
        },
        liked: false,
        alt: "Blue and Purple Cosmic Sky",
    },
    {
        id: 289586,
        width: 3500,
        height: 2129,
        url: "https://www.pexels.com/photo/stacked-of-stones-outdoors-289586/",
        photographer: "Pixabay",
        photographer_url: "https://www.pexels.com/@pixabay",
        photographer_id: 2659,
        avg_color: "#726F75",
        src: {
            original:
                "https://images.pexels.com/photos/289586/pexels-photo-289586.jpeg",
            large2x:
                "https://images.pexels.com/photos/289586/pexels-photo-289586.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940",
            large: "https://images.pexels.com/photos/289586/pexels-photo-289586.jpeg?auto=compress&cs=tinysrgb&h=650&w=940",
            medium: "https://images.pexels.com/photos/289586/pexels-photo-289586.jpeg?auto=compress&cs=tinysrgb&h=350",
            small: "https://images.pexels.com/photos/289586/pexels-photo-289586.jpeg?auto=compress&cs=tinysrgb&h=130",
            portrait:
                "https://images.pexels.com/photos/289586/pexels-photo-289586.jpeg?auto=compress&cs=tinysrgb&fit=crop&h=1200&w=800",
            landscape:
                "https://images.pexels.com/photos/289586/pexels-photo-289586.jpeg?auto=compress&cs=tinysrgb&fit=crop&h=627&w=1200",
            tiny: "https://images.pexels.com/photos/289586/pexels-photo-289586.jpeg?auto=compress&cs=tinysrgb&dpr=1&fit=crop&h=200&w=280",
        },
        liked: false,
        alt: "Stacked of Stones Outdoors",
    },
    {
        id: 884788,
        width: 4608,
        height: 3072,
        url: "https://www.pexels.com/photo/full-moon-on-a-daybreak-884788/",
        photographer: "David Besh",
        photographer_url: "https://www.pexels.com/@david-besh",
        photographer_id: 31713,
        avg_color: "#68645B",
        src: {
            original:
                "https://images.pexels.com/photos/884788/pexels-photo-884788.jpeg",
            large2x:
                "https://images.pexels.com/photos/884788/pexels-photo-884788.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940",
            large: "https://images.pexels.com/photos/884788/pexels-photo-884788.jpeg?auto=compress&cs=tinysrgb&h=650&w=940",
            medium: "https://images.pexels.com/photos/884788/pexels-photo-884788.jpeg?auto=compress&cs=tinysrgb&h=350",
            small: "https://images.pexels.com/photos/884788/pexels-photo-884788.jpeg?auto=compress&cs=tinysrgb&h=130",
            portrait:
                "https://images.pexels.com/photos/884788/pexels-photo-884788.jpeg?auto=compress&cs=tinysrgb&fit=crop&h=1200&w=800",
            landscape:
                "https://images.pexels.com/photos/884788/pexels-photo-884788.jpeg?auto=compress&cs=tinysrgb&fit=crop&h=627&w=1200",
            tiny: "https://images.pexels.com/photos/884788/pexels-photo-884788.jpeg?auto=compress&cs=tinysrgb&dpr=1&fit=crop&h=200&w=280",
        },
        liked: false,
        alt: "Full Moon on a Daybreak ",
    },
    {
        id: 2422915,
        width: 5472,
        height: 3648,
        url: "https://www.pexels.com/photo/photo-of-pod-of-dolphins-2422915/",
        photographer: "Jeremy Bishop",
        photographer_url: "https://www.pexels.com/@jeremy-bishop-1260133",
        photographer_id: 1260133,
        avg_color: "#21617E",
        src: {
            original:
                "https://images.pexels.com/photos/2422915/pexels-photo-2422915.jpeg",
            large2x:
                "https://images.pexels.com/photos/2422915/pexels-photo-2422915.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940",
            large: "https://images.pexels.com/photos/2422915/pexels-photo-2422915.jpeg?auto=compress&cs=tinysrgb&h=650&w=940",
            medium: "https://images.pexels.com/photos/2422915/pexels-photo-2422915.jpeg?auto=compress&cs=tinysrgb&h=350",
            small: "https://images.pexels.com/photos/2422915/pexels-photo-2422915.jpeg?auto=compress&cs=tinysrgb&h=130",
            portrait:
                "https://images.pexels.com/photos/2422915/pexels-photo-2422915.jpeg?auto=compress&cs=tinysrgb&fit=crop&h=1200&w=800",
            landscape:
                "https://images.pexels.com/photos/2422915/pexels-photo-2422915.jpeg?auto=compress&cs=tinysrgb&fit=crop&h=627&w=1200",
            tiny: "https://images.pexels.com/photos/2422915/pexels-photo-2422915.jpeg?auto=compress&cs=tinysrgb&dpr=1&fit=crop&h=200&w=280",
        },
        liked: false,
        alt: "Photo of Pod of Dolphins",
    },
    {
        id: 355288,
        width: 5106,
        height: 3404,
        url: "https://www.pexels.com/photo/macro-photography-of-water-waves-355288/",
        photographer: "Pixabay",
        photographer_url: "https://www.pexels.com/@pixabay",
        photographer_id: 2659,
        avg_color: "#2D5867",
        src: {
            original:
                "https://images.pexels.com/photos/355288/pexels-photo-355288.jpeg",
            large2x:
                "https://images.pexels.com/photos/355288/pexels-photo-355288.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940",
            large: "https://images.pexels.com/photos/355288/pexels-photo-355288.jpeg?auto=compress&cs=tinysrgb&h=650&w=940",
            medium: "https://images.pexels.com/photos/355288/pexels-photo-355288.jpeg?auto=compress&cs=tinysrgb&h=350",
            small: "https://images.pexels.com/photos/355288/pexels-photo-355288.jpeg?auto=compress&cs=tinysrgb&h=130",
            portrait:
                "https://images.pexels.com/photos/355288/pexels-photo-355288.jpeg?auto=compress&cs=tinysrgb&fit=crop&h=1200&w=800",
            landscape:
                "https://images.pexels.com/photos/355288/pexels-photo-355288.jpeg?auto=compress&cs=tinysrgb&fit=crop&h=627&w=1200",
            tiny: "https://images.pexels.com/photos/355288/pexels-photo-355288.jpeg?auto=compress&cs=tinysrgb&dpr=1&fit=crop&h=200&w=280",
        },
        liked: false,
        alt: "Macro Photography of Water Waves",
    },
    {
        id: 911738,
        width: 6016,
        height: 4016,
        url: "https://www.pexels.com/photo/white-textile-911738/",
        photographer: "gdtography",
        photographer_url: "https://www.pexels.com/@gdtography-277628",
        photographer_id: 277628,
        avg_color: "#DFDFDF",
        src: {
            original:
                "https://images.pexels.com/photos/911738/pexels-photo-911738.jpeg",
            large2x:
                "https://images.pexels.com/photos/911738/pexels-photo-911738.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940",
            large: "https://images.pexels.com/photos/911738/pexels-photo-911738.jpeg?auto=compress&cs=tinysrgb&h=650&w=940",
            medium: "https://images.pexels.com/photos/911738/pexels-photo-911738.jpeg?auto=compress&cs=tinysrgb&h=350",
            small: "https://images.pexels.com/photos/911738/pexels-photo-911738.jpeg?auto=compress&cs=tinysrgb&h=130",
            portrait:
                "https://images.pexels.com/photos/911738/pexels-photo-911738.jpeg?auto=compress&cs=tinysrgb&fit=crop&h=1200&w=800",
            landscape:
                "https://images.pexels.com/photos/911738/pexels-photo-911738.jpeg?auto=compress&cs=tinysrgb&fit=crop&h=627&w=1200",
            tiny: "https://images.pexels.com/photos/911738/pexels-photo-911738.jpeg?auto=compress&cs=tinysrgb&dpr=1&fit=crop&h=200&w=280",
        },
        liked: false,
        alt: "White Textile",
    },
    {
        id: 167699,
        width: 6000,
        height: 4000,
        url: "https://www.pexels.com/photo/green-pine-trees-covered-with-fogs-under-white-sky-during-daytime-167699/",
        photographer: "Lum3n",
        photographer_url: "https://www.pexels.com/@lum3n-44775",
        photographer_id: 44775,
        avg_color: "#98AAB8",
        src: {
            original:
                "https://images.pexels.com/photos/167699/pexels-photo-167699.jpeg",
            large2x:
                "https://images.pexels.com/photos/167699/pexels-photo-167699.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940",
            large: "https://images.pexels.com/photos/167699/pexels-photo-167699.jpeg?auto=compress&cs=tinysrgb&h=650&w=940",
            medium: "https://images.pexels.com/photos/167699/pexels-photo-167699.jpeg?auto=compress&cs=tinysrgb&h=350",
            small: "https://images.pexels.com/photos/167699/pexels-photo-167699.jpeg?auto=compress&cs=tinysrgb&h=130",
            portrait:
                "https://images.pexels.com/photos/167699/pexels-photo-167699.jpeg?auto=compress&cs=tinysrgb&fit=crop&h=1200&w=800",
            landscape:
                "https://images.pexels.com/photos/167699/pexels-photo-167699.jpeg?auto=compress&cs=tinysrgb&fit=crop&h=627&w=1200",
            tiny: "https://images.pexels.com/photos/167699/pexels-photo-167699.jpeg?auto=compress&cs=tinysrgb&dpr=1&fit=crop&h=200&w=280",
        },
        liked: false,
        alt: "Green Pine Trees Covered With Fogs Under White Sky during Daytime",
    },
    {
        id: 4534200,
        width: 6000,
        height: 4002,
        url: "https://www.pexels.com/photo/amazing-waterfall-with-lush-foliage-on-rocks-4534200/",
        photographer: "ArtHouse Studio",
        photographer_url: "https://www.pexels.com/@arthousestudio",
        photographer_id: 2766954,
        avg_color: "#5A6656",
        src: {
            original:
                "https://images.pexels.com/photos/4534200/pexels-photo-4534200.jpeg",
            large2x:
                "https://images.pexels.com/photos/4534200/pexels-photo-4534200.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940",
            large: "https://images.pexels.com/photos/4534200/pexels-photo-4534200.jpeg?auto=compress&cs=tinysrgb&h=650&w=940",
            medium: "https://images.pexels.com/photos/4534200/pexels-photo-4534200.jpeg?auto=compress&cs=tinysrgb&h=350",
            small: "https://images.pexels.com/photos/4534200/pexels-photo-4534200.jpeg?auto=compress&cs=tinysrgb&h=130",
            portrait:
                "https://images.pexels.com/photos/4534200/pexels-photo-4534200.jpeg?auto=compress&cs=tinysrgb&fit=crop&h=1200&w=800",
            landscape:
                "https://images.pexels.com/photos/4534200/pexels-photo-4534200.jpeg?auto=compress&cs=tinysrgb&fit=crop&h=627&w=1200",
            tiny: "https://images.pexels.com/photos/4534200/pexels-photo-4534200.jpeg?auto=compress&cs=tinysrgb&dpr=1&fit=crop&h=200&w=280",
        },
        liked: false,
        alt: "Wonderful Aling Aling Waterfall among lush greenery of Sambangan mountainous area on Bali Island",
    },
    {
        id: 1114690,
        width: 5242,
        height: 3499,
        url: "https://www.pexels.com/photo/photo-of-lightning-1114690/",
        photographer: "Philippe Donn",
        photographer_url: "https://www.pexels.com/@philippedonn",
        photographer_id: 230606,
        avg_color: "#15171E",
        src: {
            original:
                "https://images.pexels.com/photos/1114690/pexels-photo-1114690.jpeg",
            large2x:
                "https://images.pexels.com/photos/1114690/pexels-photo-1114690.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940",
            large: "https://images.pexels.com/photos/1114690/pexels-photo-1114690.jpeg?auto=compress&cs=tinysrgb&h=650&w=940",
            medium: "https://images.pexels.com/photos/1114690/pexels-photo-1114690.jpeg?auto=compress&cs=tinysrgb&h=350",
            small: "https://images.pexels.com/photos/1114690/pexels-photo-1114690.jpeg?auto=compress&cs=tinysrgb&h=130",
            portrait:
                "https://images.pexels.com/photos/1114690/pexels-photo-1114690.jpeg?auto=compress&cs=tinysrgb&fit=crop&h=1200&w=800",
            landscape:
                "https://images.pexels.com/photos/1114690/pexels-photo-1114690.jpeg?auto=compress&cs=tinysrgb&fit=crop&h=627&w=1200",
            tiny: "https://images.pexels.com/photos/1114690/pexels-photo-1114690.jpeg?auto=compress&cs=tinysrgb&dpr=1&fit=crop&h=200&w=280",
        },
        liked: false,
        alt: "Photo of Lightning",
    },
    {
        id: 62693,
        width: 2154,
        height: 2153,
        url: "https://www.pexels.com/photo/sunlight-on-floor-62693/",
        photographer: "OVAN",
        photographer_url: "https://www.pexels.com/@knownasovan",
        photographer_id: 11203,
        avg_color: "#DDE0E2",
        src: {
            original:
                "https://images.pexels.com/photos/62693/pexels-photo-62693.jpeg",
            large2x:
                "https://images.pexels.com/photos/62693/pexels-photo-62693.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940",
            large: "https://images.pexels.com/photos/62693/pexels-photo-62693.jpeg?auto=compress&cs=tinysrgb&h=650&w=940",
            medium: "https://images.pexels.com/photos/62693/pexels-photo-62693.jpeg?auto=compress&cs=tinysrgb&h=350",
            small: "https://images.pexels.com/photos/62693/pexels-photo-62693.jpeg?auto=compress&cs=tinysrgb&h=130",
            portrait:
                "https://images.pexels.com/photos/62693/pexels-photo-62693.jpeg?auto=compress&cs=tinysrgb&fit=crop&h=1200&w=800",
            landscape:
                "https://images.pexels.com/photos/62693/pexels-photo-62693.jpeg?auto=compress&cs=tinysrgb&fit=crop&h=627&w=1200",
            tiny: "https://images.pexels.com/photos/62693/pexels-photo-62693.jpeg?auto=compress&cs=tinysrgb&dpr=1&fit=crop&h=200&w=280",
        },
        liked: false,
        alt: "Sunlight on Floor",
    },
    {
        id: 2258536,
        width: 6016,
        height: 4016,
        url: "https://www.pexels.com/photo/lightning-strikes-2258536/",
        photographer: "Frank Cone",
        photographer_url: "https://www.pexels.com/@frank-cone-140140",
        photographer_id: 140140,
        avg_color: "#46465E",
        src: {
            original:
                "https://images.pexels.com/photos/2258536/pexels-photo-2258536.jpeg",
            large2x:
                "https://images.pexels.com/photos/2258536/pexels-photo-2258536.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940",
            large: "https://images.pexels.com/photos/2258536/pexels-photo-2258536.jpeg?auto=compress&cs=tinysrgb&h=650&w=940",
            medium: "https://images.pexels.com/photos/2258536/pexels-photo-2258536.jpeg?auto=compress&cs=tinysrgb&h=350",
            small: "https://images.pexels.com/photos/2258536/pexels-photo-2258536.jpeg?auto=compress&cs=tinysrgb&h=130",
            portrait:
                "https://images.pexels.com/photos/2258536/pexels-photo-2258536.jpeg?auto=compress&cs=tinysrgb&fit=crop&h=1200&w=800",
            landscape:
                "https://images.pexels.com/photos/2258536/pexels-photo-2258536.jpeg?auto=compress&cs=tinysrgb&fit=crop&h=627&w=1200",
            tiny: "https://images.pexels.com/photos/2258536/pexels-photo-2258536.jpeg?auto=compress&cs=tinysrgb&dpr=1&fit=crop&h=200&w=280",
        },
        liked: false,
        alt: "Lightning Strikes",
    },
    {
        id: 985266,
        width: 4032,
        height: 3024,
        url: "https://www.pexels.com/photo/close-up-photo-of-blue-petaled-flowers-985266/",
        photographer: "Magda Ehlers",
        photographer_url: "https://www.pexels.com/@magda-ehlers-pexels",
        photographer_id: 102775,
        avg_color: "#6C8FC2",
        src: {
            original:
                "https://images.pexels.com/photos/985266/pexels-photo-985266.jpeg",
            large2x:
                "https://images.pexels.com/photos/985266/pexels-photo-985266.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940",
            large: "https://images.pexels.com/photos/985266/pexels-photo-985266.jpeg?auto=compress&cs=tinysrgb&h=650&w=940",
            medium: "https://images.pexels.com/photos/985266/pexels-photo-985266.jpeg?auto=compress&cs=tinysrgb&h=350",
            small: "https://images.pexels.com/photos/985266/pexels-photo-985266.jpeg?auto=compress&cs=tinysrgb&h=130",
            portrait:
                "https://images.pexels.com/photos/985266/pexels-photo-985266.jpeg?auto=compress&cs=tinysrgb&fit=crop&h=1200&w=800",
            landscape:
                "https://images.pexels.com/photos/985266/pexels-photo-985266.jpeg?auto=compress&cs=tinysrgb&fit=crop&h=627&w=1200",
            tiny: "https://images.pexels.com/photos/985266/pexels-photo-985266.jpeg?auto=compress&cs=tinysrgb&dpr=1&fit=crop&h=200&w=280",
        },
        liked: false,
        alt: "Close-up Photo of Blue Petaled Flowers",
    },
    {
        id: 1025469,
        width: 5472,
        height: 3648,
        url: "https://www.pexels.com/photo/photo-of-night-sky-1025469/",
        photographer: "Faik  Akmd",
        photographer_url: "https://www.pexels.com/@creationsbyfaik",
        photographer_id: 383634,
        avg_color: "#534F57",
        src: {
            original:
                "https://images.pexels.com/photos/1025469/pexels-photo-1025469.jpeg",
            large2x:
                "https://images.pexels.com/photos/1025469/pexels-photo-1025469.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940",
            large: "https://images.pexels.com/photos/1025469/pexels-photo-1025469.jpeg?auto=compress&cs=tinysrgb&h=650&w=940",
            medium: "https://images.pexels.com/photos/1025469/pexels-photo-1025469.jpeg?auto=compress&cs=tinysrgb&h=350",
            small: "https://images.pexels.com/photos/1025469/pexels-photo-1025469.jpeg?auto=compress&cs=tinysrgb&h=130",
            portrait:
                "https://images.pexels.com/photos/1025469/pexels-photo-1025469.jpeg?auto=compress&cs=tinysrgb&fit=crop&h=1200&w=800",
            landscape:
                "https://images.pexels.com/photos/1025469/pexels-photo-1025469.jpeg?auto=compress&cs=tinysrgb&fit=crop&h=627&w=1200",
            tiny: "https://images.pexels.com/photos/1025469/pexels-photo-1025469.jpeg?auto=compress&cs=tinysrgb&dpr=1&fit=crop&h=200&w=280",
        },
        liked: false,
        alt: "Photo of night sky",
    },
    {
        id: 5326909,
        width: 4900,
        height: 3267,
        url: "https://www.pexels.com/photo/rippling-seawater-reflecting-pink-evening-sky-5326909/",
        photographer: "Ben Mack",
        photographer_url: "https://www.pexels.com/@ben-mack",
        photographer_id: 3391146,
        avg_color: "#D7B9B1",
        src: {
            original:
                "https://images.pexels.com/photos/5326909/pexels-photo-5326909.jpeg",
            large2x:
                "https://images.pexels.com/photos/5326909/pexels-photo-5326909.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940",
            large: "https://images.pexels.com/photos/5326909/pexels-photo-5326909.jpeg?auto=compress&cs=tinysrgb&h=650&w=940",
            medium: "https://images.pexels.com/photos/5326909/pexels-photo-5326909.jpeg?auto=compress&cs=tinysrgb&h=350",
            small: "https://images.pexels.com/photos/5326909/pexels-photo-5326909.jpeg?auto=compress&cs=tinysrgb&h=130",
            portrait:
                "https://images.pexels.com/photos/5326909/pexels-photo-5326909.jpeg?auto=compress&cs=tinysrgb&fit=crop&h=1200&w=800",
            landscape:
                "https://images.pexels.com/photos/5326909/pexels-photo-5326909.jpeg?auto=compress&cs=tinysrgb&fit=crop&h=627&w=1200",
            tiny: "https://images.pexels.com/photos/5326909/pexels-photo-5326909.jpeg?auto=compress&cs=tinysrgb&dpr=1&fit=crop&h=200&w=280",
        },
        liked: false,
        alt: "Rippling seawater reflecting pink evening sky",
    },
    {
        id: 3374210,
        width: 4928,
        height: 3248,
        url: "https://www.pexels.com/photo/blac-blue-and-green-digital-wallpaepr-3374210/",
        photographer: "stein egil liland",
        photographer_url: "https://www.pexels.com/@therato",
        photographer_id: 144244,
        avg_color: "#061D31",
        src: {
            original:
                "https://images.pexels.com/photos/3374210/pexels-photo-3374210.jpeg",
            large2x:
                "https://images.pexels.com/photos/3374210/pexels-photo-3374210.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940",
            large: "https://images.pexels.com/photos/3374210/pexels-photo-3374210.jpeg?auto=compress&cs=tinysrgb&h=650&w=940",
            medium: "https://images.pexels.com/photos/3374210/pexels-photo-3374210.jpeg?auto=compress&cs=tinysrgb&h=350",
            small: "https://images.pexels.com/photos/3374210/pexels-photo-3374210.jpeg?auto=compress&cs=tinysrgb&h=130",
            portrait:
                "https://images.pexels.com/photos/3374210/pexels-photo-3374210.jpeg?auto=compress&cs=tinysrgb&fit=crop&h=1200&w=800",
            landscape:
                "https://images.pexels.com/photos/3374210/pexels-photo-3374210.jpeg?auto=compress&cs=tinysrgb&fit=crop&h=627&w=1200",
            tiny: "https://images.pexels.com/photos/3374210/pexels-photo-3374210.jpeg?auto=compress&cs=tinysrgb&dpr=1&fit=crop&h=200&w=280",
        },
        liked: false,
        alt: "Blac Blue and Green Digital Wallpaepr",
    },
    {
        id: 1568607,
        width: 10624,
        height: 7990,
        url: "https://www.pexels.com/photo/blue-abstract-painting-1568607/",
        photographer: "Zaksheuskaya",
        photographer_url: "https://www.pexels.com/@zaksheuskaya-709412",
        photographer_id: 709412,
        avg_color: "#A0DAE5",
        src: {
            original:
                "https://images.pexels.com/photos/1568607/pexels-photo-1568607.jpeg",
            large2x:
                "https://images.pexels.com/photos/1568607/pexels-photo-1568607.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940",
            large: "https://images.pexels.com/photos/1568607/pexels-photo-1568607.jpeg?auto=compress&cs=tinysrgb&h=650&w=940",
            medium: "https://images.pexels.com/photos/1568607/pexels-photo-1568607.jpeg?auto=compress&cs=tinysrgb&h=350",
            small: "https://images.pexels.com/photos/1568607/pexels-photo-1568607.jpeg?auto=compress&cs=tinysrgb&h=130",
            portrait:
                "https://images.pexels.com/photos/1568607/pexels-photo-1568607.jpeg?auto=compress&cs=tinysrgb&fit=crop&h=1200&w=800",
            landscape:
                "https://images.pexels.com/photos/1568607/pexels-photo-1568607.jpeg?auto=compress&cs=tinysrgb&fit=crop&h=627&w=1200",
            tiny: "https://images.pexels.com/photos/1568607/pexels-photo-1568607.jpeg?auto=compress&cs=tinysrgb&dpr=1&fit=crop&h=200&w=280",
        },
        liked: false,
        alt: "Blue Abstract Painting",
    },
    {
        id: 1146708,
        width: 5068,
        height: 2850,
        url: "https://www.pexels.com/photo/green-rice-field-1146708/",
        photographer: "Johannes Plenio",
        photographer_url: "https://www.pexels.com/@jplenio",
        photographer_id: 424445,
        avg_color: "#653E2F",
        src: {
            original:
                "https://images.pexels.com/photos/1146708/pexels-photo-1146708.jpeg",
            large2x:
                "https://images.pexels.com/photos/1146708/pexels-photo-1146708.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940",
            large: "https://images.pexels.com/photos/1146708/pexels-photo-1146708.jpeg?auto=compress&cs=tinysrgb&h=650&w=940",
            medium: "https://images.pexels.com/photos/1146708/pexels-photo-1146708.jpeg?auto=compress&cs=tinysrgb&h=350",
            small: "https://images.pexels.com/photos/1146708/pexels-photo-1146708.jpeg?auto=compress&cs=tinysrgb&h=130",
            portrait:
                "https://images.pexels.com/photos/1146708/pexels-photo-1146708.jpeg?auto=compress&cs=tinysrgb&fit=crop&h=1200&w=800",
            landscape:
                "https://images.pexels.com/photos/1146708/pexels-photo-1146708.jpeg?auto=compress&cs=tinysrgb&fit=crop&h=627&w=1200",
            tiny: "https://images.pexels.com/photos/1146708/pexels-photo-1146708.jpeg?auto=compress&cs=tinysrgb&dpr=1&fit=crop&h=200&w=280",
        },
        liked: false,
        alt: "Green Rice Field",
    },
    {
        id: 733857,
        width: 6016,
        height: 4016,
        url: "https://www.pexels.com/photo/white-blank-notebook-733857/",
        photographer: "Tirachard Kumtanom",
        photographer_url: "https://www.pexels.com/@tirachard-kumtanom-112571",
        photographer_id: 112571,
        avg_color: "#E4E3E3",
        src: {
            original:
                "https://images.pexels.com/photos/733857/pexels-photo-733857.jpeg",
            large2x:
                "https://images.pexels.com/photos/733857/pexels-photo-733857.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940",
            large: "https://images.pexels.com/photos/733857/pexels-photo-733857.jpeg?auto=compress&cs=tinysrgb&h=650&w=940",
            medium: "https://images.pexels.com/photos/733857/pexels-photo-733857.jpeg?auto=compress&cs=tinysrgb&h=350",
            small: "https://images.pexels.com/photos/733857/pexels-photo-733857.jpeg?auto=compress&cs=tinysrgb&h=130",
            portrait:
                "https://images.pexels.com/photos/733857/pexels-photo-733857.jpeg?auto=compress&cs=tinysrgb&fit=crop&h=1200&w=800",
            landscape:
                "https://images.pexels.com/photos/733857/pexels-photo-733857.jpeg?auto=compress&cs=tinysrgb&fit=crop&h=627&w=1200",
            tiny: "https://images.pexels.com/photos/733857/pexels-photo-733857.jpeg?auto=compress&cs=tinysrgb&dpr=1&fit=crop&h=200&w=280",
        },
        liked: false,
        alt: "White Blank Notebook",
    },
    {
        id: 1774986,
        width: 4534,
        height: 3273,
        url: "https://www.pexels.com/photo/white-surface-1774986/",
        photographer: "Steve Johnson",
        photographer_url: "https://www.pexels.com/@steve",
        photographer_id: 287172,
        avg_color: "#D5D5D5",
        src: {
            original:
                "https://images.pexels.com/photos/1774986/pexels-photo-1774986.jpeg",
            large2x:
                "https://images.pexels.com/photos/1774986/pexels-photo-1774986.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940",
            large: "https://images.pexels.com/photos/1774986/pexels-photo-1774986.jpeg?auto=compress&cs=tinysrgb&h=650&w=940",
            medium: "https://images.pexels.com/photos/1774986/pexels-photo-1774986.jpeg?auto=compress&cs=tinysrgb&h=350",
            small: "https://images.pexels.com/photos/1774986/pexels-photo-1774986.jpeg?auto=compress&cs=tinysrgb&h=130",
            portrait:
                "https://images.pexels.com/photos/1774986/pexels-photo-1774986.jpeg?auto=compress&cs=tinysrgb&fit=crop&h=1200&w=800",
            landscape:
                "https://images.pexels.com/photos/1774986/pexels-photo-1774986.jpeg?auto=compress&cs=tinysrgb&fit=crop&h=627&w=1200",
            tiny: "https://images.pexels.com/photos/1774986/pexels-photo-1774986.jpeg?auto=compress&cs=tinysrgb&dpr=1&fit=crop&h=200&w=280",
        },
        liked: false,
        alt: "White Surface",
    },
    {
        id: 547114,
        width: 4928,
        height: 3264,
        url: "https://www.pexels.com/photo/brown-wooden-dock-547114/",
        photographer: "Krivec Ales",
        photographer_url: "https://www.pexels.com/@dreamypixel",
        photographer_id: 166939,
        avg_color: "#8295A4",
        src: {
            original:
                "https://images.pexels.com/photos/547114/pexels-photo-547114.jpeg",
            large2x:
                "https://images.pexels.com/photos/547114/pexels-photo-547114.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940",
            large: "https://images.pexels.com/photos/547114/pexels-photo-547114.jpeg?auto=compress&cs=tinysrgb&h=650&w=940",
            medium: "https://images.pexels.com/photos/547114/pexels-photo-547114.jpeg?auto=compress&cs=tinysrgb&h=350",
            small: "https://images.pexels.com/photos/547114/pexels-photo-547114.jpeg?auto=compress&cs=tinysrgb&h=130",
            portrait:
                "https://images.pexels.com/photos/547114/pexels-photo-547114.jpeg?auto=compress&cs=tinysrgb&fit=crop&h=1200&w=800",
            landscape:
                "https://images.pexels.com/photos/547114/pexels-photo-547114.jpeg?auto=compress&cs=tinysrgb&fit=crop&h=627&w=1200",
            tiny: "https://images.pexels.com/photos/547114/pexels-photo-547114.jpeg?auto=compress&cs=tinysrgb&dpr=1&fit=crop&h=200&w=280",
        },
        liked: false,
        alt: "Brown Wooden Dock",
    },
    {
        id: 3805983,
        width: 3698,
        height: 2264,
        url: "https://www.pexels.com/photo/photo-of-supernova-in-galaxy-3805983/",
        photographer: "Alex Andrews",
        photographer_url: "https://www.pexels.com/@alex-andrews-271121",
        photographer_id: 271121,
        avg_color: "#242321",
        src: {
            original:
                "https://images.pexels.com/photos/3805983/pexels-photo-3805983.jpeg",
            large2x:
                "https://images.pexels.com/photos/3805983/pexels-photo-3805983.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940",
            large: "https://images.pexels.com/photos/3805983/pexels-photo-3805983.jpeg?auto=compress&cs=tinysrgb&h=650&w=940",
            medium: "https://images.pexels.com/photos/3805983/pexels-photo-3805983.jpeg?auto=compress&cs=tinysrgb&h=350",
            small: "https://images.pexels.com/photos/3805983/pexels-photo-3805983.jpeg?auto=compress&cs=tinysrgb&h=130",
            portrait:
                "https://images.pexels.com/photos/3805983/pexels-photo-3805983.jpeg?auto=compress&cs=tinysrgb&fit=crop&h=1200&w=800",
            landscape:
                "https://images.pexels.com/photos/3805983/pexels-photo-3805983.jpeg?auto=compress&cs=tinysrgb&fit=crop&h=627&w=1200",
            tiny: "https://images.pexels.com/photos/3805983/pexels-photo-3805983.jpeg?auto=compress&cs=tinysrgb&dpr=1&fit=crop&h=200&w=280",
        },
        liked: false,
        alt: "Photo of Supernova in Galaxy",
    },
    {
        id: 2531709,
        width: 3778,
        height: 2638,
        url: "https://www.pexels.com/photo/photo-of-thunderstorm-2531709/",
        photographer: "Rodrigo Souza",
        photographer_url: "https://www.pexels.com/@rodrigo-souza-1275988",
        photographer_id: 1275988,
        avg_color: "#17336A",
        src: {
            original:
                "https://images.pexels.com/photos/2531709/pexels-photo-2531709.jpeg",
            large2x:
                "https://images.pexels.com/photos/2531709/pexels-photo-2531709.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940",
            large: "https://images.pexels.com/photos/2531709/pexels-photo-2531709.jpeg?auto=compress&cs=tinysrgb&h=650&w=940",
            medium: "https://images.pexels.com/photos/2531709/pexels-photo-2531709.jpeg?auto=compress&cs=tinysrgb&h=350",
            small: "https://images.pexels.com/photos/2531709/pexels-photo-2531709.jpeg?auto=compress&cs=tinysrgb&h=130",
            portrait:
                "https://images.pexels.com/photos/2531709/pexels-photo-2531709.jpeg?auto=compress&cs=tinysrgb&fit=crop&h=1200&w=800",
            landscape:
                "https://images.pexels.com/photos/2531709/pexels-photo-2531709.jpeg?auto=compress&cs=tinysrgb&fit=crop&h=627&w=1200",
            tiny: "https://images.pexels.com/photos/2531709/pexels-photo-2531709.jpeg?auto=compress&cs=tinysrgb&dpr=1&fit=crop&h=200&w=280",
        },
        liked: false,
        alt: "Photo of Thunderstorm",
    },
    {
        id: 8960464,
        width: 5748,
        height: 3832,
        url: "https://www.pexels.com/photo/silver-macbook-on-white-table-8960464/",
        photographer: "Andrew Neel",
        photographer_url: "https://www.pexels.com/@andrew",
        photographer_id: 977589,
        avg_color: "#DDD6D0",
        src: {
            original:
                "https://images.pexels.com/photos/8960464/pexels-photo-8960464.jpeg",
            large2x:
                "https://images.pexels.com/photos/8960464/pexels-photo-8960464.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940",
            large: "https://images.pexels.com/photos/8960464/pexels-photo-8960464.jpeg?auto=compress&cs=tinysrgb&h=650&w=940",
            medium: "https://images.pexels.com/photos/8960464/pexels-photo-8960464.jpeg?auto=compress&cs=tinysrgb&h=350",
            small: "https://images.pexels.com/photos/8960464/pexels-photo-8960464.jpeg?auto=compress&cs=tinysrgb&h=130",
            portrait:
                "https://images.pexels.com/photos/8960464/pexels-photo-8960464.jpeg?auto=compress&cs=tinysrgb&fit=crop&h=1200&w=800",
            landscape:
                "https://images.pexels.com/photos/8960464/pexels-photo-8960464.jpeg?auto=compress&cs=tinysrgb&fit=crop&h=627&w=1200",
            tiny: "https://images.pexels.com/photos/8960464/pexels-photo-8960464.jpeg?auto=compress&cs=tinysrgb&dpr=1&fit=crop&h=200&w=280",
        },
        liked: false,
        alt: "Silver MacBook on White Table",
    },
    {
        id: 2850287,
        width: 5992,
        height: 3371,
        url: "https://www.pexels.com/photo/two-person-on-boat-in-body-of-water-during-golden-hour-2850287/",
        photographer: "Johannes Plenio",
        photographer_url: "https://www.pexels.com/@jplenio",
        photographer_id: 424445,
        avg_color: "#8C6E88",
        src: {
            original:
                "https://images.pexels.com/photos/2850287/pexels-photo-2850287.jpeg",
            large2x:
                "https://images.pexels.com/photos/2850287/pexels-photo-2850287.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940",
            large: "https://images.pexels.com/photos/2850287/pexels-photo-2850287.jpeg?auto=compress&cs=tinysrgb&h=650&w=940",
            medium: "https://images.pexels.com/photos/2850287/pexels-photo-2850287.jpeg?auto=compress&cs=tinysrgb&h=350",
            small: "https://images.pexels.com/photos/2850287/pexels-photo-2850287.jpeg?auto=compress&cs=tinysrgb&h=130",
            portrait:
                "https://images.pexels.com/photos/2850287/pexels-photo-2850287.jpeg?auto=compress&cs=tinysrgb&fit=crop&h=1200&w=800",
            landscape:
                "https://images.pexels.com/photos/2850287/pexels-photo-2850287.jpeg?auto=compress&cs=tinysrgb&fit=crop&h=627&w=1200",
            tiny: "https://images.pexels.com/photos/2850287/pexels-photo-2850287.jpeg?auto=compress&cs=tinysrgb&dpr=1&fit=crop&h=200&w=280",
        },
        liked: false,
        alt: "Two Person on Boat in Body of Water during Golden Hour",
    },
    {
        id: 2377965,
        width: 6240,
        height: 4154,
        url: "https://www.pexels.com/photo/selective-focus-of-plants-2377965/",
        photographer: "VH S",
        photographer_url: "https://www.pexels.com/@vlah",
        photographer_id: 1135791,
        avg_color: "#846D68",
        src: {
            original:
                "https://images.pexels.com/photos/2377965/pexels-photo-2377965.jpeg",
            large2x:
                "https://images.pexels.com/photos/2377965/pexels-photo-2377965.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940",
            large: "https://images.pexels.com/photos/2377965/pexels-photo-2377965.jpeg?auto=compress&cs=tinysrgb&h=650&w=940",
            medium: "https://images.pexels.com/photos/2377965/pexels-photo-2377965.jpeg?auto=compress&cs=tinysrgb&h=350",
            small: "https://images.pexels.com/photos/2377965/pexels-photo-2377965.jpeg?auto=compress&cs=tinysrgb&h=130",
            portrait:
                "https://images.pexels.com/photos/2377965/pexels-photo-2377965.jpeg?auto=compress&cs=tinysrgb&fit=crop&h=1200&w=800",
            landscape:
                "https://images.pexels.com/photos/2377965/pexels-photo-2377965.jpeg?auto=compress&cs=tinysrgb&fit=crop&h=627&w=1200",
            tiny: "https://images.pexels.com/photos/2377965/pexels-photo-2377965.jpeg?auto=compress&cs=tinysrgb&dpr=1&fit=crop&h=200&w=280",
        },
        liked: false,
        alt: "Selective Focus of Plants",
    },
    {
        id: 1933316,
        width: 4806,
        height: 3199,
        url: "https://www.pexels.com/photo/time-lapse-photo-of-northern-lights-1933316/",
        photographer: "stein egil liland",
        photographer_url: "https://www.pexels.com/@therato",
        photographer_id: 144244,
        avg_color: "#3F4D48",
        src: {
            original:
                "https://images.pexels.com/photos/1933316/pexels-photo-1933316.jpeg",
            large2x:
                "https://images.pexels.com/photos/1933316/pexels-photo-1933316.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940",
            large: "https://images.pexels.com/photos/1933316/pexels-photo-1933316.jpeg?auto=compress&cs=tinysrgb&h=650&w=940",
            medium: "https://images.pexels.com/photos/1933316/pexels-photo-1933316.jpeg?auto=compress&cs=tinysrgb&h=350",
            small: "https://images.pexels.com/photos/1933316/pexels-photo-1933316.jpeg?auto=compress&cs=tinysrgb&h=130",
            portrait:
                "https://images.pexels.com/photos/1933316/pexels-photo-1933316.jpeg?auto=compress&cs=tinysrgb&fit=crop&h=1200&w=800",
            landscape:
                "https://images.pexels.com/photos/1933316/pexels-photo-1933316.jpeg?auto=compress&cs=tinysrgb&fit=crop&h=627&w=1200",
            tiny: "https://images.pexels.com/photos/1933316/pexels-photo-1933316.jpeg?auto=compress&cs=tinysrgb&dpr=1&fit=crop&h=200&w=280",
        },
        liked: false,
        alt: "Time Lapse Photo of Northern Lights",
    },
    {
        id: 707915,
        width: 6000,
        height: 4000,
        url: "https://www.pexels.com/photo/timelapse-photography-of-falls-near-trees-707915/",
        photographer: "Fabian Reitmeier",
        photographer_url: "https://www.pexels.com/@fabianreitmeier",
        photographer_id: 223400,
        avg_color: "#444326",
        src: {
            original:
                "https://images.pexels.com/photos/707915/pexels-photo-707915.jpeg",
            large2x:
                "https://images.pexels.com/photos/707915/pexels-photo-707915.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940",
            large: "https://images.pexels.com/photos/707915/pexels-photo-707915.jpeg?auto=compress&cs=tinysrgb&h=650&w=940",
            medium: "https://images.pexels.com/photos/707915/pexels-photo-707915.jpeg?auto=compress&cs=tinysrgb&h=350",
            small: "https://images.pexels.com/photos/707915/pexels-photo-707915.jpeg?auto=compress&cs=tinysrgb&h=130",
            portrait:
                "https://images.pexels.com/photos/707915/pexels-photo-707915.jpeg?auto=compress&cs=tinysrgb&fit=crop&h=1200&w=800",
            landscape:
                "https://images.pexels.com/photos/707915/pexels-photo-707915.jpeg?auto=compress&cs=tinysrgb&fit=crop&h=627&w=1200",
            tiny: "https://images.pexels.com/photos/707915/pexels-photo-707915.jpeg?auto=compress&cs=tinysrgb&dpr=1&fit=crop&h=200&w=280",
        },
        liked: false,
        alt: "Timelapse Photography of Falls Near Trees",
    },
    {
        id: 733852,
        width: 6016,
        height: 4016,
        url: "https://www.pexels.com/photo/black-ball-point-pen-with-brown-spiral-notebook-733852/",
        photographer: "Tirachard Kumtanom",
        photographer_url: "https://www.pexels.com/@tirachard-kumtanom-112571",
        photographer_id: 112571,
        avg_color: "#E7E4DD",
        src: {
            original:
                "https://images.pexels.com/photos/733852/pexels-photo-733852.jpeg",
            large2x:
                "https://images.pexels.com/photos/733852/pexels-photo-733852.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940",
            large: "https://images.pexels.com/photos/733852/pexels-photo-733852.jpeg?auto=compress&cs=tinysrgb&h=650&w=940",
            medium: "https://images.pexels.com/photos/733852/pexels-photo-733852.jpeg?auto=compress&cs=tinysrgb&h=350",
            small: "https://images.pexels.com/photos/733852/pexels-photo-733852.jpeg?auto=compress&cs=tinysrgb&h=130",
            portrait:
                "https://images.pexels.com/photos/733852/pexels-photo-733852.jpeg?auto=compress&cs=tinysrgb&fit=crop&h=1200&w=800",
            landscape:
                "https://images.pexels.com/photos/733852/pexels-photo-733852.jpeg?auto=compress&cs=tinysrgb&fit=crop&h=627&w=1200",
            tiny: "https://images.pexels.com/photos/733852/pexels-photo-733852.jpeg?auto=compress&cs=tinysrgb&dpr=1&fit=crop&h=200&w=280",
        },
        liked: false,
        alt: "Black Ball-point Pen With Brown Spiral Notebook",
    },
    {
        id: 950241,
        width: 3996,
        height: 2668,
        url: "https://www.pexels.com/photo/modern-art-painting-in-white-950241/",
        photographer: "gdtography",
        photographer_url: "https://www.pexels.com/@gdtography-277628",
        photographer_id: 277628,
        avg_color: "#ECECEB",
        src: {
            original:
                "https://images.pexels.com/photos/950241/pexels-photo-950241.jpeg",
            large2x:
                "https://images.pexels.com/photos/950241/pexels-photo-950241.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940",
            large: "https://images.pexels.com/photos/950241/pexels-photo-950241.jpeg?auto=compress&cs=tinysrgb&h=650&w=940",
            medium: "https://images.pexels.com/photos/950241/pexels-photo-950241.jpeg?auto=compress&cs=tinysrgb&h=350",
            small: "https://images.pexels.com/photos/950241/pexels-photo-950241.jpeg?auto=compress&cs=tinysrgb&h=130",
            portrait:
                "https://images.pexels.com/photos/950241/pexels-photo-950241.jpeg?auto=compress&cs=tinysrgb&fit=crop&h=1200&w=800",
            landscape:
                "https://images.pexels.com/photos/950241/pexels-photo-950241.jpeg?auto=compress&cs=tinysrgb&fit=crop&h=627&w=1200",
            tiny: "https://images.pexels.com/photos/950241/pexels-photo-950241.jpeg?auto=compress&cs=tinysrgb&dpr=1&fit=crop&h=200&w=280",
        },
        liked: false,
        alt: "Modern Art Painting In White ",
    },
    {
        id: 2441454,
        width: 3403,
        height: 2272,
        url: "https://www.pexels.com/photo/close-up-photo-of-blue-background-2441454/",
        photographer: "Harrison  Candlin",
        photographer_url: "https://www.pexels.com/@harrison-candlin-1279336",
        photographer_id: 1279336,
        avg_color: "#467486",
        src: {
            original:
                "https://images.pexels.com/photos/2441454/pexels-photo-2441454.jpeg",
            large2x:
                "https://images.pexels.com/photos/2441454/pexels-photo-2441454.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940",
            large: "https://images.pexels.com/photos/2441454/pexels-photo-2441454.jpeg?auto=compress&cs=tinysrgb&h=650&w=940",
            medium: "https://images.pexels.com/photos/2441454/pexels-photo-2441454.jpeg?auto=compress&cs=tinysrgb&h=350",
            small: "https://images.pexels.com/photos/2441454/pexels-photo-2441454.jpeg?auto=compress&cs=tinysrgb&h=130",
            portrait:
                "https://images.pexels.com/photos/2441454/pexels-photo-2441454.jpeg?auto=compress&cs=tinysrgb&fit=crop&h=1200&w=800",
            landscape:
                "https://images.pexels.com/photos/2441454/pexels-photo-2441454.jpeg?auto=compress&cs=tinysrgb&fit=crop&h=627&w=1200",
            tiny: "https://images.pexels.com/photos/2441454/pexels-photo-2441454.jpeg?auto=compress&cs=tinysrgb&dpr=1&fit=crop&h=200&w=280",
        },
        liked: false,
        alt: "Close Up Photo of Blue Background",
    },
    {
        id: 1054218,
        width: 6000,
        height: 4000,
        url: "https://www.pexels.com/photo/snow-top-mountain-under-clear-sky-1054218/",
        photographer: "Stephan Seeber",
        photographer_url: "https://www.pexels.com/@stywo",
        photographer_id: 396539,
        avg_color: "#4C6D76",
        src: {
            original:
                "https://images.pexels.com/photos/1054218/pexels-photo-1054218.jpeg",
            large2x:
                "https://images.pexels.com/photos/1054218/pexels-photo-1054218.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940",
            large: "https://images.pexels.com/photos/1054218/pexels-photo-1054218.jpeg?auto=compress&cs=tinysrgb&h=650&w=940",
            medium: "https://images.pexels.com/photos/1054218/pexels-photo-1054218.jpeg?auto=compress&cs=tinysrgb&h=350",
            small: "https://images.pexels.com/photos/1054218/pexels-photo-1054218.jpeg?auto=compress&cs=tinysrgb&h=130",
            portrait:
                "https://images.pexels.com/photos/1054218/pexels-photo-1054218.jpeg?auto=compress&cs=tinysrgb&fit=crop&h=1200&w=800",
            landscape:
                "https://images.pexels.com/photos/1054218/pexels-photo-1054218.jpeg?auto=compress&cs=tinysrgb&fit=crop&h=627&w=1200",
            tiny: "https://images.pexels.com/photos/1054218/pexels-photo-1054218.jpeg?auto=compress&cs=tinysrgb&dpr=1&fit=crop&h=200&w=280",
        },
        liked: false,
        alt: "Snow-top Mountain Under Clear Sky",
    },
    {
        id: 1509582,
        width: 5464,
        height: 3640,
        url: "https://www.pexels.com/photo/bird-s-eye-view-of-desert-1509582/",
        photographer: "Walid Ahmad",
        photographer_url: "https://www.pexels.com/@walidphotoz",
        photographer_id: 31963,
        avg_color: "#8C4D38",
        src: {
            original:
                "https://images.pexels.com/photos/1509582/pexels-photo-1509582.jpeg",
            large2x:
                "https://images.pexels.com/photos/1509582/pexels-photo-1509582.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940",
            large: "https://images.pexels.com/photos/1509582/pexels-photo-1509582.jpeg?auto=compress&cs=tinysrgb&h=650&w=940",
            medium: "https://images.pexels.com/photos/1509582/pexels-photo-1509582.jpeg?auto=compress&cs=tinysrgb&h=350",
            small: "https://images.pexels.com/photos/1509582/pexels-photo-1509582.jpeg?auto=compress&cs=tinysrgb&h=130",
            portrait:
                "https://images.pexels.com/photos/1509582/pexels-photo-1509582.jpeg?auto=compress&cs=tinysrgb&fit=crop&h=1200&w=800",
            landscape:
                "https://images.pexels.com/photos/1509582/pexels-photo-1509582.jpeg?auto=compress&cs=tinysrgb&fit=crop&h=627&w=1200",
            tiny: "https://images.pexels.com/photos/1509582/pexels-photo-1509582.jpeg?auto=compress&cs=tinysrgb&dpr=1&fit=crop&h=200&w=280",
        },
        liked: false,
        alt: "Bird's Eye View Of Desert",
    },
    {
        id: 461940,
        width: 6000,
        height: 3845,
        url: "https://www.pexels.com/photo/silhouette-of-palm-trees-near-shoreline-461940/",
        photographer: "Pixabay",
        photographer_url: "https://www.pexels.com/@pixabay",
        photographer_id: 2659,
        avg_color: "#62536B",
        src: {
            original:
                "https://images.pexels.com/photos/461940/pexels-photo-461940.jpeg",
            large2x:
                "https://images.pexels.com/photos/461940/pexels-photo-461940.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940",
            large: "https://images.pexels.com/photos/461940/pexels-photo-461940.jpeg?auto=compress&cs=tinysrgb&h=650&w=940",
            medium: "https://images.pexels.com/photos/461940/pexels-photo-461940.jpeg?auto=compress&cs=tinysrgb&h=350",
            small: "https://images.pexels.com/photos/461940/pexels-photo-461940.jpeg?auto=compress&cs=tinysrgb&h=130",
            portrait:
                "https://images.pexels.com/photos/461940/pexels-photo-461940.jpeg?auto=compress&cs=tinysrgb&fit=crop&h=1200&w=800",
            landscape:
                "https://images.pexels.com/photos/461940/pexels-photo-461940.jpeg?auto=compress&cs=tinysrgb&fit=crop&h=627&w=1200",
            tiny: "https://images.pexels.com/photos/461940/pexels-photo-461940.jpeg?auto=compress&cs=tinysrgb&dpr=1&fit=crop&h=200&w=280",
        },
        liked: false,
        alt: "Silhouette of Palm Trees Near Shoreline",
    },
    {
        id: 2356045,
        width: 5421,
        height: 3614,
        url: "https://www.pexels.com/photo/machu-pichu-peru-2356045/",
        photographer: "Errin Casano",
        photographer_url: "https://www.pexels.com/@errin-casano-1240439",
        photographer_id: 1240439,
        avg_color: "#788385",
        src: {
            original:
                "https://images.pexels.com/photos/2356045/pexels-photo-2356045.jpeg",
            large2x:
                "https://images.pexels.com/photos/2356045/pexels-photo-2356045.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940",
            large: "https://images.pexels.com/photos/2356045/pexels-photo-2356045.jpeg?auto=compress&cs=tinysrgb&h=650&w=940",
            medium: "https://images.pexels.com/photos/2356045/pexels-photo-2356045.jpeg?auto=compress&cs=tinysrgb&h=350",
            small: "https://images.pexels.com/photos/2356045/pexels-photo-2356045.jpeg?auto=compress&cs=tinysrgb&h=130",
            portrait:
                "https://images.pexels.com/photos/2356045/pexels-photo-2356045.jpeg?auto=compress&cs=tinysrgb&fit=crop&h=1200&w=800",
            landscape:
                "https://images.pexels.com/photos/2356045/pexels-photo-2356045.jpeg?auto=compress&cs=tinysrgb&fit=crop&h=627&w=1200",
            tiny: "https://images.pexels.com/photos/2356045/pexels-photo-2356045.jpeg?auto=compress&cs=tinysrgb&dpr=1&fit=crop&h=200&w=280",
        },
        liked: false,
        alt: "Machu Pichu, Peru",
    },
    {
        id: 413195,
        width: 3264,
        height: 2448,
        url: "https://www.pexels.com/photo/green-grass-413195/",
        photographer: "pixmike",
        photographer_url: "https://www.pexels.com/@gravitylicious",
        photographer_id: 136983,
        avg_color: "#45A11D",
        src: {
            original:
                "https://images.pexels.com/photos/413195/pexels-photo-413195.jpeg",
            large2x:
                "https://images.pexels.com/photos/413195/pexels-photo-413195.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940",
            large: "https://images.pexels.com/photos/413195/pexels-photo-413195.jpeg?auto=compress&cs=tinysrgb&h=650&w=940",
            medium: "https://images.pexels.com/photos/413195/pexels-photo-413195.jpeg?auto=compress&cs=tinysrgb&h=350",
            small: "https://images.pexels.com/photos/413195/pexels-photo-413195.jpeg?auto=compress&cs=tinysrgb&h=130",
            portrait:
                "https://images.pexels.com/photos/413195/pexels-photo-413195.jpeg?auto=compress&cs=tinysrgb&fit=crop&h=1200&w=800",
            landscape:
                "https://images.pexels.com/photos/413195/pexels-photo-413195.jpeg?auto=compress&cs=tinysrgb&fit=crop&h=627&w=1200",
            tiny: "https://images.pexels.com/photos/413195/pexels-photo-413195.jpeg?auto=compress&cs=tinysrgb&dpr=1&fit=crop&h=200&w=280",
        },
        liked: false,
        alt: "Green Grass",
    },
    {
        id: 3996362,
        width: 6000,
        height: 4000,
        url: "https://www.pexels.com/photo/green-grass-field-and-trees-under-blue-sky-3996362/",
        photographer: "Chavdar Lungov",
        photographer_url: "https://www.pexels.com/@chavdar-lungov-2332494",
        photographer_id: 2332494,
        avg_color: "#697964",
        src: {
            original:
                "https://images.pexels.com/photos/3996362/pexels-photo-3996362.jpeg",
            large2x:
                "https://images.pexels.com/photos/3996362/pexels-photo-3996362.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940",
            large: "https://images.pexels.com/photos/3996362/pexels-photo-3996362.jpeg?auto=compress&cs=tinysrgb&h=650&w=940",
            medium: "https://images.pexels.com/photos/3996362/pexels-photo-3996362.jpeg?auto=compress&cs=tinysrgb&h=350",
            small: "https://images.pexels.com/photos/3996362/pexels-photo-3996362.jpeg?auto=compress&cs=tinysrgb&h=130",
            portrait:
                "https://images.pexels.com/photos/3996362/pexels-photo-3996362.jpeg?auto=compress&cs=tinysrgb&fit=crop&h=1200&w=800",
            landscape:
                "https://images.pexels.com/photos/3996362/pexels-photo-3996362.jpeg?auto=compress&cs=tinysrgb&fit=crop&h=627&w=1200",
            tiny: "https://images.pexels.com/photos/3996362/pexels-photo-3996362.jpeg?auto=compress&cs=tinysrgb&dpr=1&fit=crop&h=200&w=280",
        },
        liked: false,
        alt: "Green Grass Field and Trees Under Blue Sky",
    },
    {
        id: 3178786,
        width: 5170,
        height: 3447,
        url: "https://www.pexels.com/photo/close-up-photo-of-green-leaves-3178786/",
        photographer: "Andrew Neel",
        photographer_url: "https://www.pexels.com/@andrew",
        photographer_id: 977589,
        avg_color: "#11110E",
        src: {
            original:
                "https://images.pexels.com/photos/3178786/pexels-photo-3178786.jpeg",
            large2x:
                "https://images.pexels.com/photos/3178786/pexels-photo-3178786.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940",
            large: "https://images.pexels.com/photos/3178786/pexels-photo-3178786.jpeg?auto=compress&cs=tinysrgb&h=650&w=940",
            medium: "https://images.pexels.com/photos/3178786/pexels-photo-3178786.jpeg?auto=compress&cs=tinysrgb&h=350",
            small: "https://images.pexels.com/photos/3178786/pexels-photo-3178786.jpeg?auto=compress&cs=tinysrgb&h=130",
            portrait:
                "https://images.pexels.com/photos/3178786/pexels-photo-3178786.jpeg?auto=compress&cs=tinysrgb&fit=crop&h=1200&w=800",
            landscape:
                "https://images.pexels.com/photos/3178786/pexels-photo-3178786.jpeg?auto=compress&cs=tinysrgb&fit=crop&h=627&w=1200",
            tiny: "https://images.pexels.com/photos/3178786/pexels-photo-3178786.jpeg?auto=compress&cs=tinysrgb&dpr=1&fit=crop&h=200&w=280",
        },
        liked: false,
        alt: "Close-Up Photo Of Green Leaves",
    },
    {
        id: 1090972,
        width: 6000,
        height: 4000,
        url: "https://www.pexels.com/photo/top-view-photo-of-green-plants-1090972/",
        photographer: "Irina Iriser",
        photographer_url: "https://www.pexels.com/@iriser",
        photographer_id: 135125,
        avg_color: "#102726",
        src: {
            original:
                "https://images.pexels.com/photos/1090972/pexels-photo-1090972.jpeg",
            large2x:
                "https://images.pexels.com/photos/1090972/pexels-photo-1090972.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940",
            large: "https://images.pexels.com/photos/1090972/pexels-photo-1090972.jpeg?auto=compress&cs=tinysrgb&h=650&w=940",
            medium: "https://images.pexels.com/photos/1090972/pexels-photo-1090972.jpeg?auto=compress&cs=tinysrgb&h=350",
            small: "https://images.pexels.com/photos/1090972/pexels-photo-1090972.jpeg?auto=compress&cs=tinysrgb&h=130",
            portrait:
                "https://images.pexels.com/photos/1090972/pexels-photo-1090972.jpeg?auto=compress&cs=tinysrgb&fit=crop&h=1200&w=800",
            landscape:
                "https://images.pexels.com/photos/1090972/pexels-photo-1090972.jpeg?auto=compress&cs=tinysrgb&fit=crop&h=627&w=1200",
            tiny: "https://images.pexels.com/photos/1090972/pexels-photo-1090972.jpeg?auto=compress&cs=tinysrgb&dpr=1&fit=crop&h=200&w=280",
        },
        liked: false,
        alt: "Top View Photo of Green Plants",
    },
    {
        id: 4100130,
        width: 6720,
        height: 4480,
        url: "https://www.pexels.com/photo/silhouette-of-mountain-under-the-moon-covered-with-clouds-4100130/",
        photographer: "Vladyslav Dushenkovsky",
        photographer_url: "https://www.pexels.com/@dushenkovsky",
        photographer_id: 2031205,
        avg_color: "#504752",
        src: {
            original:
                "https://images.pexels.com/photos/4100130/pexels-photo-4100130.jpeg",
            large2x:
                "https://images.pexels.com/photos/4100130/pexels-photo-4100130.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940",
            large: "https://images.pexels.com/photos/4100130/pexels-photo-4100130.jpeg?auto=compress&cs=tinysrgb&h=650&w=940",
            medium: "https://images.pexels.com/photos/4100130/pexels-photo-4100130.jpeg?auto=compress&cs=tinysrgb&h=350",
            small: "https://images.pexels.com/photos/4100130/pexels-photo-4100130.jpeg?auto=compress&cs=tinysrgb&h=130",
            portrait:
                "https://images.pexels.com/photos/4100130/pexels-photo-4100130.jpeg?auto=compress&cs=tinysrgb&fit=crop&h=1200&w=800",
            landscape:
                "https://images.pexels.com/photos/4100130/pexels-photo-4100130.jpeg?auto=compress&cs=tinysrgb&fit=crop&h=627&w=1200",
            tiny: "https://images.pexels.com/photos/4100130/pexels-photo-4100130.jpeg?auto=compress&cs=tinysrgb&dpr=1&fit=crop&h=200&w=280",
        },
        liked: false,
        alt: "Silhouette of Mountain Under The Moon Covered With Clouds",
    },
    {
        id: 3454270,
        width: 4592,
        height: 2583,
        url: "https://www.pexels.com/photo/black-and-white-photography-of-trees-on-a-foggy-day-3454270/",
        photographer: "Michał Osiński",
        photographer_url: "https://www.pexels.com/@michal-osinski-1863102",
        photographer_id: 1863102,
        avg_color: "#788188",
        src: {
            original:
                "https://images.pexels.com/photos/3454270/pexels-photo-3454270.jpeg",
            large2x:
                "https://images.pexels.com/photos/3454270/pexels-photo-3454270.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940",
            large: "https://images.pexels.com/photos/3454270/pexels-photo-3454270.jpeg?auto=compress&cs=tinysrgb&h=650&w=940",
            medium: "https://images.pexels.com/photos/3454270/pexels-photo-3454270.jpeg?auto=compress&cs=tinysrgb&h=350",
            small: "https://images.pexels.com/photos/3454270/pexels-photo-3454270.jpeg?auto=compress&cs=tinysrgb&h=130",
            portrait:
                "https://images.pexels.com/photos/3454270/pexels-photo-3454270.jpeg?auto=compress&cs=tinysrgb&fit=crop&h=1200&w=800",
            landscape:
                "https://images.pexels.com/photos/3454270/pexels-photo-3454270.jpeg?auto=compress&cs=tinysrgb&fit=crop&h=627&w=1200",
            tiny: "https://images.pexels.com/photos/3454270/pexels-photo-3454270.jpeg?auto=compress&cs=tinysrgb&dpr=1&fit=crop&h=200&w=280",
        },
        liked: false,
        alt: "Black and White Photography of Trees on a Foggy Day",
    },
    {
        id: 3297593,
        width: 6000,
        height: 4000,
        url: "https://www.pexels.com/photo/white-concrete-building-3297593/",
        photographer: "Matheus Natan",
        photographer_url: "https://www.pexels.com/@matheusnatan",
        photographer_id: 854413,
        avg_color: "#AAC4D1",
        src: {
            original:
                "https://images.pexels.com/photos/3297593/pexels-photo-3297593.jpeg",
            large2x:
                "https://images.pexels.com/photos/3297593/pexels-photo-3297593.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940",
            large: "https://images.pexels.com/photos/3297593/pexels-photo-3297593.jpeg?auto=compress&cs=tinysrgb&h=650&w=940",
            medium: "https://images.pexels.com/photos/3297593/pexels-photo-3297593.jpeg?auto=compress&cs=tinysrgb&h=350",
            small: "https://images.pexels.com/photos/3297593/pexels-photo-3297593.jpeg?auto=compress&cs=tinysrgb&h=130",
            portrait:
                "https://images.pexels.com/photos/3297593/pexels-photo-3297593.jpeg?auto=compress&cs=tinysrgb&fit=crop&h=1200&w=800",
            landscape:
                "https://images.pexels.com/photos/3297593/pexels-photo-3297593.jpeg?auto=compress&cs=tinysrgb&fit=crop&h=627&w=1200",
            tiny: "https://images.pexels.com/photos/3297593/pexels-photo-3297593.jpeg?auto=compress&cs=tinysrgb&dpr=1&fit=crop&h=200&w=280",
        },
        liked: false,
        alt: "White Concrete Building",
    },
    {
        id: 1292115,
        width: 5472,
        height: 3648,
        url: "https://www.pexels.com/photo/aerial-photography-of-pine-trees-1292115/",
        photographer: "eberhard grossgasteiger",
        photographer_url: "https://www.pexels.com/@eberhardgross",
        photographer_id: 121938,
        avg_color: "#757B78",
        src: {
            original:
                "https://images.pexels.com/photos/1292115/pexels-photo-1292115.jpeg",
            large2x:
                "https://images.pexels.com/photos/1292115/pexels-photo-1292115.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940",
            large: "https://images.pexels.com/photos/1292115/pexels-photo-1292115.jpeg?auto=compress&cs=tinysrgb&h=650&w=940",
            medium: "https://images.pexels.com/photos/1292115/pexels-photo-1292115.jpeg?auto=compress&cs=tinysrgb&h=350",
            small: "https://images.pexels.com/photos/1292115/pexels-photo-1292115.jpeg?auto=compress&cs=tinysrgb&h=130",
            portrait:
                "https://images.pexels.com/photos/1292115/pexels-photo-1292115.jpeg?auto=compress&cs=tinysrgb&fit=crop&h=1200&w=800",
            landscape:
                "https://images.pexels.com/photos/1292115/pexels-photo-1292115.jpeg?auto=compress&cs=tinysrgb&fit=crop&h=627&w=1200",
            tiny: "https://images.pexels.com/photos/1292115/pexels-photo-1292115.jpeg?auto=compress&cs=tinysrgb&dpr=1&fit=crop&h=200&w=280",
        },
        liked: false,
        alt: "Aerial Photography of Pine Trees",
    },
    {
        id: 130621,
        width: 4272,
        height: 2848,
        url: "https://www.pexels.com/photo/jellyfish-illustration-130621/",
        photographer: "Oday Hazeem",
        photographer_url: "https://www.pexels.com/@oday-hazeem-4297",
        photographer_id: 4297,
        avg_color: "#8A6C57",
        src: {
            original:
                "https://images.pexels.com/photos/130621/pexels-photo-130621.jpeg",
            large2x:
                "https://images.pexels.com/photos/130621/pexels-photo-130621.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940",
            large: "https://images.pexels.com/photos/130621/pexels-photo-130621.jpeg?auto=compress&cs=tinysrgb&h=650&w=940",
            medium: "https://images.pexels.com/photos/130621/pexels-photo-130621.jpeg?auto=compress&cs=tinysrgb&h=350",
            small: "https://images.pexels.com/photos/130621/pexels-photo-130621.jpeg?auto=compress&cs=tinysrgb&h=130",
            portrait:
                "https://images.pexels.com/photos/130621/pexels-photo-130621.jpeg?auto=compress&cs=tinysrgb&fit=crop&h=1200&w=800",
            landscape:
                "https://images.pexels.com/photos/130621/pexels-photo-130621.jpeg?auto=compress&cs=tinysrgb&fit=crop&h=627&w=1200",
            tiny: "https://images.pexels.com/photos/130621/pexels-photo-130621.jpeg?auto=compress&cs=tinysrgb&dpr=1&fit=crop&h=200&w=280",
        },
        liked: false,
        alt: "Jellyfish Illustration",
    },
    {
        id: 1809344,
        width: 5992,
        height: 3995,
        url: "https://www.pexels.com/photo/white-and-pink-flowers-beside-a-canister-1809344/",
        photographer: "Ylanite Koppens",
        photographer_url: "https://www.pexels.com/@nietjuh",
        photographer_id: 196863,
        avg_color: "#A29F9F",
        src: {
            original:
                "https://images.pexels.com/photos/1809344/pexels-photo-1809344.jpeg",
            large2x:
                "https://images.pexels.com/photos/1809344/pexels-photo-1809344.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940",
            large: "https://images.pexels.com/photos/1809344/pexels-photo-1809344.jpeg?auto=compress&cs=tinysrgb&h=650&w=940",
            medium: "https://images.pexels.com/photos/1809344/pexels-photo-1809344.jpeg?auto=compress&cs=tinysrgb&h=350",
            small: "https://images.pexels.com/photos/1809344/pexels-photo-1809344.jpeg?auto=compress&cs=tinysrgb&h=130",
            portrait:
                "https://images.pexels.com/photos/1809344/pexels-photo-1809344.jpeg?auto=compress&cs=tinysrgb&fit=crop&h=1200&w=800",
            landscape:
                "https://images.pexels.com/photos/1809344/pexels-photo-1809344.jpeg?auto=compress&cs=tinysrgb&fit=crop&h=627&w=1200",
            tiny: "https://images.pexels.com/photos/1809344/pexels-photo-1809344.jpeg?auto=compress&cs=tinysrgb&dpr=1&fit=crop&h=200&w=280",
        },
        liked: false,
        alt: "White and Pink Flowers Beside A Canister",
    },
    {
        id: 414667,
        width: 4387,
        height: 2713,
        url: "https://www.pexels.com/photo/background-beautiful-bloom-blooming-414667/",
        photographer: "Pixabay",
        photographer_url: "https://www.pexels.com/@pixabay",
        photographer_id: 2659,
        avg_color: "#A81F74",
        src: {
            original:
                "https://images.pexels.com/photos/414667/pexels-photo-414667.jpeg",
            large2x:
                "https://images.pexels.com/photos/414667/pexels-photo-414667.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940",
            large: "https://images.pexels.com/photos/414667/pexels-photo-414667.jpeg?auto=compress&cs=tinysrgb&h=650&w=940",
            medium: "https://images.pexels.com/photos/414667/pexels-photo-414667.jpeg?auto=compress&cs=tinysrgb&h=350",
            small: "https://images.pexels.com/photos/414667/pexels-photo-414667.jpeg?auto=compress&cs=tinysrgb&h=130",
            portrait:
                "https://images.pexels.com/photos/414667/pexels-photo-414667.jpeg?auto=compress&cs=tinysrgb&fit=crop&h=1200&w=800",
            landscape:
                "https://images.pexels.com/photos/414667/pexels-photo-414667.jpeg?auto=compress&cs=tinysrgb&fit=crop&h=627&w=1200",
            tiny: "https://images.pexels.com/photos/414667/pexels-photo-414667.jpeg?auto=compress&cs=tinysrgb&dpr=1&fit=crop&h=200&w=280",
        },
        liked: false,
        alt: "Pink Blooming Flowers",
    },
    {
        id: 7219901,
        width: 6720,
        height: 4480,
        url: "https://www.pexels.com/photo/fashion-love-people-woman-7219901/",
        photographer: "Thirdman",
        photographer_url: "https://www.pexels.com/@thirdman",
        photographer_id: 3361091,
        avg_color: "#9A7B66",
        src: {
            original:
                "https://images.pexels.com/photos/7219901/pexels-photo-7219901.jpeg",
            large2x:
                "https://images.pexels.com/photos/7219901/pexels-photo-7219901.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940",
            large: "https://images.pexels.com/photos/7219901/pexels-photo-7219901.jpeg?auto=compress&cs=tinysrgb&h=650&w=940",
            medium: "https://images.pexels.com/photos/7219901/pexels-photo-7219901.jpeg?auto=compress&cs=tinysrgb&h=350",
            small: "https://images.pexels.com/photos/7219901/pexels-photo-7219901.jpeg?auto=compress&cs=tinysrgb&h=130",
            portrait:
                "https://images.pexels.com/photos/7219901/pexels-photo-7219901.jpeg?auto=compress&cs=tinysrgb&fit=crop&h=1200&w=800",
            landscape:
                "https://images.pexels.com/photos/7219901/pexels-photo-7219901.jpeg?auto=compress&cs=tinysrgb&fit=crop&h=627&w=1200",
            tiny: "https://images.pexels.com/photos/7219901/pexels-photo-7219901.jpeg?auto=compress&cs=tinysrgb&dpr=1&fit=crop&h=200&w=280",
        },
        liked: false,
        alt: "Free stock photo of attractive, authentic, background",
    },
    {
        id: 96627,
        width: 4608,
        height: 3072,
        url: "https://www.pexels.com/photo/selective-focus-photo-of-white-petaled-flowers-96627/",
        photographer: "PhotoMIX Company",
        photographer_url: "https://www.pexels.com/@wdnet",
        photographer_id: 21063,
        avg_color: "#9CA780",
        src: {
            original:
                "https://images.pexels.com/photos/96627/pexels-photo-96627.jpeg",
            large2x:
                "https://images.pexels.com/photos/96627/pexels-photo-96627.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940",
            large: "https://images.pexels.com/photos/96627/pexels-photo-96627.jpeg?auto=compress&cs=tinysrgb&h=650&w=940",
            medium: "https://images.pexels.com/photos/96627/pexels-photo-96627.jpeg?auto=compress&cs=tinysrgb&h=350",
            small: "https://images.pexels.com/photos/96627/pexels-photo-96627.jpeg?auto=compress&cs=tinysrgb&h=130",
            portrait:
                "https://images.pexels.com/photos/96627/pexels-photo-96627.jpeg?auto=compress&cs=tinysrgb&fit=crop&h=1200&w=800",
            landscape:
                "https://images.pexels.com/photos/96627/pexels-photo-96627.jpeg?auto=compress&cs=tinysrgb&fit=crop&h=627&w=1200",
            tiny: "https://images.pexels.com/photos/96627/pexels-photo-96627.jpeg?auto=compress&cs=tinysrgb&dpr=1&fit=crop&h=200&w=280",
        },
        liked: false,
        alt: "Selective Focus Photo of White Petaled Flowers",
    },
    {
        id: 937782,
        width: 3969,
        height: 2729,
        url: "https://www.pexels.com/photo/body-of-water-with-mountains-on-side-937782/",
        photographer: "Tyler Lastovich",
        photographer_url: "https://www.pexels.com/@lastly",
        photographer_id: 129858,
        avg_color: "#9B969B",
        src: {
            original:
                "https://images.pexels.com/photos/937782/pexels-photo-937782.jpeg",
            large2x:
                "https://images.pexels.com/photos/937782/pexels-photo-937782.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940",
            large: "https://images.pexels.com/photos/937782/pexels-photo-937782.jpeg?auto=compress&cs=tinysrgb&h=650&w=940",
            medium: "https://images.pexels.com/photos/937782/pexels-photo-937782.jpeg?auto=compress&cs=tinysrgb&h=350",
            small: "https://images.pexels.com/photos/937782/pexels-photo-937782.jpeg?auto=compress&cs=tinysrgb&h=130",
            portrait:
                "https://images.pexels.com/photos/937782/pexels-photo-937782.jpeg?auto=compress&cs=tinysrgb&fit=crop&h=1200&w=800",
            landscape:
                "https://images.pexels.com/photos/937782/pexels-photo-937782.jpeg?auto=compress&cs=tinysrgb&fit=crop&h=627&w=1200",
            tiny: "https://images.pexels.com/photos/937782/pexels-photo-937782.jpeg?auto=compress&cs=tinysrgb&dpr=1&fit=crop&h=200&w=280",
        },
        liked: false,
        alt: "Body of Water With Mountains on Side",
    },
    {
        id: 2409681,
        width: 5821,
        height: 3881,
        url: "https://www.pexels.com/photo/two-women-sitting-on-vehicle-roofs-2409681/",
        photographer: "Elijah O'Donnell",
        photographer_url: "https://www.pexels.com/@elijahsad",
        photographer_id: 1257224,
        avg_color: "#545B5F",
        src: {
            original:
                "https://images.pexels.com/photos/2409681/pexels-photo-2409681.jpeg",
            large2x:
                "https://images.pexels.com/photos/2409681/pexels-photo-2409681.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940",
            large: "https://images.pexels.com/photos/2409681/pexels-photo-2409681.jpeg?auto=compress&cs=tinysrgb&h=650&w=940",
            medium: "https://images.pexels.com/photos/2409681/pexels-photo-2409681.jpeg?auto=compress&cs=tinysrgb&h=350",
            small: "https://images.pexels.com/photos/2409681/pexels-photo-2409681.jpeg?auto=compress&cs=tinysrgb&h=130",
            portrait:
                "https://images.pexels.com/photos/2409681/pexels-photo-2409681.jpeg?auto=compress&cs=tinysrgb&fit=crop&h=1200&w=800",
            landscape:
                "https://images.pexels.com/photos/2409681/pexels-photo-2409681.jpeg?auto=compress&cs=tinysrgb&fit=crop&h=627&w=1200",
            tiny: "https://images.pexels.com/photos/2409681/pexels-photo-2409681.jpeg?auto=compress&cs=tinysrgb&dpr=1&fit=crop&h=200&w=280",
        },
        liked: false,
        alt: "Two Women Sitting on Vehicle Roofs",
    },
    {
        id: 316466,
        width: 6000,
        height: 4000,
        url: "https://www.pexels.com/photo/close-up-of-hand-holding-pencil-over-white-background-316466/",
        photographer: "Lum3n",
        photographer_url: "https://www.pexels.com/@lum3n-44775",
        photographer_id: 44775,
        avg_color: "#F2EDEB",
        src: {
            original:
                "https://images.pexels.com/photos/316466/pexels-photo-316466.jpeg",
            large2x:
                "https://images.pexels.com/photos/316466/pexels-photo-316466.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940",
            large: "https://images.pexels.com/photos/316466/pexels-photo-316466.jpeg?auto=compress&cs=tinysrgb&h=650&w=940",
            medium: "https://images.pexels.com/photos/316466/pexels-photo-316466.jpeg?auto=compress&cs=tinysrgb&h=350",
            small: "https://images.pexels.com/photos/316466/pexels-photo-316466.jpeg?auto=compress&cs=tinysrgb&h=130",
            portrait:
                "https://images.pexels.com/photos/316466/pexels-photo-316466.jpeg?auto=compress&cs=tinysrgb&fit=crop&h=1200&w=800",
            landscape:
                "https://images.pexels.com/photos/316466/pexels-photo-316466.jpeg?auto=compress&cs=tinysrgb&fit=crop&h=627&w=1200",
            tiny: "https://images.pexels.com/photos/316466/pexels-photo-316466.jpeg?auto=compress&cs=tinysrgb&dpr=1&fit=crop&h=200&w=280",
        },
        liked: false,
        alt: "Close-up of Hand Holding Pencil over White Background",
    },
    {
        id: 169789,
        width: 5389,
        height: 3593,
        url: "https://www.pexels.com/photo/timelapse-photography-of-stars-at-night-169789/",
        photographer: "InstaWalli",
        photographer_url: "https://www.pexels.com/@instawally",
        photographer_id: 43987,
        avg_color: "#142435",
        src: {
            original:
                "https://images.pexels.com/photos/169789/pexels-photo-169789.jpeg",
            large2x:
                "https://images.pexels.com/photos/169789/pexels-photo-169789.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940",
            large: "https://images.pexels.com/photos/169789/pexels-photo-169789.jpeg?auto=compress&cs=tinysrgb&h=650&w=940",
            medium: "https://images.pexels.com/photos/169789/pexels-photo-169789.jpeg?auto=compress&cs=tinysrgb&h=350",
            small: "https://images.pexels.com/photos/169789/pexels-photo-169789.jpeg?auto=compress&cs=tinysrgb&h=130",
            portrait:
                "https://images.pexels.com/photos/169789/pexels-photo-169789.jpeg?auto=compress&cs=tinysrgb&fit=crop&h=1200&w=800",
            landscape:
                "https://images.pexels.com/photos/169789/pexels-photo-169789.jpeg?auto=compress&cs=tinysrgb&fit=crop&h=627&w=1200",
            tiny: "https://images.pexels.com/photos/169789/pexels-photo-169789.jpeg?auto=compress&cs=tinysrgb&dpr=1&fit=crop&h=200&w=280",
        },
        liked: false,
        alt: "Timelapse Photography of Stars at Night",
    },
    {
        id: 317333,
        width: 6144,
        height: 4069,
        url: "https://www.pexels.com/photo/beige-fur-317333/",
        photographer: "Lukas",
        photographer_url: "https://www.pexels.com/@goumbik",
        photographer_id: 89898,
        avg_color: "#B4A491",
        src: {
            original:
                "https://images.pexels.com/photos/317333/pexels-photo-317333.jpeg",
            large2x:
                "https://images.pexels.com/photos/317333/pexels-photo-317333.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940",
            large: "https://images.pexels.com/photos/317333/pexels-photo-317333.jpeg?auto=compress&cs=tinysrgb&h=650&w=940",
            medium: "https://images.pexels.com/photos/317333/pexels-photo-317333.jpeg?auto=compress&cs=tinysrgb&h=350",
            small: "https://images.pexels.com/photos/317333/pexels-photo-317333.jpeg?auto=compress&cs=tinysrgb&h=130",
            portrait:
                "https://images.pexels.com/photos/317333/pexels-photo-317333.jpeg?auto=compress&cs=tinysrgb&fit=crop&h=1200&w=800",
            landscape:
                "https://images.pexels.com/photos/317333/pexels-photo-317333.jpeg?auto=compress&cs=tinysrgb&fit=crop&h=627&w=1200",
            tiny: "https://images.pexels.com/photos/317333/pexels-photo-317333.jpeg?auto=compress&cs=tinysrgb&dpr=1&fit=crop&h=200&w=280",
        },
        liked: false,
        alt: "Beige Fur",
    },
    {
        id: 3064079,
        width: 3992,
        height: 2662,
        url: "https://www.pexels.com/photo/aerial-view-of-landscape-3064079/",
        photographer: "Mengliu Di",
        photographer_url: "https://www.pexels.com/@dimengliu",
        photographer_id: 1368233,
        avg_color: "#624F3E",
        src: {
            original:
                "https://images.pexels.com/photos/3064079/pexels-photo-3064079.jpeg",
            large2x:
                "https://images.pexels.com/photos/3064079/pexels-photo-3064079.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940",
            large: "https://images.pexels.com/photos/3064079/pexels-photo-3064079.jpeg?auto=compress&cs=tinysrgb&h=650&w=940",
            medium: "https://images.pexels.com/photos/3064079/pexels-photo-3064079.jpeg?auto=compress&cs=tinysrgb&h=350",
            small: "https://images.pexels.com/photos/3064079/pexels-photo-3064079.jpeg?auto=compress&cs=tinysrgb&h=130",
            portrait:
                "https://images.pexels.com/photos/3064079/pexels-photo-3064079.jpeg?auto=compress&cs=tinysrgb&fit=crop&h=1200&w=800",
            landscape:
                "https://images.pexels.com/photos/3064079/pexels-photo-3064079.jpeg?auto=compress&cs=tinysrgb&fit=crop&h=627&w=1200",
            tiny: "https://images.pexels.com/photos/3064079/pexels-photo-3064079.jpeg?auto=compress&cs=tinysrgb&dpr=1&fit=crop&h=200&w=280",
        },
        liked: false,
        alt: "Aerial View Of Landscape",
    },
    {
        id: 4386442,
        width: 6720,
        height: 4480,
        url: "https://www.pexels.com/photo/heap-of-different-nominal-per-dollars-4386442/",
        photographer: "Karolina Grabowska",
        photographer_url: "https://www.pexels.com/@karolina-grabowska",
        photographer_id: 2332540,
        avg_color: "#A5A4A5",
        src: {
            original:
                "https://images.pexels.com/photos/4386442/pexels-photo-4386442.jpeg",
            large2x:
                "https://images.pexels.com/photos/4386442/pexels-photo-4386442.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940",
            large: "https://images.pexels.com/photos/4386442/pexels-photo-4386442.jpeg?auto=compress&cs=tinysrgb&h=650&w=940",
            medium: "https://images.pexels.com/photos/4386442/pexels-photo-4386442.jpeg?auto=compress&cs=tinysrgb&h=350",
            small: "https://images.pexels.com/photos/4386442/pexels-photo-4386442.jpeg?auto=compress&cs=tinysrgb&h=130",
            portrait:
                "https://images.pexels.com/photos/4386442/pexels-photo-4386442.jpeg?auto=compress&cs=tinysrgb&fit=crop&h=1200&w=800",
            landscape:
                "https://images.pexels.com/photos/4386442/pexels-photo-4386442.jpeg?auto=compress&cs=tinysrgb&fit=crop&h=627&w=1200",
            tiny: "https://images.pexels.com/photos/4386442/pexels-photo-4386442.jpeg?auto=compress&cs=tinysrgb&dpr=1&fit=crop&h=200&w=280",
        },
        liked: false,
        alt: "Top view of different types and colors American dollars placed together on each other",
    },
    {
        id: 552789,
        width: 7309,
        height: 4739,
        url: "https://www.pexels.com/photo/clouds-and-mountain-552789/",
        photographer: "Krivec Ales",
        photographer_url: "https://www.pexels.com/@dreamypixel",
        photographer_id: 166939,
        avg_color: "#A87D9B",
        src: {
            original:
                "https://images.pexels.com/photos/552789/pexels-photo-552789.jpeg",
            large2x:
                "https://images.pexels.com/photos/552789/pexels-photo-552789.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940",
            large: "https://images.pexels.com/photos/552789/pexels-photo-552789.jpeg?auto=compress&cs=tinysrgb&h=650&w=940",
            medium: "https://images.pexels.com/photos/552789/pexels-photo-552789.jpeg?auto=compress&cs=tinysrgb&h=350",
            small: "https://images.pexels.com/photos/552789/pexels-photo-552789.jpeg?auto=compress&cs=tinysrgb&h=130",
            portrait:
                "https://images.pexels.com/photos/552789/pexels-photo-552789.jpeg?auto=compress&cs=tinysrgb&fit=crop&h=1200&w=800",
            landscape:
                "https://images.pexels.com/photos/552789/pexels-photo-552789.jpeg?auto=compress&cs=tinysrgb&fit=crop&h=627&w=1200",
            tiny: "https://images.pexels.com/photos/552789/pexels-photo-552789.jpeg?auto=compress&cs=tinysrgb&dpr=1&fit=crop&h=200&w=280",
        },
        liked: false,
        alt: "Clouds and Mountain",
    },
    {
        id: 1933319,
        width: 4774,
        height: 2889,
        url: "https://www.pexels.com/photo/northern-lights-1933319/",
        photographer: "stein egil liland",
        photographer_url: "https://www.pexels.com/@therato",
        photographer_id: 144244,
        avg_color: "#1D3A41",
        src: {
            original:
                "https://images.pexels.com/photos/1933319/pexels-photo-1933319.jpeg",
            large2x:
                "https://images.pexels.com/photos/1933319/pexels-photo-1933319.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940",
            large: "https://images.pexels.com/photos/1933319/pexels-photo-1933319.jpeg?auto=compress&cs=tinysrgb&h=650&w=940",
            medium: "https://images.pexels.com/photos/1933319/pexels-photo-1933319.jpeg?auto=compress&cs=tinysrgb&h=350",
            small: "https://images.pexels.com/photos/1933319/pexels-photo-1933319.jpeg?auto=compress&cs=tinysrgb&h=130",
            portrait:
                "https://images.pexels.com/photos/1933319/pexels-photo-1933319.jpeg?auto=compress&cs=tinysrgb&fit=crop&h=1200&w=800",
            landscape:
                "https://images.pexels.com/photos/1933319/pexels-photo-1933319.jpeg?auto=compress&cs=tinysrgb&fit=crop&h=627&w=1200",
            tiny: "https://images.pexels.com/photos/1933319/pexels-photo-1933319.jpeg?auto=compress&cs=tinysrgb&dpr=1&fit=crop&h=200&w=280",
        },
        liked: false,
        alt: "Northern Lights",
    },
    {
        id: 937783,
        width: 4000,
        height: 2750,
        url: "https://www.pexels.com/photo/body-of-water-near-silhouette-of-mountain-under-white-clouds-during-sunset-937783/",
        photographer: "Tyler Lastovich",
        photographer_url: "https://www.pexels.com/@lastly",
        photographer_id: 129858,
        avg_color: "#7D7172",
        src: {
            original:
                "https://images.pexels.com/photos/937783/pexels-photo-937783.jpeg",
            large2x:
                "https://images.pexels.com/photos/937783/pexels-photo-937783.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940",
            large: "https://images.pexels.com/photos/937783/pexels-photo-937783.jpeg?auto=compress&cs=tinysrgb&h=650&w=940",
            medium: "https://images.pexels.com/photos/937783/pexels-photo-937783.jpeg?auto=compress&cs=tinysrgb&h=350",
            small: "https://images.pexels.com/photos/937783/pexels-photo-937783.jpeg?auto=compress&cs=tinysrgb&h=130",
            portrait:
                "https://images.pexels.com/photos/937783/pexels-photo-937783.jpeg?auto=compress&cs=tinysrgb&fit=crop&h=1200&w=800",
            landscape:
                "https://images.pexels.com/photos/937783/pexels-photo-937783.jpeg?auto=compress&cs=tinysrgb&fit=crop&h=627&w=1200",
            tiny: "https://images.pexels.com/photos/937783/pexels-photo-937783.jpeg?auto=compress&cs=tinysrgb&dpr=1&fit=crop&h=200&w=280",
        },
        liked: false,
        alt: "Body of Water Near Silhouette of Mountain Under White Clouds during Sunset",
    },
    {
        id: 2835436,
        width: 5446,
        height: 3602,
        url: "https://www.pexels.com/photo/photo-of-snow-capped-mountains-during-dawn-2835436/",
        photographer: "Francesco Ungaro",
        photographer_url: "https://www.pexels.com/@francesco-ungaro",
        photographer_id: 21273,
        avg_color: "#6D8D9D",
        src: {
            original:
                "https://images.pexels.com/photos/2835436/pexels-photo-2835436.jpeg",
            large2x:
                "https://images.pexels.com/photos/2835436/pexels-photo-2835436.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940",
            large: "https://images.pexels.com/photos/2835436/pexels-photo-2835436.jpeg?auto=compress&cs=tinysrgb&h=650&w=940",
            medium: "https://images.pexels.com/photos/2835436/pexels-photo-2835436.jpeg?auto=compress&cs=tinysrgb&h=350",
            small: "https://images.pexels.com/photos/2835436/pexels-photo-2835436.jpeg?auto=compress&cs=tinysrgb&h=130",
            portrait:
                "https://images.pexels.com/photos/2835436/pexels-photo-2835436.jpeg?auto=compress&cs=tinysrgb&fit=crop&h=1200&w=800",
            landscape:
                "https://images.pexels.com/photos/2835436/pexels-photo-2835436.jpeg?auto=compress&cs=tinysrgb&fit=crop&h=627&w=1200",
            tiny: "https://images.pexels.com/photos/2835436/pexels-photo-2835436.jpeg?auto=compress&cs=tinysrgb&dpr=1&fit=crop&h=200&w=280",
        },
        liked: false,
        alt: "Photo Of Snow Capped Mountains During Dawn ",
    },
    {
        id: 355887,
        width: 6000,
        height: 4000,
        url: "https://www.pexels.com/photo/plants-under-starry-sky-355887/",
        photographer: "Pixabay",
        photographer_url: "https://www.pexels.com/@pixabay",
        photographer_id: 2659,
        avg_color: "#06436D",
        src: {
            original:
                "https://images.pexels.com/photos/355887/pexels-photo-355887.jpeg",
            large2x:
                "https://images.pexels.com/photos/355887/pexels-photo-355887.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940",
            large: "https://images.pexels.com/photos/355887/pexels-photo-355887.jpeg?auto=compress&cs=tinysrgb&h=650&w=940",
            medium: "https://images.pexels.com/photos/355887/pexels-photo-355887.jpeg?auto=compress&cs=tinysrgb&h=350",
            small: "https://images.pexels.com/photos/355887/pexels-photo-355887.jpeg?auto=compress&cs=tinysrgb&h=130",
            portrait:
                "https://images.pexels.com/photos/355887/pexels-photo-355887.jpeg?auto=compress&cs=tinysrgb&fit=crop&h=1200&w=800",
            landscape:
                "https://images.pexels.com/photos/355887/pexels-photo-355887.jpeg?auto=compress&cs=tinysrgb&fit=crop&h=627&w=1200",
            tiny: "https://images.pexels.com/photos/355887/pexels-photo-355887.jpeg?auto=compress&cs=tinysrgb&dpr=1&fit=crop&h=200&w=280",
        },
        liked: false,
        alt: "Plants Under Starry Sky",
    },
    {
        id: 3131634,
        width: 6000,
        height: 3375,
        url: "https://www.pexels.com/photo/brown-mountain-near-body-of-water-3131634/",
        photographer: "Johannes Plenio",
        photographer_url: "https://www.pexels.com/@jplenio",
        photographer_id: 424445,
        avg_color: "#645844",
        src: {
            original:
                "https://images.pexels.com/photos/3131634/pexels-photo-3131634.jpeg",
            large2x:
                "https://images.pexels.com/photos/3131634/pexels-photo-3131634.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940",
            large: "https://images.pexels.com/photos/3131634/pexels-photo-3131634.jpeg?auto=compress&cs=tinysrgb&h=650&w=940",
            medium: "https://images.pexels.com/photos/3131634/pexels-photo-3131634.jpeg?auto=compress&cs=tinysrgb&h=350",
            small: "https://images.pexels.com/photos/3131634/pexels-photo-3131634.jpeg?auto=compress&cs=tinysrgb&h=130",
            portrait:
                "https://images.pexels.com/photos/3131634/pexels-photo-3131634.jpeg?auto=compress&cs=tinysrgb&fit=crop&h=1200&w=800",
            landscape:
                "https://images.pexels.com/photos/3131634/pexels-photo-3131634.jpeg?auto=compress&cs=tinysrgb&fit=crop&h=627&w=1200",
            tiny: "https://images.pexels.com/photos/3131634/pexels-photo-3131634.jpeg?auto=compress&cs=tinysrgb&dpr=1&fit=crop&h=200&w=280",
        },
        liked: false,
        alt: "Brown Mountain Near Body of Water",
    },
    {
        id: 3274903,
        width: 5537,
        height: 3691,
        url: "https://www.pexels.com/photo/mountain-and-sky-3274903/",
        photographer: "Aviv Perets",
        photographer_url: "https://www.pexels.com/@avivperets",
        photographer_id: 1707817,
        avg_color: "#51545C",
        src: {
            original:
                "https://images.pexels.com/photos/3274903/pexels-photo-3274903.jpeg",
            large2x:
                "https://images.pexels.com/photos/3274903/pexels-photo-3274903.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940",
            large: "https://images.pexels.com/photos/3274903/pexels-photo-3274903.jpeg?auto=compress&cs=tinysrgb&h=650&w=940",
            medium: "https://images.pexels.com/photos/3274903/pexels-photo-3274903.jpeg?auto=compress&cs=tinysrgb&h=350",
            small: "https://images.pexels.com/photos/3274903/pexels-photo-3274903.jpeg?auto=compress&cs=tinysrgb&h=130",
            portrait:
                "https://images.pexels.com/photos/3274903/pexels-photo-3274903.jpeg?auto=compress&cs=tinysrgb&fit=crop&h=1200&w=800",
            landscape:
                "https://images.pexels.com/photos/3274903/pexels-photo-3274903.jpeg?auto=compress&cs=tinysrgb&fit=crop&h=627&w=1200",
            tiny: "https://images.pexels.com/photos/3274903/pexels-photo-3274903.jpeg?auto=compress&cs=tinysrgb&dpr=1&fit=crop&h=200&w=280",
        },
        liked: false,
        alt: "Mountain and Sky",
    },
];

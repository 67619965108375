import React from "react";
import {Box, Typography, IconButton } from "@mui/material";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import YouTubeIcon from "@mui/icons-material/YouTube";
import TwitterIcon from "@mui/icons-material/Twitter";
import ContactForm from "../components/ContactForm";

export const ContactUs = () => {
    
    return (
        <div style={{ backgroundColor: "#007DFE" }}>
            <Box
                display="flex"
                alignItems="center"
                justifyContent="center"
                sx={{ bgcolor: "#007DFE", p: { xs: 3, md: 1 }, mt: 10 }}
                flexDirection="column"
            >
                <Typography
                    sx={{
                        fontFamily: "Inter",
                        fontWeight: 1000,
                        color: "#F6FAFD",
                        fontSize: "48px",
                        paddingBottom: "10px",
                    }}
                >
                    Contact Us
                </Typography>
                <Box>
                    <IconButton
                        className="text-capitalize"
                        variant="outlined"
                        size="large"
                        sx={
                            {
                                // color: "blue"
                            }
                        }
                        href="https://www.linkedin.com/company/kroop-ai/"
                    >
                        <LinkedInIcon fontSize="large" />
                    </IconButton>

                    <IconButton
                        className="text-capitalize"
                        variant="outlined"
                        size="large"
                        sx={{
                            marginLeft: "10px",
                            marginRight: "10px",
                        }}
                    >
                        <YouTubeIcon fontSize="large" />
                    </IconButton>

                    <IconButton
                        className="text-capitalize"
                        variant="outlined"
                        size="large"
                        href="https://twitter.com/kroop_ai?lang=en"
                    >
                        <TwitterIcon fontSize="large" />
                    </IconButton>
                </Box>
            </Box>
            <Box
                sx={{
                    bgcolor: "#F6FAFD",
                    minHeight: "70vh",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    flexDirection: "column",
                    padding: "2rem",
                    // mt: {xs:"-70px", md:"-50px"}
                }}
            >
                <ContactForm />
            </Box>
        </div>
    );
};

import React from "react";
import {
    Box,
    Card,
    CardContent,
    CardMedia,
    Typography,
    Button,
} from "@mui/material";
import { Link } from 'react-router-dom';

const Card1 = ({
    cardImage,
    title,
    description,
    buttonText,
    cardColor,
    section,
    onClickHandler,
    marginTop

}) => {
    return (
        <Card
            sx={{
                p: 2,
                m: 2,
                marginTop: {xs: 0, md: marginTop || 0},
                borderRadius: "38px",
                border: "1px solid rgba(61, 65, 81, 0.20)",
                background: cardColor || "#4FB6EC",
                boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                textAlign: "center",
                width: "100%",
                height: "25rem"
            }}
        >
            <Box
                sx={{
                    padding: "10px",
                    backgroundColor: cardColor? "#4FB6EC": "white",
                    borderRadius: "50%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: 70,
                    width: 70,
                }}
            >
                <CardMedia
                    component="img"
                    src={cardImage}
                    alt="Image"
                    sx={{
                        // height: "auto",
                        width: "30px",
                        maxWidth: "100%",
                        display: "block",
                    }}
                />
            </Box>
            <CardContent>
                <Typography
                    variant="h6"
                    component="div"
                    sx={{
                        color: cardColor ? "#3D4151" : "white",
                        fontSize: "15px",
                        fontWeight: 700,
                    }}
                >
                    {title}
                </Typography>
                <Typography
                    variant="body2"
                    sx={{
                        my: 2,
                        color: cardColor ? "rgba(61, 65, 81, 0.71)" : "white",
                        fontSize: "12px",
                        fontWeight: 500,
                    }}
                >
                    {description}
                </Typography>
                <Link to={section}>
                <Button
                    className="btn-rounded"
                    variant="contained"
                    sx={{
                        bgcolor: cardColor ? "#4FB6EC !important" : "#F6FAFD !important",
                        color: cardColor ? "#FFFFFF !important" : "#3D4151 !important",
                        textTransform: "capitalize"
                    }}
                >
                    {buttonText}
                </Button>
                </Link>
            </CardContent>
        </Card>
    );
};

export default Card1;

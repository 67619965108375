import React, {useEffect} from "react";
import { useLocation } from "react-router-dom";
import { Box, Grid, Typography, CardMedia } from "@mui/material";

import { UseCaseLeft, UseCaseRight } from "../components/UseCaseComponent";
import svg1 from "../assets/images/svg_images/svg-use.png";
import svg2 from "../assets/images/svg_images/Online learning-bro 1.png";
import svg3 from "../assets/images/svg_images/Product presentation-rafiki 1.png";
import svg4 from "../assets/images/svg_images/Broadcast-amico 1.png";

import vidP from "../assets/videos/Hritik_Personalization.mp4";
// import vidE from "../assets/videos/edutech.mp4";
import vidF from "../assets/videos/FlipkartFurnitures.mp4";
import { Container } from "reactstrap";
// import vidG from "../assets/videos/Gulfnews_Demo_Dynamic_background.mp4";

const UseCases = () => {
    const location = useLocation();
    useEffect(() => {
        const targetId = location.hash.slice(1); // Remove the '#' character
        const targetDiv = document.getElementById(targetId);

        if (targetDiv) {
            // Scroll to the target div using scrollIntoView
            targetDiv.scrollIntoView({ behavior: "smooth" });
        }
    }, [location]);
    return (
        <div style={{ backgroundColor: "aliceblue" }}>
            <Container>
                <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    minHeight="60vh"
                    sx={{
                        p: { xs: 3, md: 2 },
                        mb: { xs: -15 },
                        mt: { xs: -10, md: 0 },
                    }}
                    flexDirection="column"
                >
                    <Typography
                        sx={{
                            fontFamily: "Inter",
                            fontWeight: 1000,
                            color: "#3D4151",
                            fontSize: {
                                xs: "2rem",
                                md: "3rem",
                            },
                        }}
                    >
                        USE CASES
                    </Typography>
                    <Typography
                        mt={1}
                        variant="body1"
                        sx={{
                            fontWeight: 400,
                            lineHeight: "1.5",
                            fontSize: {
                                xs: "0.75rem",
                                md: "1.3rem",
                            },
                            textAlign: { xs: "center", md: "left" },
                        }}
                    >
                        Check out our case studies and see how{" "}
                        <strong>Kroop AI</strong> can take your business to the
                        next level!
                    </Typography>
                </Box>
                <div id="hyper-personalization"></div>
                <UseCaseLeft
                    img={require("../assets/images/svg_images/svg-use.png")}
                    vid={vidP}
                    title="Hyper Personalization"
                    subtitle="Create Personalized videos for your needs"
                    text="Hyper-personalization plays a crucial role in enabling effective communication by tailoring messages, content, and channels to individual preferences and needs. Here's how hyper-personalization contributes to effective communication"
                />
                <div id="product-explainer"></div>
                <UseCaseRight
                    img={svg3}
                    vid="https://kroop-website-assets.s3.ap-south-1.amazonaws.com/64ba4d94cdf807790930ff69.mp4"
                    title="Product Explainer Videos"
                    subtitle="Generate tutorial videos with text prompts"
                    text="Product explainer videos fall in the “Entire Content Generation” Category where there is no base template or a pre-recorded script with placeholders. You can use our in-house avatars or create your own avatars to create videos with just text as input."
                />
                <div id="edutech"></div>
                <UseCaseLeft
                    img={svg2}
                    vid="https://kroop-website-assets.s3.ap-south-1.amazonaws.com/eddemo.mp4"
                    title="EDU-TECH"
                    subtitle="Enhance your learning experience with digital avatars"
                    text="AI-generated videos are revolutionizing the field of education technology (Edutech) by enhancing the learning experience for students and teachers alike."
                />
                <div id="digital-news-anchor"></div>
                <UseCaseRight
                    img={svg4}
                    vid="https://kroop-website-assets.s3.ap-south-1.amazonaws.com/news_anchor.mp4"
                    title="Digital News Anchor"
                    subtitle="Generate tutorial videos with text prompts"
                    text="A digital news anchor using AI refers to the use of artificial intelligence technology to generate and present news content in a digital format."
                />
            </Container>
        </div>
    );
};

export default UseCases;

import React, { useRef, useState } from "react";
import {
    Box,
    Card,
    // CardContent,
    // CardMedia,
    Typography,
    Button,
    Grid
} from "@mui/material";
import PlayCircleFilledOutlinedIcon from '@mui/icons-material/PlayCircleFilledOutlined';


function VideoPlayer() {
    const [isPlaying, setIsPlaying] = useState(false);
    const videoRef = useRef(null);

    const handleButtonClick = () => {
      setIsPlaying(true);
      playVideoInFullScreen();
    };
  
    const playVideoInFullScreen = () => {
        const videoElement = videoRef.current;
        if (videoElement) {
          videoElement.play()
            .then(() => {
              if (videoElement.requestFullscreen) {
                videoElement.requestFullscreen()
                  .catch((error) => console.log('Error entering fullscreen:', error));
              }
            })
            .catch((error) => console.log('Error playing video:', error));
        }
      };
    
      const handleFullScreenChange = () => {
        if (document.fullscreenElement === null) {
          setIsPlaying(false);
        }
      };
    
      // Listen for fullscreenchange event to exit fullscreen mode
      React.useEffect(() => {
        document.addEventListener('fullscreenchange', handleFullScreenChange);
        return () => {
          document.removeEventListener('fullscreenchange', handleFullScreenChange);
        };
      }, []);

    return (
      <div style={{ position: 'relative', width: '100vw', height: '100vh' }}>
        {!isPlaying ? (
          <Button
            className="btn-primary"
            sx={{
              bgcolor: 'white',
              borderRadius: '25px',
              p: 1,
              position: 'absolute',
              top: '40%',
              left: '65%',
              transform: 'translate(-50%, -50%)',
              padding: '12px 24px',
            }}
            onClick={handleButtonClick}
          >
            <PlayCircleFilledOutlinedIcon style={{ color: 'blue' }} />
            <Typography
              sx={{
                color: 'gray',
                fontSize: { xs: '12px', md: '12px' },
                fontWeight: 600,
                textAlign: 'center',
                ml: 1,
              }}
            >
              See How it Works
            </Typography>
          </Button>
        ) : (
          <div>
            <video
              id="videoPlayer"
              controls
              autoPlay
              onEnded={() => setIsPlaying(false)} // Handle video end to reset the state
            >
              <source src="https://kroop-website-assets.s3.ap-south-1.amazonaws.com/The+Artiste+by+KROOP+AI.mp4" type="video/mp4" />
              {/* You can add more source elements for different video formats if needed */}
              Your browser does not support the video tag.
            </video>
          </div>
        )}
      </div>
    );
  }

const LandingPageArtiste = () => {
    return (<Box
        sx={{
            // bgcolor: "#F6FAFD",
            p: 15,
            // display: "flex",
            // justifyContent: "center",
            backgroundImage: `url(${require("../assets/images/background_images/bg_landing_artiste.png")})`,
            backgroundRepeat: "no-repeat",
            // backgroundSize: "cover",
            backgroundPosition: "center center"
        }}
    >
        <Grid container spacing={1}>
            <Grid item md={6}>
                <Card
                    sx={{
                        bgcolor: "#4FB6EC",
                        pt: 15,
                        pb: 15,
                        pr: 15,
                        pl: 10,
                        borderRadius: "50px",
                        width: "70%",
                        position: "relative",
                        left: "30%",
                        bottom: "15%"
                    }}
                >
                    <Typography
                        // variant="h1"
                        component="div"
                        sx={{
                            color: "white",
                            fontSize: { xs: "12px", md: "35px" },
                            fontWeight: 200,
                            textAlign: 'left'
                        }}
                    >
                        Introducing
                        {/* The Artiste AI */}
                    </Typography>
                    <Typography
                        variant="h1"
                        component="div"
                        sx={{
                            color: "white",
                            fontSize: { xs: "12px", md: "50px" },
                            fontWeight: 700,
                            textAlign: 'left'
                        }}
                    >
                        ARTISTE
                    </Typography>

                    <Typography
                        // variant="h1"
                        // component="div"
                        sx={{
                            color: "white",
                            fontSize: { xs: "12px", md: "15Px" },
                            fontWeight: 400,
                            textAlign: 'left',
                            mt: 5,
                        }}
                    >
                        Visual Content Generation platform to help you reduce your costs and maximize your reach without language and location barriers.
                    </Typography>
                    <Button
                        //  variant="contained"
                        className="btn-primary"
                        sx={{
                            mt: 5,
                            background: "white",
                            borderRadius: "25px",
                            width: "60%",
                            color: 'gray',
                            p: 1,
                            textAlign: 'center'
                        }}
                        href="https://studio.theartiste.ai/app/t2v"
                    >
                        Try It Out
                    </Button>
                </Card>
            </Grid>

            <Grid item md={6}>
                <Box
                    sx={{
                        zIndex: 5,
                        position: "relative",
                        right: "25%"
                    }}
                >
                    <img src={require("../assets/images/background_images/MacBook Pro 14.png")} alt=""></img>
                    {/* <VideoPlayer /> */}
                </Box>
            </Grid>
        </Grid>
    </Box>);
}

export default LandingPageArtiste;
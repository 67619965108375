import { useEffect } from 'react';

const useOutsideClickHandler = (ref, handleClose) => {
  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        handleClose();
      }
    };

    document.addEventListener('click', handleOutsideClick);
    return () => {
      document.removeEventListener('click', handleOutsideClick);
    };
  }, [ref, handleClose]);
};

export default useOutsideClickHandler;

export const avatars = [
    {
        _id: {
            $oid: "62d90c7c12b5a0ebf9cd57bf",
        },
        name: "Shruti Formal",
        url: "https://a2v-synthesis-h6sv37.s3.ap-south-1.amazonaws.com/synthesis/static_config/avatars/shruti_formal.mp4",
        thumbnail_url:
            "https://a2v-synthesis-h6sv37.s3.ap-south-1.amazonaws.com/synthesis/static_config/avatars/thumbnail_shruti_formal.png",
        categories: {
            gender: "female",
        },
        is_active: true,
    },
    {
        _id: {
            $oid: "62dd0568dec0f8ad9f212a16",
        },
        name: "Denis Casual",
        url: "https://a2v-synthesis-h6sv37.s3.ap-south-1.amazonaws.com/synthesis/static_config/avatars/denis_casual.mp4",
        thumbnail_url:
            "https://a2v-synthesis-h6sv37.s3.ap-south-1.amazonaws.com/synthesis/static_config/avatars/thumbnail_denis_casual.png",
        categories: {
            gender: "male",
        },
        is_active: true,
    },
    {
        _id: {
            $oid: "648312d7d3bee311bdcb139c",
        },
        name: "Nishant Formal",
        type: "avatar",
        alias: "nishant_formal",
        url: "https://a2v-synthesis-h6sv37.s3.ap-south-1.amazonaws.com/synthesis/static_config/avatars/nishant_formal.mp4",
        thumbnail_url:
            "https://a2v-synthesis-h6sv37.s3.ap-south-1.amazonaws.com/synthesis/static_config/avatars/thumbnail_nishant_formal_zoomout.png",
        categories: {
            gender: "male",
            apparel_type: "formal",
        },
        is_active: true,
    },
    {
        _id: {
            $oid: "62d90c9612b5a0ebf9cd57c0",
        },
        name: "Denis Formal",
        url: "https://a2v-synthesis-h6sv37.s3.ap-south-1.amazonaws.com/synthesis/static_config/avatars/denis_formal.mp4",
        thumbnail_url:
            "https://a2v-synthesis-h6sv37.s3.ap-south-1.amazonaws.com/synthesis/static_config/avatars/thumbnail_denis_formal.png",
        categories: {
            gender: "male",
        },
        is_active: true,
    },
    {
        _id: {
            $oid: "636614fe50a97c74bb2184ec",
        },
        name: "Aman Formal",
        url: "https://a2v-synthesis-h6sv37.s3.ap-south-1.amazonaws.com/synthesis/static_config/avatars/aman_formal.mp4",
        thumbnail_url:
            "https://a2v-synthesis-h6sv37.s3.ap-south-1.amazonaws.com/synthesis/static_config/avatars/thumbnail_aman_formal.png",
        categories: {
            gender: "male",
        },
        is_active: true,
    },
    {
        _id: {
            $oid: "62d90c5c12b5a0ebf9cd57be",
        },
        name: "Ksenia Formal",
        url: "https://a2v-synthesis-h6sv37.s3.ap-south-1.amazonaws.com/synthesis/static_config/avatars/ksenia_formal.mp4",
        thumbnail_url:
            "https://a2v-synthesis-h6sv37.s3.ap-south-1.amazonaws.com/synthesis/static_config/avatars/thumbnail_ksenia_formal.png",
        categories: {
            gender: "female",
        },
        is_active: true,
    },
    {
        _id: {
            $oid: "62d90c1a12b5a0ebf9cd57bc",
        },
        name: "Ksenia Ethnic",
        url: "https://a2v-synthesis-h6sv37.s3.ap-south-1.amazonaws.com/synthesis/static_config/avatars/ksenia_ethnic.mp4",
        thumbnail_url:
            "https://a2v-synthesis-h6sv37.s3.ap-south-1.amazonaws.com/synthesis/static_config/avatars/thumbnail_ksenia_ethnic.png",
        categories: {
            gender: "female",
        },
        is_active: true,
    },
    {
        _id: {
            $oid: "6366153250a97c74bb2184ee",
        },
        name: "Himanshu Formal",
        url: "https://a2v-synthesis-h6sv37.s3.ap-south-1.amazonaws.com/synthesis/static_config/avatars/himanshu_formal.mp4",
        thumbnail_url:
            "https://a2v-synthesis-h6sv37.s3.ap-south-1.amazonaws.com/synthesis/static_config/avatars/thumbnail_himanshu_formal.png",
        categories: {
            gender: "male",
        },
        is_active: true,
    },
    {
      _id: {
          $oid: "6269a5b77e8aabd17b6ebb2b",
      },
      name: "Ritik Fitness",
      url: "https://a2v-synthesis-h6sv37.s3.ap-south-1.amazonaws.com/synthesis/static_config/avatars/kshitij_fitness.mp4",
      thumbnail_url:
          "https://a2v-synthesis-h6sv37.s3.ap-south-1.amazonaws.com/synthesis/static_config/avatars/thumbnail_kshitij_fitness.png",
      categories: {
          gender: "male",
      },
      is_active: true,
  },
    {
        _id: {
            $oid: "623443a5a638257dac32d547",
        },
        name: "Dhruv Formal",
        url: "https://a2v-synthesis-h6sv37.s3.ap-south-1.amazonaws.com/synthesis/static_config/avatars/manav_formal.mp4",
        thumbnail_url:
            "https://a2v-synthesis-h6sv37.s3.ap-south-1.amazonaws.com/synthesis/static_config/avatars/thumbnail_manav_formal.png",
        categories: {
            gender: "male",
        },
        is_active: true,
    },
    {
        _id: {
            $oid: "62d90cb912b5a0ebf9cd57c1",
        },
        name: "Aakriti Ethnic",
        url: "https://a2v-synthesis-h6sv37.s3.ap-south-1.amazonaws.com/synthesis/static_config/avatars/aakriti_ethnic.mp4",
        thumbnail_url:
            "https://a2v-synthesis-h6sv37.s3.ap-south-1.amazonaws.com/synthesis/static_config/avatars/thumbnail_aakriti_ethnic.png",
        categories: {
            gender: "female",
        },
        is_active: true,
    },
    {
        _id: {
            $oid: "6275112409aad15aa1594a1c",
        },
        name: "Ananya Formal",
        url: "https://a2v-synthesis-h6sv37.s3.ap-south-1.amazonaws.com/synthesis/static_config/avatars/jyot_formal.mp4",
        thumbnail_url:
            "https://a2v-synthesis-h6sv37.s3.ap-south-1.amazonaws.com/synthesis/static_config/avatars/thumbnail_jyot_formal.png",
        categories: {
            gender: "female",
        },
        is_active: true,
    },
    {
        _id: {
            $oid: "62d9090112b5a0ebf9cd57b6",
        },
        name: "Kiran Formal",
        url: "https://a2v-synthesis-h6sv37.s3.ap-south-1.amazonaws.com/synthesis/static_config/avatars/kiran_formal.mp4",
        thumbnail_url:
            "https://a2v-synthesis-h6sv37.s3.ap-south-1.amazonaws.com/synthesis/static_config/avatars/thumbnail_kiran_formal.png",
        categories: {
            gender: "female",
        },
        is_active: true,
    },
    {
        _id: {
            $oid: "62d90c3912b5a0ebf9cd57bd",
        },
        name: "Varuna Ethnic",
        url: "https://a2v-synthesis-h6sv37.s3.ap-south-1.amazonaws.com/synthesis/static_config/avatars/varuna_ethnic.mp4",
        thumbnail_url:
            "https://a2v-synthesis-h6sv37.s3.ap-south-1.amazonaws.com/synthesis/static_config/avatars/thumbnail_varuna_ethnic.png",
        categories: {
            gender: "female",
        },
        is_active: true,
    },
    {
        _id: {
            $oid: "636614e850a97c74bb2184eb",
        },
        name: "Fiza Formal",
        url: "https://a2v-synthesis-h6sv37.s3.ap-south-1.amazonaws.com/synthesis/static_config/avatars/fiza_formal.mp4",
        thumbnail_url:
            "https://a2v-synthesis-h6sv37.s3.ap-south-1.amazonaws.com/synthesis/static_config/avatars/thumbnail_fiza_formal.png",
        categories: {
            gender: "female",
        },
        is_active: true,
    },
    {
        _id: {
            $oid: "62cf2ef14638b306b4ced015",
        },
        name: "Varuna Formal",
        url: "https://a2v-synthesis-h6sv37.s3.ap-south-1.amazonaws.com/synthesis/static_config/avatars/varuna_formal.mp4",
        thumbnail_url:
            "https://a2v-synthesis-h6sv37.s3.ap-south-1.amazonaws.com/synthesis/static_config/avatars/thumbnail_varuna_formal.png",
        categories: {
            gender: "female",
        },
        is_active: true,
    },
    {
        _id: {
            $oid: "62d90cd812b5a0ebf9cd57c2",
        },
        name: "Shruti Casual",
        url: "https://a2v-synthesis-h6sv37.s3.ap-south-1.amazonaws.com/synthesis/static_config/avatars/shruti_casual.mp4",
        thumbnail_url:
            "https://a2v-synthesis-h6sv37.s3.ap-south-1.amazonaws.com/synthesis/static_config/avatars/thumbnail_shruti_casual.png",
        categories: {
            gender: "female",
        },
        is_active: true,
    },
    {
        _id: {
            $oid: "636615a050a97c74bb2184f2",
        },
        name: "Aakriti Formal",
        url: "https://a2v-synthesis-h6sv37.s3.ap-south-1.amazonaws.com/synthesis/static_config/avatars/aakriti_formal.mp4",
        thumbnail_url:
            "https://a2v-synthesis-h6sv37.s3.ap-south-1.amazonaws.com/synthesis/static_config/avatars/thumbnail_aakriti_formal.png",
        categories: {
            gender: "female",
        },
        is_active: true,
    },
    {
        _id: {
            $oid: "62d9092912b5a0ebf9cd57b7",
        },
        name: "Kiran Ethnic",
        url: "https://a2v-synthesis-h6sv37.s3.ap-south-1.amazonaws.com/synthesis/static_config/avatars/kiran_ethnic.mp4",
        thumbnail_url:
            "https://a2v-synthesis-h6sv37.s3.ap-south-1.amazonaws.com/synthesis/static_config/avatars/thumbnail_kiran_ethnic.png",
        categories: {
            gender: "female",
        },
        is_active: true,
    },
    {
        _id: {
            $oid: "624eee6b3a8dd6549af9a3f5",
        },
        name: "Diya Formal",
        url: "https://a2v-synthesis-h6sv37.s3.ap-south-1.amazonaws.com/synthesis/static_config/avatars/tamanna_formal.mp4",
        thumbnail_url:
            "https://a2v-synthesis-h6sv37.s3.ap-south-1.amazonaws.com/synthesis/static_config/avatars/thumbnail_tamanna_formal.png",
        categories: {
            gender: "female",
        },
        is_active: true,
    },

    {
        _id: {
            $oid: "627511eb09aad15aa1594a22",
        },
        name: "Sudha Casual",
        url: "https://a2v-synthesis-h6sv37.s3.ap-south-1.amazonaws.com/synthesis/static_config/avatars/poonam_casual.mp4",
        thumbnail_url:
            "https://a2v-synthesis-h6sv37.s3.ap-south-1.amazonaws.com/synthesis/static_config/avatars/thumbnail_poonam_casual.png",
        categories: {
            gender: "female",
        },
        is_active: true,
    },
    {
        _id: {
            $oid: "624eee963a8dd6549af9a3f6",
        },
        name: "Dhruv Ethnic",
        url: "https://a2v-synthesis-h6sv37.s3.ap-south-1.amazonaws.com/synthesis/static_config/avatars/manav_ethnic.mp4",
        thumbnail_url:
            "https://a2v-synthesis-h6sv37.s3.ap-south-1.amazonaws.com/synthesis/static_config/avatars/thumbnail_manav_ethnic.png",
        categories: {
            gender: "male",
        },
        is_active: true,
    },
    {
        _id: {
            $oid: "6275115d09aad15aa1594a1e",
        },
        name: "Ananya Bride",
        url: "https://a2v-synthesis-h6sv37.s3.ap-south-1.amazonaws.com/synthesis/static_config/avatars/jyot_bride.mp4",
        thumbnail_url:
            "https://a2v-synthesis-h6sv37.s3.ap-south-1.amazonaws.com/synthesis/static_config/avatars/thumbnail_jyot_bride.png",
        categories: {
            gender: "female",
        },
        is_active: true,
    },
    {
        _id: {
            $oid: "6269a54b7e8aabd17b6ebb29",
        },
        name: "Tracy Casual",
        url: "https://a2v-synthesis-h6sv37.s3.ap-south-1.amazonaws.com/synthesis/static_config/avatars/uma_casual.mp4",
        thumbnail_url:
            "https://a2v-synthesis-h6sv37.s3.ap-south-1.amazonaws.com/synthesis/static_config/avatars/thumbnail_uma_casual.png",
        categories: {
            gender: "female",
        },
        is_active: true,
    },
    {
        _id: {
            $oid: "6269a59d7e8aabd17b6ebb2a",
        },
        name: "Ritik Casual",
        url: "https://a2v-synthesis-h6sv37.s3.ap-south-1.amazonaws.com/synthesis/static_config/avatars/kshitij_casual.mp4",
        thumbnail_url:
            "https://a2v-synthesis-h6sv37.s3.ap-south-1.amazonaws.com/synthesis/static_config/avatars/thumbnail_kshitij_casual.png",
        categories: {
            gender: "male",
        },
        is_active: true,
    },
    {
        _id: {
            $oid: "61bcc9db46bc8f49b44eb997",
        },
        name: "Neevi Casual",
        url: "https://a2v-synthesis-h6sv37.s3.ap-south-1.amazonaws.com/synthesis/static_config/avatars/priyanshi_casual.mp4",
        thumbnail_url:
            "https://a2v-synthesis-h6sv37.s3.ap-south-1.amazonaws.com/synthesis/static_config/avatars/thumbnail_priyanshi_casual.png",
        categories: {
            gender: "female",
        },
        is_active: true,
    },
    {
        _id: {
            $oid: "6269a5db7e8aabd17b6ebb2c",
        },
        name: "Ritik Formal",
        url: "https://a2v-synthesis-h6sv37.s3.ap-south-1.amazonaws.com/synthesis/static_config/avatars/kshitij_formal.mp4",
        thumbnail_url:
            "https://a2v-synthesis-h6sv37.s3.ap-south-1.amazonaws.com/synthesis/static_config/avatars/thumbnail_kshitij_formal.png",
        categories: {
            gender: "male",
        },
        is_active: true,
    },
    {
        _id: {
            $oid: "61be010c934ea00688c50db3",
        },
        name: "Sara Ethnic",
        url: "https://a2v-synthesis-h6sv37.s3.ap-south-1.amazonaws.com/synthesis/static_config/avatars/suhina_ethnic.mp4",
        thumbnail_url:
            "https://a2v-synthesis-h6sv37.s3.ap-south-1.amazonaws.com/synthesis/static_config/avatars/thumbnail_suhina_ethnic.png",
        categories: {
            gender: "female",
        },
        is_active: true,
    },
    {
        _id: {
            $oid: "61be1a1d934ea00688c50db9",
        },
        name: "Rajat Formal",
        url: "https://a2v-synthesis-h6sv37.s3.ap-south-1.amazonaws.com/synthesis/static_config/avatars/rajat_formal.mp4",
        thumbnail_url:
            "https://a2v-synthesis-h6sv37.s3.ap-south-1.amazonaws.com/synthesis/static_config/avatars/thumbnail_rajat_formal.png",
        categories: {
            gender: "male",
        },
        is_active: true,
    },
    {
        _id: {
            $oid: "61c746c77de2e026073c30db",
        },
        name: "Ishani Ethnic",
        url: "https://a2v-synthesis-h6sv37.s3.ap-south-1.amazonaws.com/synthesis/static_config/avatars/rutu_ethnic.mp4",
        thumbnail_url:
            "https://a2v-synthesis-h6sv37.s3.ap-south-1.amazonaws.com/synthesis/static_config/avatars/thumbnail_rutu_ethnic.png",
        categories: {
            gender: "female",
        },
        is_active: true,
    },
    {
        _id: {
            $oid: "6275113d09aad15aa1594a1d",
        },
        name: "Ananya Casual",
        url: "https://a2v-synthesis-h6sv37.s3.ap-south-1.amazonaws.com/synthesis/static_config/avatars/jyot_casual.mp4",
        thumbnail_url:
            "https://a2v-synthesis-h6sv37.s3.ap-south-1.amazonaws.com/synthesis/static_config/avatars/thumbnail_jyot_casual.png",
        categories: {
            gender: "female",
        },
        is_active: true,
    },
    {
        _id: {
            $oid: "627511ba09aad15aa1594a20",
        },
        name: "Kartik Ethnic",
        url: "https://a2v-synthesis-h6sv37.s3.ap-south-1.amazonaws.com/synthesis/static_config/avatars/pankaj_ethnic.mp4",
        thumbnail_url:
            "https://a2v-synthesis-h6sv37.s3.ap-south-1.amazonaws.com/synthesis/static_config/avatars/thumbnail_pankaj_ethnic.png",
        categories: {
            gender: "male",
        },
        is_active: true,
    },
    {
        _id: {
            $oid: "628b89096f5f93bb102660f4",
        },
        name: "Dhara Doctor",
        url: "https://a2v-synthesis-h6sv37.s3.ap-south-1.amazonaws.com/synthesis/static_config/avatars/nishita_doctor.mp4",
        thumbnail_url:
            "https://a2v-synthesis-h6sv37.s3.ap-south-1.amazonaws.com/synthesis/static_config/avatars/thumbnail_nishita_doctor.png",
        categories: {
            gender: "female",
        },
        is_active: true,
    },
    {
        _id: {
            $oid: "62c57b934638b306b4cecff2",
        },
        name: "Rajat Formal",
        url: "https://a2v-synthesis-h6sv37.s3.ap-south-1.amazonaws.com/synthesis/static_config/avatars/rajat_formal.mp4",
        thumbnail_url:
            "https://a2v-synthesis-h6sv37.s3.ap-south-1.amazonaws.com/synthesis/static_config/avatars/thumbnail_rajat_formal.png",
        categories: {
            gender: "male",
        },
        is_active: true,
    },
    {
        _id: {
            $oid: "61bcc9db46bc8f49b44eb996",
        },
        name: "Neevi Formal",
        url: "https://a2v-synthesis-h6sv37.s3.ap-south-1.amazonaws.com/synthesis/static_config/avatars/priyanshi_formal.mp4",
        thumbnail_url:
            "https://a2v-synthesis-h6sv37.s3.ap-south-1.amazonaws.com/synthesis/static_config/avatars/thumbnail_priyanshi_formal.png",
        categories: {
            gender: "female",
        },
        is_active: true,
    },
    {
        _id: {
            $oid: "6364b85050a97c74bb2184e5",
        },
        name: "Shruti Casual Zoomin",
        url: "https://a2v-synthesis-h6sv37.s3.ap-south-1.amazonaws.com/synthesis/static_config/avatars/shruti_casual_zoomin.mp4",
        thumbnail_url:
            "https://a2v-synthesis-h6sv37.s3.ap-south-1.amazonaws.com/synthesis/static_config/avatars/thumbnail_shruti_casual_zoomin.png",
        categories: {
            gender: "female",
        },
        is_active: true,
    },
    {
        _id: {
            $oid: "636614b550a97c74bb2184ea",
        },
        name: "Fiza Casual Zoomin",
        url: "https://a2v-synthesis-h6sv37.s3.ap-south-1.amazonaws.com/synthesis/static_config/avatars/fiza_casual_zoomin.mp4",
        thumbnail_url:
            "https://a2v-synthesis-h6sv37.s3.ap-south-1.amazonaws.com/synthesis/static_config/avatars/thumbnail_fiza_casual_zoomin.png",
        categories: {
            gender: "female",
        },
        is_active: true,
    },
    {
        _id: {
            $oid: "61be010c934ea00688c50db2",
        },
        name: "Aditi Formal",
        url: "https://a2v-synthesis-h6sv37.s3.ap-south-1.amazonaws.com/synthesis/static_config/avatars/smita_formal.mp4",
        thumbnail_url:
            "https://a2v-synthesis-h6sv37.s3.ap-south-1.amazonaws.com/synthesis/static_config/avatars/thumbnail_smita_formal.png",
        categories: {
            gender: "female",
        },
        is_active: true,
    },
    {
        _id: {
            $oid: "62500ab13a8dd6549af9a3fe",
        },
        name: "Meta Shahrukh",
        url: "https://a2v-synthesis-h6sv37.s3.ap-south-1.amazonaws.com/synthesis/static_config/avatars/meta_shahrukh.mp4",
        thumbnail_url:
            "https://a2v-synthesis-h6sv37.s3.ap-south-1.amazonaws.com/synthesis/static_config/avatars/thumbnail_meta_shahrukh.png",
        categories: {
            gender: "male",
        },
        is_active: true,
    },
    {
        _id: {
            $oid: "625026ea3a8dd6549af9a404",
        },
        name: "Agatsya ethnic",
        url: "https://a2v-synthesis-h6sv37.s3.ap-south-1.amazonaws.com/synthesis/static_config/avatars/manas_ethnic.mp4",
        thumbnail_url:
            "https://a2v-synthesis-h6sv37.s3.ap-south-1.amazonaws.com/synthesis/static_config/avatars/thumbnail_manas_ethnic.png",
        categories: {
            gender: "male",
        },
        is_active: true,
    },
    {
        _id: {
            $oid: "6269a6407e8aabd17b6ebb2e",
        },
        name: "Aman Casual",
        url: "https://a2v-synthesis-h6sv37.s3.ap-south-1.amazonaws.com/synthesis/static_config/avatars/ayush_casual.mp4",
        thumbnail_url:
            "https://a2v-synthesis-h6sv37.s3.ap-south-1.amazonaws.com/synthesis/static_config/avatars/thumbnail_ayush_casual.png",
        categories: {
            gender: "male",
        },
        is_active: true,
    },
    {
        _id: {
            $oid: "62500a893a8dd6549af9a3fd",
        },
        name: "Diya Ethnic",
        url: "https://a2v-synthesis-h6sv37.s3.ap-south-1.amazonaws.com/synthesis/static_config/avatars/tamanna_ethnic.mp4",
        thumbnail_url:
            "https://a2v-synthesis-h6sv37.s3.ap-south-1.amazonaws.com/synthesis/static_config/avatars/thumbnail_tamanna_ethnic.png",
        categories: {
            gender: "female",
        },
        is_active: true,
    },
    {
        _id: {
            $oid: "6269a6267e8aabd17b6ebb2d",
        },
        name: "Aman Formal",
        url: "https://a2v-synthesis-h6sv37.s3.ap-south-1.amazonaws.com/synthesis/static_config/avatars/ayush_formal.mp4",
        thumbnail_url:
            "https://a2v-synthesis-h6sv37.s3.ap-south-1.amazonaws.com/synthesis/static_config/avatars/thumbnail_ayush_formal.png",
        categories: {
            gender: "male",
        },
        is_active: true,
    },
    {
        _id: {
            $oid: "6269ae007e8aabd17b6ebb32",
        },
        name: "Tracy Formal",
        url: "https://a2v-synthesis-h6sv37.s3.ap-south-1.amazonaws.com/synthesis/static_config/avatars/uma_formal.mp4",
        thumbnail_url:
            "https://a2v-synthesis-h6sv37.s3.ap-south-1.amazonaws.com/synthesis/static_config/avatars/thumbnail_uma_formal.png",
        categories: {
            gender: "female",
        },
        is_active: true,
    },
    {
        _id: {
            $oid: "62b5bc7740276a11a49ef814",
        },
        name: "Meta Drake",
        url: "https://a2v-synthesis-h6sv37.s3.ap-south-1.amazonaws.com/synthesis/static_config/avatars/meta_drake.mp4",
        thumbnail_url:
            "https://a2v-synthesis-h6sv37.s3.ap-south-1.amazonaws.com/synthesis/static_config/avatars/thumbnail_meta_drake.png",
        categories: {
            gender: "male",
        },
        is_active: true,
    },
    {
        _id: {
            $oid: "6364b82f50a97c74bb2184e4",
        },
        name: "Varuna Ethnic Zoomin",
        url: "https://a2v-synthesis-h6sv37.s3.ap-south-1.amazonaws.com/synthesis/static_config/avatars/varuna_ethnic_zoomin.mp4",
        thumbnail_url:
            "https://a2v-synthesis-h6sv37.s3.ap-south-1.amazonaws.com/synthesis/static_config/avatars/thumbnail_varuna_ethnic_zoomin.png",
        categories: {
            gender: "female",
        },
        is_active: true,
    },
    {
        _id: {
            $oid: "6366151350a97c74bb2184ed",
        },
        name: "Aman Formal Zoomin",
        url: "https://a2v-synthesis-h6sv37.s3.ap-south-1.amazonaws.com/synthesis/static_config/avatars/aman_formal_zoomin.mp4",
        thumbnail_url:
            "https://a2v-synthesis-h6sv37.s3.ap-south-1.amazonaws.com/synthesis/static_config/avatars/thumbnail_aman_formal_zoomin.png",
        categories: {
            gender: "male",
        },
        is_active: true,
    },
    {
        _id: {
            $oid: "6366154850a97c74bb2184ef",
        },
        name: "Himanshu Formal Zoomin",
        url: "https://a2v-synthesis-h6sv37.s3.ap-south-1.amazonaws.com/synthesis/static_config/avatars/himanshu_formal_zoomin.mp4",
        thumbnail_url:
            "https://a2v-synthesis-h6sv37.s3.ap-south-1.amazonaws.com/synthesis/static_config/avatars/thumbnail_himanshu_formal_zoomin.png",
        categories: {
            gender: "male",
        },
        is_active: true,
    },
    {
        _id: {
            $oid: "61c0656ac8d9246b28e023c4",
        },
        name: "Meta Nikhil",
        url: "https://a2v-synthesis-h6sv37.s3.ap-south-1.amazonaws.com/synthesis/static_config/avatars/meta_nikhil.mp4",
        thumbnail_url:
            "https://a2v-synthesis-h6sv37.s3.ap-south-1.amazonaws.com/synthesis/static_config/avatars/thumbnail_meta_nikhil.png",
        categories: {
            gender: "male",
        },
        is_active: true,
    },
    {
        _id: {
            $oid: "61c0656ac8d9246b28e023c5",
        },
        name: "Agatsya formal",
        url: "https://a2v-synthesis-h6sv37.s3.ap-south-1.amazonaws.com/synthesis/static_config/avatars/manas_formal.mp4",
        thumbnail_url:
            "https://a2v-synthesis-h6sv37.s3.ap-south-1.amazonaws.com/synthesis/static_config/avatars/thumbnail_manas_formal.png",
        categories: {
            gender: "male",
        },
        is_active: true,
    },
    {
        _id: {
            $oid: "62500ad33a8dd6549af9a3ff",
        },
        name: "Meta Salena",
        url: "https://a2v-synthesis-h6sv37.s3.ap-south-1.amazonaws.com/synthesis/static_config/avatars/meta_salena.mp4",
        thumbnail_url:
            "https://a2v-synthesis-h6sv37.s3.ap-south-1.amazonaws.com/synthesis/static_config/avatars/thumbnail_meta_salena.png",
        categories: {
            gender: "female",
        },
        is_active: true,
    },
    {
        _id: {
            $oid: "6269a51b7e8aabd17b6ebb28",
        },
        name: "Tracy Ethnic",
        url: "https://a2v-synthesis-h6sv37.s3.ap-south-1.amazonaws.com/synthesis/static_config/avatars/uma_ethnic.mp4",
        thumbnail_url:
            "https://a2v-synthesis-h6sv37.s3.ap-south-1.amazonaws.com/synthesis/static_config/avatars/thumbnail_uma_ethnic.png",
        categories: {
            gender: "female",
        },
        is_active: true,
    },
    {
        _id: {
            $oid: "627511a009aad15aa1594a1f",
        },
        name: "Kartik Formal",
        url: "https://a2v-synthesis-h6sv37.s3.ap-south-1.amazonaws.com/synthesis/static_config/avatars/pankaj_formal.mp4",
        thumbnail_url:
            "https://a2v-synthesis-h6sv37.s3.ap-south-1.amazonaws.com/synthesis/static_config/avatars/thumbnail_pankaj_formal.png",
        categories: {
            gender: "male",
        },
        is_active: true,
    },
    {
        _id: {
            $oid: "628b88f46f5f93bb102660f3",
        },
        name: "Dhara Casual",
        url: "https://a2v-synthesis-h6sv37.s3.ap-south-1.amazonaws.com/synthesis/static_config/avatars/nishita_casual.mp4",
        thumbnail_url:
            "https://a2v-synthesis-h6sv37.s3.ap-south-1.amazonaws.com/synthesis/static_config/avatars/thumbnail_nishita_casual.png",
        categories: {
            gender: "female",
        },
        is_active: true,
    },
    {
        _id: {
            $oid: "6366157850a97c74bb2184f1",
        },
        name: "Himanshu Doctor Zoomin",
        url: "https://a2v-synthesis-h6sv37.s3.ap-south-1.amazonaws.com/synthesis/static_config/avatars/himanshu_doctor_zoomin.mp4",
        thumbnail_url:
            "https://a2v-synthesis-h6sv37.s3.ap-south-1.amazonaws.com/synthesis/static_config/avatars/thumbnail_himanshu_doctor_zoomin.png",
        categories: {
            gender: "male",
        },
        is_active: true,
    },
    {
        _id: {
            $oid: "62500c383a8dd6549af9a401",
        },
        name: "Meta Priyanka",
        url: "https://a2v-synthesis-h6sv37.s3.ap-south-1.amazonaws.com/synthesis/static_config/avatars/meta_priyanka.mp4",
        thumbnail_url:
            "https://a2v-synthesis-h6sv37.s3.ap-south-1.amazonaws.com/synthesis/static_config/avatars/thumbnail_meta_priyanka.png",
        categories: {
            gender: "female",
        },
        is_active: true,
    },
    {
        _id: {
            $oid: "6269a6577e8aabd17b6ebb2f",
        },
        name: "Aman Groom",
        url: "https://a2v-synthesis-h6sv37.s3.ap-south-1.amazonaws.com/synthesis/static_config/avatars/ayush_groom.mp4",
        thumbnail_url:
            "https://a2v-synthesis-h6sv37.s3.ap-south-1.amazonaws.com/synthesis/static_config/avatars/thumbnail_ayush_groom.png",
        categories: {
            gender: "male",
        },
        is_active: true,
    },
];

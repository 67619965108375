export const blogs = [
    {
        id:0,
        url:"https://www.linkedin.com/embed/feed/update/urn:li:share:7122254145117446144"
    },
    {
        id:0,
        url:"https://www.linkedin.com/embed/feed/update/urn:li:ugcPost:7120765836218241025"
    },
    {
        id:0,
        url:"https://www.linkedin.com/embed/feed/update/urn:li:share:7120401501310636032"
    },
    {
        id:0,
        url:"https://www.linkedin.com/embed/feed/update/urn:li:share:7110543709984763904"
    },
    {
        id:0,
        url:"https://www.linkedin.com/embed/feed/update/urn:li:ugcPost:7081506502812852224"
    },
    {
        id:0,
        url:"https://www.linkedin.com/embed/feed/update/urn:li:ugcPost:7085507787220238336"
    },
    {
        id:0,
        url:"https://www.linkedin.com/embed/feed/update/urn:li:share:7016813346372882432"
    },
    {
        id:0,
        url:"https://www.linkedin.com/embed/feed/update/urn:li:ugcPost:6943523961280299008"
    },
    {
        id:0,
        url:"https://www.linkedin.com/embed/feed/update/urn:li:ugcPost:6966351339060645888"
    },
    {
        id:0,
        url:"https://www.linkedin.com/embed/feed/update/urn:li:ugcPost:6964827987812732929"
    },
]
import React, { useState } from "react";
import {
    Box,
    Grid,
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Typography,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";

import faqImg1 from "../assets/images/svg_images/5066368.png";

const FAQ = () => {
    const faqs = [
        {
            question: "What is synthetic data?",
            answer: "Data (audio/visual/text etc.) generated by a computer algorithm is synthetic data. It is not recorded in real-world like it is generally done.",
        },
        {
            question: "What are typical synthetic data use cases?",
            answer: "Generally, most of our clients use synthetic data for personalised marketing, lip-sync correction, MetaVerse use cases and multi-lingual content generation.",
        },
        {
            question: "Is synthetic data generation safe?",
            answer: "Synthetic data has a lot of applications in advertisement & hyper-personalised messages. We source, store and generate data with appropriate consents and policies.",
        },
        {
            question: "How long does it take to generate synthetic data?",
            answer: "For generating high quality data, platform needs to learn the audio-visual patterns through training. Our tech can train & create a high fidelity audio-visual digital avatar in around 7 days.",
        },
        {
            question:
                "How much ‘real’ audio/video data required to create a digital avatar?",
            answer: "With around 10 minutes of video data containing the face & 30 minutes of clean speech audio, a digital avatar can be created with audio/video model",
        },
        {
            question:
                "How easy it is to integrate Kroop AI solution to any existing stack?",
            answer: "Very easy! Kroop AI solution can be used as a Plug’n’Play solution with just API level calls. With our cloud studio IDE, an editor is also available to generate the content.",
        },
        {
            question: "Can synthetic data of celebrities be generated?",
            answer: "Technology can generate anybody’s data but to use it for any commercial/advertisement purpose, consent & legal contract with the celebrity is required.",
        },
        {
            question:
                "Does Kroop AI application provide lip-sync while dubbing in real time?",
            answer: "Lip-sync in MetaVerse is close to real time. However, for `real’ avatars-based data (e.g. movies) the lip-sync is done as post processing to obtain high quality.",
        },
        {
            question: "Can the tool generate videos with audio as input?",
            answer: "Yes, audio only can also be used to generate videos.",
        },
        {
            question:
                "Does Kroop AI provide Audio deep fake detection as well?",
            answer: "Yes, both video & audio deep fake detection is provided as API for content creators to check any misuse of their data.",
        },
    ];

    const [expanded, setExpanded] = useState(null);

    const toggleAccordion = (index) => {
        setExpanded((prev) => (prev === index ? null : index));
    };

    return (
        <>
            <Box
                display="flex"
                alignItems="center"
                justifyContent="center"
                minHeight="100vh"
                sx={{ bgcolor: "#F6FAFD", p: { xs: 3, md: 10 }, mt: 1 }}
            >
                <Grid container spacing={2}>
                    <Grid item xs={12} md={6}>
                        <Box
                            display="flex"
                            justifyContent="center"
                            alignItems="center"
                            height="100%"
                        >
                            <img
                                src={faqImg1}
                                alt="faq_image"
                                style={{ width: "70%", height: "auto" }}
                            />
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Box
                            display="flex"
                            flexDirection="column"
                            justifyContent="center"
                            alignItems={{ xs: "center", md: "flex-start" }}
                            height="100%"
                        >
                            <Typography
                                variant="h4"
                                gutterBottom
                                sx={{
                                    fontFamily: "Inter",
                                    fontWeight: "bold",
                                    fontSize: { xs: "1.2rem", md: "2.2rem"},
                                    color: "#000000",
                                    textAlign: { xs: "left", md: "left" },
                                }}
                            >
                                Frequently Asked Questions
                            </Typography>
                            {faqs.map((faq, index) => (
                                <Accordion
                                    key={index}
                                    expanded={expanded === index}
                                    onChange={() => toggleAccordion(index)}
                                    style={{
                                        // width: 600,
                                        fontFamily: "Inter",
                                        fontWeight: 400,
                                        border: "none",
                                        boxShadow: "none",
                                    }}
                                >
                                    <AccordionSummary
                                        sx={{
                                            bgcolor: "#F6FAFD",
                                        }}
                                        expandIcon={
                                            expanded === index ? (
                                                <CloseIcon
                                                    sx={{ color: "#1a0f91" }}
                                                />
                                            ) : (
                                                <AddIcon
                                                    sx={{ color: "#1a0f91" }}
                                                />
                                            )
                                        }
                                    >
                                        <Typography sx={{ fontWeight: "bold" }}>
                                            {faq.question}
                                        </Typography>
                                    </AccordionSummary>
                                    <AccordionDetails
                                        sx={{
                                            bgcolor: "#F6FAFD",
                                        }}
                                    >
                                        <Typography
                                            sx={{
                                                fontWeight: "light",
                                                opacity: 0.7,
                                            }}
                                        >
                                            {faq.answer}
                                        </Typography>
                                    </AccordionDetails>
                                </Accordion>
                            ))}
                        </Box>
                    </Grid>
                </Grid>
            </Box>
        </>
    );
};

export default FAQ;

import React from "react";
import {

    Paper
} from "@mui/material";

const Langs = ({ img, text, isFlag }) => {
    return (
        // <Paper elevation={1} sx={{
        //     // width: "em"
        //     m: 1,
        //     borderRadius: "20px",
        //     boxShadow: "0px 3px 3px 0px rgba(0,0,0,0.10)"
        // }}>

        <div style={{
            // margin: "10px",
            width: "80px",
            height: "100px",
            flexDirection: "column",
            margin: "20px",
            /* height: 180px; */
            display: "flex",
            alignItems: 'center',
            justifyContent: "space-between"
        }}>
            <img style={{
                maxHeight: isFlag? "70%": "100%" ,
                maxWidth: isFlag? "70%": "100%"
            }} src={img} alt=""/>
            <span style={{
                fontWeight: 100,
                fontSize: "14px",
                mt: "5px",
                textAlign: "center"
            }}>{text}</span>
        </div>

        // </Paper>
    )
}
export default Langs;
import React from "react";
import {
    Box,
    // Card,
    // CardContent,
    // CardMedia,
    Typography,
    // Button,
    // Grid,
    Paper
} from "@mui/material";

const LeadershipProfile = ({ title, desig, description, img }) => {
    return (<Box
        sx={{
            ml: {xs:-7,md:0},
            mt: {xs:-7, md:5},
            transform:{xs :"scale(0.65)", md:"scale(1)"}

        }}>
        <Paper elevation={0} component="div"
            sx={{
                backgroundColor: "#007DFE",
                height: "450px",
                width: "450px",
                borderRadius: "50%",
                position: "relative",
                left: "15%"
            }}>
            <Paper elevation={3} component="img"
                sx={{
                    height: "200px",
                    width: "200px",
                    borderRadius: "50%",
                    position: "relative",
                    mt: 10,
                    left: "35%",
                    zIndex: 1
                }}
                src={img} alt="">
            </Paper>
        </Paper>

        <Paper elevation={1} component="div"
            sx={{
                backgroundColor: "#FFFFF",
                height: "400px",
                width: "400px",
                borderRadius: "50%",
                position: "relative",
                // bottom: "25%"
                mt: -40,
                zIndex: 0
            }}>
            <Box sx={{
                pt: 12,
                pl: 8
            }}>
                <Typography variant="h5" sx={{
                    fontWeight: 500,
                    fontSize: {xs:"1.3rem", md:"1.5rem"}
                }}>
                    {title}
                </Typography>
                <Typography sx={{
                    fontSize: "1rem"
                }}>
                    {desig}
                </Typography>
                <Typography sx={{
                    fontSize: {xs:"0.8rem", md:"0.75rem"},
                    color: "gray",
                    pt: 3,
                    width: "85%"
                }}>
                    {description}
                </Typography>
            </Box>
        </Paper>


    </Box>)
}
export default LeadershipProfile;
import React from "react";
import { Box, Grid, Button, IconButton } from "@mui/material";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import YouTubeIcon from "@mui/icons-material/YouTube";
import TwitterIcon from "@mui/icons-material/Twitter";
import Link from "@mui/material/Link";

import logo from "../assets/images/kroop_logo.jpeg";

const FooterContainer = () => {
    return (
        <Box sx={{ bgcolor: "#FFFFF", p: { xs: 3, md: 10 } }}>
            <Grid container spacing={3}>
                <Grid item xs={12} md={4}>
                    <Box sx={{ textAlign: { xs: "center", md: "left" } }}>
                        <Link href="/">
                            <Box
                                component="img"
                                sx={{ height: 54 }}
                                alt="Logo"
                                src={logo}
                            />
                        </Link>
                        <p className="fs-6">© Kroop AI Private Limited</p>
                        <IconButton
                            className="text-capitalize"
                            variant="outlined"
                            size="large"
                            href="https://www.linkedin.com/company/kroop-ai/"
                        >
                            <LinkedInIcon fontSize="large" />
                        </IconButton>

                        <IconButton
                            className="text-capitalize"
                            variant="outlined"
                            size="large"
                            href="https://twitter.com/kroop_ai?lang=en"
                            sx={{
                                marginLeft: "10px",
                                marginRight: "10px",
                            }}
                        >
                            <YouTubeIcon fontSize="large" />
                        </IconButton>

                        <IconButton
                            className="text-capitalize"
                            variant="outlined"
                            size="large"
                        >
                            <TwitterIcon fontSize="large" />
                        </IconButton>
                    </Box>
                </Grid>
                <Grid item xs={6} md={2}>
                    <Box sx={{ textAlign: { xs: "center", md: "left" } }}>
                        <h6 className="fs-6" style={{ fontWeight: "bold" }}>
                            COMPANY
                        </h6>
                        <ul className="footer-links">
                            <li>
                                <a
                                    href="/about-us"
                                    style={{
                                        color: "#000000",
                                        textDecoration: "none",
                                    }}
                                >
                                    About
                                </a>
                            </li>
                            <li>Careers</li>
                        </ul>
                    </Box>
                </Grid>
                <Grid item xs={6} md={2}>
                    <Box sx={{ textAlign: { xs: "center", md: "left" } }}>
                        <h6 className="fs-6" style={{ fontWeight: "bold" }}>
                            FEATURES
                        </h6>
                        <ul className="footer-links">
                            <li>
                                <a
                                    href="/#avatars"
                                    style={{
                                        color: "#000000",
                                        textDecoration: "none",
                                    }}
                                >
                                    Avatars
                                </a>
                            </li>
                            <li>
                                <a
                                    href="/#language"
                                    style={{
                                        color: "#000000",
                                        textDecoration: "none",
                                    }}
                                >
                                    Voice and Languages
                                </a>
                            </li>
                            <li>
                                <a
                                    href="/#template"
                                    style={{
                                        color: "#000000",
                                        textDecoration: "none",
                                    }}
                                >
                                    Templates
                                </a>
                            </li>
                            <li>
                                <a
                                    href="/#image"
                                    style={{
                                        color: "#000000",
                                        textDecoration: "none",
                                    }}
                                >
                                    Image and Video Library
                                </a>
                            </li>
                        </ul>
                    </Box>
                </Grid>
                <Grid item xs={6} md={2}>
                    <Box sx={{ textAlign: { xs: "center", md: "left" } }}>
                        <h6 className="fs-6" style={{ fontWeight: "bold" }}>
                            USE CASES
                        </h6>
                        <ul className="footer-links">
                            <li>
                                <a
                                    href="/use-cases#hyper-personalization"
                                    style={{
                                        color: "#000000",
                                        textDecoration: "none",
                                    }}
                                >
                                    Hyper Personalization
                                </a>
                            </li>
                            <li>
                                <a
                                    href="/use-cases#product-explainer"
                                    style={{
                                        color: "#000000",
                                        textDecoration: "none",
                                    }}
                                >
                                    Product Explainer Videos
                                </a>
                            </li>
                            <li>
                                <a
                                    href="/use-cases#edutech"
                                    style={{
                                        color: "#000000",
                                        textDecoration: "none",
                                    }}
                                >
                                    Edutech
                                </a>
                            </li>
                            <li>
                                <a
                                    href="/use-cases#digital-news-anchor"
                                    style={{
                                        color: "#000000",
                                        textDecoration: "none",
                                    }}
                                >
                                    Digital News Anchor
                                </a>
                            </li>
                        </ul>
                    </Box>
                </Grid>
                <Grid item xs={6} md={2}>
                    <Box sx={{ textAlign: { xs: "center", md: "left" } }}>
                        <h6 className="fs-6" style={{ fontWeight: "bold" }}>
                            LEGAL
                        </h6>
                        <ul className="footer-links">
                            <li>
                                <a
                                    href="/faq"
                                    style={{
                                        color: "#000000",
                                        textDecoration: "none",
                                    }}
                                >
                                    FAQs
                                </a>
                            </li>
                            <li>
                                <a
                                    href="/terms"
                                    style={{
                                        color: "#000000",
                                        textDecoration: "none",
                                    }}
                                >
                                    Terms & Conditions
                                </a>
                            </li>
                            <li>
                                <a
                                    href="/terms#privacy"
                                    style={{
                                        color: "#000000",
                                        textDecoration: "none",
                                    }}
                                >
                                    Privacy Policy
                                </a>
                            </li>
                        </ul>
                    </Box>
                </Grid>
            </Grid>
        </Box>
    );
};

export default FooterContainer;

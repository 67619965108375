import React from "react";
import {

    Paper
} from "@mui/material";

const Avatar = ({ img }) => {
    return (
        <Paper elevation={1} sx={{
            // width: "em"
            m: 1,
            borderRadius: "20px",
            boxShadow: "0px 3px 3px 0px rgba(0,0,0,0.10)"
        }}>
            <img style={{
                borderRadius: "20px"
            }}
                src={img} width={100} />
        </Paper>
    )
}
export default Avatar;
import React from "react";
import {
    // Box,
    Card,
    CardContent,
    CardMedia,
    Typography,
    // Button,
} from "@mui/material";
import { CardHeader } from "reactstrap";

// News card for landing page
const NewsCard = ({
    cardImage,
    title,
    description,
    onClickHandler
}) => {
    return (
        <Card
            sx={{
                p: 2,
                m: 3,
                borderRadius: "38px",
                border: "1px solid rgba(61, 65, 81, 0.20)",
                background: "white",
                boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                textAlign: "center",
                width: {xs :"18rem", md: "20rem"},
                height: "20rem"
            }}
        >
            <CardHeader>
                <CardMedia
                    component="img"
                    src={cardImage}
                    alt="Image"
                    sx={{
                        // height: "auto",
                        width: {xs:"10rem",md: "15rem"},
                        maxWidth: "100%",
                        display: "block",
                    }}
                />
            </CardHeader>
            <CardContent>
                <Typography
                    variant="h6"
                    component="div"
                    sx={{
                        color: "black",
                        fontSize: "15px",
                        fontWeight: 700,
                        textAlign: 'left',
                        cursor:"pointer"
                    }}
                    onClick={onClickHandler}
                >
                    {title}
                </Typography>
                <Typography
                    variant="body2"
                    sx={{
                        my: 2,
                        color: "gray",
                        fontSize: { xs: "11px", md: "12px" },
                        fontWeight: 500,
                        textAlign: 'left'

                    }}
                >
                    {description}
                </Typography>
            </CardContent>
        </Card>
    );
};

export default NewsCard;

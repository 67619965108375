import { React } from "react";
import {
    Box,
    Card,
    CardMedia,
    Grid,
    Typography,
    Button,
    Paper,
} from "@mui/material";
import { avatars } from "../data/avatars";
import { langs } from "../data/langs";
import Avatar from "../components/Avatar";
import Langs from "../components/Langs";
import { photos } from "../data/photos";
import { videos } from "../data/videos";
// import PlayCircleFilledOutlinedIcon from '@mui/icons-material/PlayCircleFilledOutlined';
import deepfakeImage from "../assets/images/deepfake-img.svg";
import "../App.css";

const Features = () => {
    return (
        <div style={{ backgroundColor: "#F6FAFD" }}>
            <div className="featuresContainer">
            {/* Section 1 */}
            <Box
                display="flex"
                alignItems="center"
                justifyContent="center"
                minHeight="100vh"
                sx={{
                    bgcolor: "#F6FAFD",
                    p: { md: 5 },
                    m: 3,
                }}
            >
                <Grid container spacing={2}>
                    <Grid item xs={12} md={6}>
                        <Box
                            display="flex"
                            flexDirection="column"
                            justifyContent="center"
                            alignItems={{ xs: "center", md: "flex-start" }}
                            height="100%"
                        >
                            <Typography
                                variant="h4"
                                component="h2"
                                sx={{
                                    color: "#007dfe",
                                    fontSize: {
                                        xs: "2rem",
                                        md: "3.5rem",
                                    },
                                    fontWeight: 700,
                                    textAlign: { xs: "left", md: "left" },
                                    marginTop: { xs: "70px", md: "40px"}
                                }}
                            >
                                <span style={{color:"black"}}>THE</span> ARTISTE <span style={{color:"#FF8A00"}}>AI</span>
                            </Typography>
                            <Typography
                                mt={1}
                                variant="body1"
                                sx={{
                                    fontFamily: "Inter",
                                    fontWeight: 400,
                                    lineHeight: "1.5",
                                    fontSize: {
                                        xs: "0.75rem",
                                        md: "1.3rem",
                                    },
                                    color: "rgba(61, 65, 81, 0.61)",
                                    textAlign: { xs: "center", md: "left" },
                                }}
                            >
                                Kroop AI is an ethical synthetic data solution platform. <br/>
                                Our sophisticated audio-visual deep learning based <br/>
                                platform "The Artiste" 
                                generates high quality <br/>
                                videos 
                                with digital Avatars with just text as input.
                            </Typography>
                            <Button
                                variant="contained"
                                sx={{
                                    boxShadow:
                                        "4px 7px 20px 0 rgba(0,0,0,0.27)",
                                    mt: 2,
                                    fontWeight: 300,
                                    width: "20em",
                                    height: "3.5em",
                                    borderRadius: "10px",
                                    backgroundColor: "#0267FF",
                                    border: "#0267FF",
                                    textTransform: "capitalize",
                                    marginTop:"25px"
                                }}
                                href="https://studio.theartiste.ai/app/t2v"
                            >
                                {/* Edit video with Artiste */}
                                Create AI Video
                            </Button>
                            <Grid container style={{marginTop:"100px",width:"85vw"}}>
                               <Grid item sm={12} md={7}>
                                  <img src={deepfakeImage} style={{objectFit:"contain",width:"400px"}}/>
                               </Grid>
                               <Grid item md={0}/>
                               <Grid item sm={12} md={5} sx={{ marginTop: { xs: "20px", md: "0px"} }}>
                                    <Typography
                                        variant="h4"
                                        component="h2"
                                        sx={{
                                            color: "black",
                                            fontSize: {
                                                xs: "2rem",
                                                md: "3.5rem",
                                            },
                                            fontWeight: 700,
                                            textAlign: { xs: "left", md: "left" },
                                            marginTop: { xs: "10px", md: "0px"}
                                        }}
                                    >
                                        Deepfake Detector
                                    </Typography>
                                    <Typography 
                                        mt={1}
                                        variant="body1"
                                        sx={{
                                            fontFamily: "Inter",
                                            fontWeight: 400,
                                            lineHeight: "1.5",
                                            fontSize: {
                                                xs: "0.75rem",
                                                md: "1.3rem",
                                            },
                                            color: "rgba(61, 65, 81, 0.61)",
                                            textAlign: { xs: "center", md: "left" },
                                        }}
                                    >
                                        The deepfake detector from Kroop AI is trained on unique
                                        in-house-generated, high-quality synthetic data.
                                        This provides the multimodal detector with a large and diverse
                                        set of identities for accurate deepfakes detection.
                                    </Typography>
                                    <Box>
                                        <Button variant="contained" 
                                            sx={{
                                                boxShadow:
                                                    "4px 7px 20px 0 rgba(0,0,0,0.27)",
                                                mt: 1,
                                                fontWeight: 300,
                                                width: "20em",
                                                height: "3.5em",
                                                borderRadius: "10px",
                                                backgroundColor: "#0267FF",
                                                border: "#0267FF",
                                                textTransform: "capitalize",
                                            }} 
                                            onClick={() => window.location.href="https://deepfake.kroop.ai/"}>
                                            Check out Deepfake Detector
                                        </Button>
                                    </Box>
                               </Grid>
                            </Grid>
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Box
                            display="flex"
                            justifyContent="center"
                            height="100%"
                            width="100%"
                            sx={{
                                marginTop: { xs: 1,md:4 },
                                objectFit: "contain"
                            }}
                        >
                            <img
                                src={require("../assets/images/svg_images/macbook.png")}
                                alt="svg_image"
                                style={{ width: "60%", height: "250px" }}
                            />
                        </Box>
                    </Grid>
                </Grid>
            </Box>
            <Box
                sx={{
                    bgcolor: "#F6FAFD",
                    minHeight: "100vh",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    flexDirection: "column",
                    padding: "2rem",
                }}
            >
                <Typography
                    variant="h4"
                    sx={{
                        color: "#0A415F",
                        // mt: 5,
                        textAlign: "center",
                        fontSize: {
                            xs: "1rem",
                            md: "3rem",
                        },
                        fontWeight: 700,
                    }}
                >
                    Features powered by{" "}
                    <b style={{ color: "#007dfe" }}> THE ARTISTE AI </b>
                </Typography>

                {/* AI Avatars Features card */}
                <div id="avatars"></div>
                <Paper
                    
                    elevation={3}
                    sx={{
                        mt: 5,
                        boxShadow: "0px 3px 3px 0px rgba(0,0,0,0.10)",
                    }}
                >
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={5}>
                            <Box
                                sx={{
                                    m: { xs: 2 },
                                    mt: { md: 10 },
                                    ml: { md: 15 },
                                }}
                            >
                                <Typography
                                    variant="h4"
                                    sx={{
                                        fontWeight: 600,
                                        color: "#0A415F",
                                    }}
                                >
                                    Create professional videos with 50+ AI
                                    avatars
                                </Typography>
                                <Typography sx={{ mt: 5, fontSize: "15px" }}>
                                    The platform offers a diverse selection of
                                    AI avatars, each with unique appearances,
                                    personalities and expressions. This service
                                    is an excellent resource for content
                                    creators, educators and marketers seeking
                                    innovative ways to produce compelling and
                                    engaging video content without the need for
                                    live actors.
                                </Typography>
                            </Box>
                        </Grid>
                        <Grid item xs={12} md={7}>
                            <Box
                                sx={{
                                    display: "flex",
                                    flexWrap: "wrap",
                                    height: "480px",
                                    overflowY: "scroll",
                                    mt: 5,
                                    mb: 5,
                                    ml: 5,
                                }}
                            >
                                {avatars.map((e) => (
                                    <Avatar img={e.thumbnail_url} />
                                ))}
                            </Box>
                        </Grid>
                    </Grid>
                </Paper>

                {/* Languages Features card */}
                <div id="language"></div>
                <Paper
                    
                    elevation={3}
                    sx={{
                        mt: 10,
                        boxShadow: "0px 3px 3px 0px rgba(0,0,0,0.10)",
                    }}
                >
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={5}>
                            <Box
                                sx={{
                                    m: { xs: 2 },
                                    mt: { md: 10 },
                                    ml: { md: 15 },
                                }}
                            >
                                <Typography
                                    variant="h4"
                                    sx={{
                                        fontWeight: 600,
                                        color: "#3D4151",
                                    }}
                                >
                                    25+ language support
                                </Typography>
                                <Typography sx={{ mt: 5, fontSize: "15px" }}>
                                    The platform offers comprehensive
                                    multilingual capabilities. The users can
                                    produce high-quality videos in more than 25
                                    different languages, accommodating a global
                                    audience and expanding the reach of their
                                    content. <br />
                                    This level of language support is valuable
                                    for businesses, content creators, educators,
                                    and anyone looking to communicate
                                    effectively with a diverse and international
                                    audience.
                                </Typography>
                            </Box>
                        </Grid>
                        <Grid item xs={12} md={7}>
                            <Box
                                sx={{
                                    display: "flex",
                                    flexWrap: "wrap",
                                    height: "480px",
                                    overflowY: "scroll",
                                    mt: 5,
                                    mb: 5,
                                    ml: 5,
                                    // mr: 5
                                }}
                            >
                                {langs.map((e, i) => (
                                    <Langs
                                        img={require(`../assets/langs/${e.imgSrc}`)}
                                        text={e.name}
                                        isFlag={true}
                                    />
                                ))}
                            </Box>
                        </Grid>
                    </Grid>
                </Paper>
            </Box>

            <Box
            id="image"
                sx={{
                    // bgcolor: "#FFFFFF",
                    minHeight: "70vh",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    flexDirection: "column",
                    padding: "2rem",
                    // mt: {xs:"-70px", md:"-50px"}
                }}
            >
                <Typography
                    variant="h4"
                    sx={{
                        color: "#3D4151",
                        fontSize: { xs: "1.2rem", md: "2.5rem" },
                        textAlign: "center",
                        fontWeight: 700,
                    }}
                >
                    Video & Image Library
                </Typography>
                <Typography
                    sx={{
                        color: "rgba(0, 0, 0, 0.61)",
                        textAlign: "center",
                        fontSize: { xs: "0.7rem", md: "1.5rem" },
                        mt: 1,
                    }}
                >
                    Create professional AI videos from using our preloaded video
                    and images library.
                </Typography>
                <Grid container spacing={2}>
                    <Grid item xs={12} md={6}>
                        <Box
                            display="flex"
                            flexDirection="row"
                            justifyContent="center"
                            alignItems={{ xs: "center", md: "flex-start" }}
                        >
                            {photos.slice(0, 3).map((e) => (
                                <Card
                                    sx={{
                                        width: { xs: "100px", md: "300px" },
                                        height: { xs: "60px", md: "130px" },
                                        margin: "10px",
                                    }}
                                >
                                    <CardMedia
                                        component="img"
                                        height="100%"
                                        image={e.src.tiny}
                                        alt="Paella dish"
                                    />
                                </Card>
                            ))}
                        </Box>
                        <Box
                            display="flex"
                            flexDirection="row"
                            justifyContent="center"
                            alignItems={{ xs: "center", md: "flex-start" }}
                        >
                            {photos.slice(3, 6).map((e) => (
                                <Card
                                    sx={{
                                        width: { xs: "100px", md: "300px" },
                                        height: { xs: "60px", md: "130px" },
                                        margin: "10px",
                                    }}
                                >
                                    <CardMedia
                                        component="img"
                                        height="100%"
                                        image={e.src.tiny}
                                        alt="Paella dish"
                                    />
                                </Card>
                            ))}
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Box
                            display="flex"
                            flexDirection="row"
                            justifyContent="center"
                            alignItems={{ xs: "center", md: "flex-start" }}
                        >
                            {videos.slice(0, 3).map((video) => (
                                <Card
                                    sx={{
                                        width: { xs: "100px", md: "300px" },
                                        height: { xs: "60px", md: "130px" },
                                        margin: "10px",
                                    }}
                                >
                                    <CardMedia
                                        component="img"
                                        height="100%"
                                        image={video.image}
                                        alt="Paella dish"
                                    />
                                </Card>
                            ))}
                        </Box>
                        <Box
                            display="flex"
                            flexDirection="row"
                            justifyContent="center"
                            alignItems={{ xs: "center", md: "flex-start" }}
                        >
                            {videos.slice(3, 6).map((video) => (
                                <Card
                                    sx={{
                                        width: { xs: "100px", md: "300px" },
                                        height: { xs: "60px", md: "130px" },
                                        margin: "10px",
                                    }}
                                >
                                    <CardMedia
                                        component="img"
                                        height="100%"
                                        image={video.image}
                                        alt="Paella dish"
                                    />
                                </Card>
                            ))}
                        </Box>
                    </Grid>
                </Grid>
            </Box>
            </div>
        </div>
    );
};
export default Features;

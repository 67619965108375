import React from "react";
import { Card, CardContent, Typography, Box } from "@mui/material";
import FormatQuoteIcon from '@mui/icons-material/FormatQuote';
const Card2 = ({
    cardImage,
    title,
    description,
    buttonText,
    cardColor,
    onClickHandler,
}) => {
    return (
        <Card
            sx={{
                p: 2,
                m: 2,
                borderRadius: "15.93px",
                background: "#FFF",
                boxShadow:
                    "9.960000038146973px 3.9800000190734863px 39.86000061035156px 0px rgba(0, 0, 0, 0.16)",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                // position: "relative",
                width:{xs: "18rem", md:"22em"},
                height: "28em",
                // justifyContent: 'space-around'
            }}
        >
                <FormatQuoteIcon sx={{
                    height:"2.5em",
                    transform: "rotate(180deg) translateX(7px) translateY(-8px) scale(2)",
                    color: "#4FB6EC",
                    textAlign: "left"
                }}/>

            <CardContent>
                <Typography
                    variant="body1"
                    sx={{
                        textAlign: "center",
                        fontSize: "11px",
                        fontWeight: 500,
                        height:"15em",
                        color: "rgba(61, 65, 81, 0.68)",
                    }}
                >
                    {description}
                </Typography>
            </CardContent>
            <Box
                sx={{
                    display: "flex",
                    alignItems: "center",
                    marginTop: "8px",
                    textAlign: "center",
                    flexDirection: "column"
                }}
            >
                <Box
                    sx={{
                        width: "120px",
                        height: "120px",
                        borderRadius: "50%",
                        marginRight: "8px",
                        display: "flex"
                    }}
                >
                    <img
                        src={cardImage}
                        alt="Card"
                        style={{
                            width: "110px",
                            height: "110px",
                            objectFit: "contain",
                            borderRadius: "50%",
                            margin: "auto",
                            // padding: "10px"
                        }}
                    />
                </Box>
                <Typography
                    variant="body2"
                    sx={{ fontWeight: 600, color: "#3D4151" }}
                >
                    {title}
                </Typography>
            </Box>
        </Card>
    );
};

export default Card2;

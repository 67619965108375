export const  langs = [
    {
      imgSrc: "canada.avif",
      name: "Eng-Canada",
    },
    {
      imgSrc: "aus.png",
      name: "Eng-AU",
    },{
      imgSrc: "uk.png",
      name: "Eng-UK",
    },
    
    {
      imgSrc: "usa.webp",
      name: "Eng-US",
    },
    {
      imgSrc: "french.webp",
      name: "French",
    },{
      imgSrc: "spain-flag-icon.png",
      name: "Spanish",
    }, {
      imgSrc: "italian.png",
      name: "Italian",
    },
    {
      imgSrc: "korean.png",
      name: "Korean",
    },
    {
      imgSrc: "german.png",
      name: "German",
    },{
      imgSrc: "arabic.png",
      name: "Arabic",
    },
    {
      imgSrc: "hindi.png",
      name: "Hindi",
    },
    {
      imgSrc: "marathi.png",
      name: "Marathi",
    },
    {
      imgSrc: "mallu.png",
      name: "Malayalam",
    },
    {
      imgSrc: "urdu.svg",
      name: "Urdu",
    },
    {
      imgSrc: "tamil.png",
      name: "Tamil",
    },
    {
      imgSrc: "guj.png",
      name: "Gujarati",
    },
    {
      imgSrc: "telugu.png",
      name: "Telugu",
    }
  ];

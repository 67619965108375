import React, { useState, useRef } from "react";
import {
    AppBar,
    Toolbar,
    Typography,
    Menu,
    Button,
    MenuItem,
} from "@mui/material";
import { BrowserRouter as Router, Route, Link } from "react-router-dom";

import useOutsideClickHandler from "./useOutsideClickHandler";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import PeopleAltOutlinedIcon from "@mui/icons-material/PeopleAltOutlined";
import TranslateOutlinedIcon from "@mui/icons-material/TranslateOutlined";
import FilterOutlinedIcon from "@mui/icons-material/FilterOutlined";
import GridViewOutlinedIcon from "@mui/icons-material/GridViewOutlined";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";

export const FeaturesMenu = () => {
    const [anchorEl, setAnchorEl] = useState(null);
    const menuRef = useRef(null);

    const handleMenuOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    useOutsideClickHandler(menuRef, handleMenuClose);

    return (
        <Button
            className="navbar-dropdown-text"
            key={"Features"}
            ref={menuRef}
            variant="text"
            style={{ color: "#3D4151" }}
            onClick={handleMenuOpen}
            // onMouseEnter={handleMenuOpen}
            onMouseLeave={handleMenuClose}
        >
            Features
            <ExpandMoreIcon />
            <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleMenuClose}
            >
                <List>
                    <a
                        href="/#avatars"
                        style={{ textDecoration: "none", color: "inherit" }}
                    >
                        <ListItem disablePadding>
                            <ListItemButton>
                                <ListItemIcon>
                                    <PeopleAltOutlinedIcon />
                                </ListItemIcon>
                                <ListItemText
                                    primary="Avatars"
                                    secondary="50+ avatars"
                                />
                            </ListItemButton>
                        </ListItem>
                    </a>

                    <a
                        href="/#language"
                        style={{ textDecoration: "none", color: "inherit" }}
                    >
                        <ListItem disablePadding>
                            <ListItemButton>
                                <ListItemIcon>
                                    <TranslateOutlinedIcon />
                                </ListItemIcon>
                                <ListItemText
                                    primary="Languages"
                                    secondary="25+ Languages"
                                />
                            </ListItemButton>
                        </ListItem>
                    </a>

                    <a
                        href="/#image"
                        style={{ textDecoration: "none", color: "inherit" }}
                    >
                        <ListItem disablePadding>
                            <ListItemButton>
                                <ListItemIcon>
                                    <FilterOutlinedIcon />
                                </ListItemIcon>
                                <ListItemText
                                    primary="Image & Video Library"
                                    secondary="Stock Images and Videos"
                                />
                            </ListItemButton>
                        </ListItem>
                    </a>

                    <a
                        href="/#template"
                        style={{ textDecoration: "none", color: "inherit" }}
                    >
                        <ListItem disablePadding>
                            <ListItemButton>
                                <ListItemIcon>
                                    <GridViewOutlinedIcon />
                                </ListItemIcon>
                                <ListItemText
                                    primary="Templates"
                                    secondary="Coming Soon"
                                />
                            </ListItemButton>
                        </ListItem>
                    </a>
                </List>
            </Menu>
        </Button>
    );
};

export const UseCasesMenu = () => {
    const [anchorEl, setAnchorEl] = useState(null);
    const menuRef = useRef(null);

    const handleMenuOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    useOutsideClickHandler(menuRef, handleMenuClose);

    return (
        <Button
            className="navbar-dropdown-text"
            key={"Use Cases"}
            ref={menuRef}
            variant="text"
            style={{ color: "#3D4151" }}
            onClick={handleMenuOpen}
            // onMouseEnter={handleMenuOpen}
            onMouseLeave={handleMenuClose}
        >
            Use Cases
            <ExpandMoreIcon />
            <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleMenuClose}
            >
                <List>
                    <ListItem disablePadding>
                        <ListItemButton
                            component={Link}
                            to="/use-cases#hyper-personalization"
                        >
                            <ListItemIcon>
                                <PeopleAltOutlinedIcon />
                            </ListItemIcon>
                            <ListItemText
                                primary="Hyper Personalization"
                                secondary="Personalize your content"
                            />
                        </ListItemButton>
                    </ListItem>
                    <ListItem disablePadding>
                        <ListItemButton
                            component={Link}
                            to="/use-cases#product-explainer"
                        >
                            <ListItemIcon>
                                <TranslateOutlinedIcon />
                            </ListItemIcon>
                            <ListItemText
                                primary="Product Explainer Videos"
                                secondary="Generate Tutorial videos"
                            />
                        </ListItemButton>
                    </ListItem>
                    <ListItem disablePadding>
                        <ListItemButton
                            component={Link}
                            to="/use-cases#edutech"
                        >
                            <ListItemIcon>
                                <FilterOutlinedIcon />
                            </ListItemIcon>
                            <ListItemText
                                primary="Edutech"
                                secondary="Enhance Learning experience"
                            />
                        </ListItemButton>
                    </ListItem>
                    <ListItem disablePadding>
                        <ListItemButton
                            component={Link}
                            to="/use-cases#digital-news-anchor"
                        >
                            <ListItemIcon>
                                <GridViewOutlinedIcon />
                            </ListItemIcon>
                            <ListItemText
                                primary="Digital News Anchor"
                                secondary="Your digital news anchor"
                            />
                        </ListItemButton>
                    </ListItem>
                </List>
            </Menu>
        </Button>
    );
};

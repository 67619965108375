import React from "react";
import { Container, Box, Typography, Grid, Card } from "@mui/material";
import { blogs } from "../data/blogs";

const PostsAndSocial = () => (
    <div style={{ backgroundColor: "aliceblue" }}>
        <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            sx={{ bgcolor: "#007DFE", p: { xs: 3, md: 1 }, mt: 10 }}
            flexDirection="column"
        >
            <Typography
                variant="h2"
                component="h2"
                sx={{
                    fontFamily: "Inter",
                    fontWeight: 1000,
                    color: "#F6FAFD",
                    fontSize: { xs: "2rem", md: "4rem" },
                    paddingBottom: "10px",
                }}
            >
                SOCIAL FEED
            </Typography>
            <Typography
                sx={{
                    fontFamily: "Inter",
                    fontWeight: 300,
                    color: "#F6FAFD",
                    fontSize: "18px",
                }}
            >
                Kroop AI's social updates are here from LinkedIn and Twitter!
            </Typography>
        </Box>

        <Container>
            <Box
                display="flex"
                flexDirection="row"
                justifyContent="center"
                alignItems={{ xs: "center", md: "flex-start" }}
                padding={2}
            >
                <Grid container spacing={2} justifyContent="center">
                    {blogs.map((blog) => (
                        <Grid item key={blog.id} xs={12} md={6}>
                            <Card
                            sx={{
                                height:{xs:"400px", md:"600px"},
                                width:{xs:"300px", md:"550px"}
                            }}>
                                <iframe
                                    key={blog.id}
                                    src={blog.url}
                                    height="100%"
                                    width="100%"
                                    frameborder="0"
                                    allowfullscreen=""
                                    title="Embedded post"
                                ></iframe>
                            </Card>
                        </Grid>
                    ))}
                </Grid>
            </Box>
        </Container>
    </div>
);

export default function Blogs() {
    return (
        <>
            <PostsAndSocial />
        </>
    );
}

import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
// import Card from '@mui/material/Card';
import { Typography, Container } from "@mui/material";
import Card1 from "../components/CardComponent/Card1";
import Card2 from "../components/CardComponent/Card2";
import svg1 from "../assets/images/svg_images/1.png";
import bg_image from "../assets/images/background_images/bg_image.png";
import cardImage1 from "../assets/images/icons/hyper.png";
import cardImage2 from "../assets/images/card_images/video.png";
import cardImage3 from "../assets/images/icons/edu.png";
import cardImage4 from "../assets/images/card_images/digital_news_anchor.png";
import iskonDwarkaImage from "../assets/images/card_images/iskcon.png";
import deepfakeImage from "../assets/images/deepfake-img.svg";
import NewsCard from "../components/CardComponent/NewsCard";
import Features from "./Features"
import LandingPageArtiste from "../components/LandingPageArtiste";

const LandingPageView = () => {

  const location = useLocation();
  useEffect(() => {
    const targetId = location.hash.slice(1); // Remove the '#' character
    const targetDiv = document.getElementById(targetId);

    if (targetDiv) {
      // Scroll to the target div using scrollIntoView
      targetDiv.scrollIntoView({ behavior: 'smooth' });
    }
  }, [location])
  
  const featuresCardData = [
    {
      title: "Hyper Personalization",
      description:
        "Kroop AI’s tech uses its data analytics and artificial intelligence to deliver highly customized and relevant interactions, services, or experiences to meet the individual needs and preferences of each customer or user",
      cardImage: cardImage1,
      buttonText: "Read More",
      section:"/use-cases#hyper-personalization",
      marginTop: "-6rem",
    },
    {
      title: "Product Explainer Videos",
      description:
        "Videos provide a visually engaging experience by combining images, animations, text, and audio.They can capture attention and evoke emotions more effectively than text alone.Visual elements help viewers understand complex concepts, features, and benefits of a product",
      cardImage: cardImage2,
      buttonText: "Read More",
      cardColor: "#FFFFFF",
      section:"/use-cases#product-explainer",
      marginTop: "3rem",
    },
    {
      title: "Edutech",
      description:
        "AI-generated videos are revolutionizing the field of education technology (Edutech) by enhancing the learning experience for students and teachers alike",
      cardImage: cardImage3,
      buttonText: "Read More",
      section: "/use-cases#edutech",
      marginTop: "-6rem",
    },
    {
      title: "Digital News Anchor",
      description:
        "A digital news anchor using AI refers to the use of artificial intelligence technology to generate and present news content in a digital format. This concept involves leveraging AI algorithms to analyze vast amounts of data, generate news articles, and even simulate human-like voices and appearances for news delivery",
      cardImage: cardImage4,
      buttonText: "Read More",
      cardColor: "#FFFFFF",
      section: "/use-cases#digital-news-anchor",
      marginTop: "3rem",
    },
  ];

  const customersCardData = [
    {
      title: "HDFC Life",
      description:
        "As part of taking initiatives in the latest technologies, HDFC Life entered the generative AI space with the help of one of the leading companies in the domain named Kroop AI. Kroop AI’s tech has enabled HDFC Life in effective communication with various stakeholders within the organization. Kudos to the entire Kroop AI team for delivering quality outputs amid tight deadlines. Looking forward for many more projects in the future!! ",
      cardImage: require("../assets/langs/HDFC_Life_Logo.jpeg"),
    },
    {
      title: "Ian Anderson (VP, AI Glance)",
      description:
        "We had the pleasure of collaborating with Kroop.ai on a synthetic media project focused on virtual avatar development. I was very impressed with the depth of Computer Vision expertise within the company. Kroop.ai enabled us to quickly test concepts and iterate fast whilst shielding us from implementation details allowing us to focus on the applications of the work. I cannot praise Kroop.ai enough",
      cardImage: require("../assets/ian.jpeg"),
    },
    {
      title: "Rahul Prasad CTO Booble AI",
      description:
        "We have engaged in a professional consultation with Kroop.ai on multiple computer vision initiatives. Their expertise has proven invaluable in assisting us with optimizing our server expenses through the implementation of on-device bobble-avatar creation.",
      cardImage: require("../assets/images/card_images/Rahul-Prasad.jpeg"),
    },
  ];

  const newsCardData = [
    {
      title: "Riding The Digital Wave",
      description:
        "These are young energetic entrepreneurs who want to make a mark. Fresh ideas, dreams and hopes bundled together – some are creating an identity some breaking new ground.",
      cardImage: require("../assets/outlook.jpeg"),
      onClickHandler:()=>window.location.href="https://www.outlookindia.com/website/story/business-news-dreams-riding-the-digital-wave/393564"
    },
    {
      title: "Don’t chase just big names",
      description:
        "In this episode of Founder Stories, we introduce you to Dr. Jyoti Joshi, the co-founder of Kroop AI, who is trying to spread awareness about the severity of Deep Fakes.",
      cardImage: require("../assets/vc.jpeg"),
      onClickHandler:()=>window.location.href="https://thevcstories.com/podcast/dont-chase-just-big-names-the-mentor-should-be-right-fit/"
    },
    {
      title: "30 Startups to Watch",
      description:
        "In our 16th edition, we have looked at startups that are addressing major pain points across fintech, SaaS, edtech and HRtech to help businesses.",
      cardImage: require("../assets/inc42.jpeg"),
      onClickHandler:()=>window.location.href="https://inc42.com/features/30-startups-to-watch-the-startups-that-caught-our-eye-in-may-2021/"
    },
    {
      title: "Voice of Metaverse",
      description:
        "How our co-founder Dr. Jyoti Joshi started ground breaking Kroop AI in four years from graduating from University of Canberra",
      cardImage: require("../assets/canberra.jpeg"),
      onClickHandler:()=>window.location.href="https://www.canberra.edu.au/uncover/news-archive/2022/june/the-voice-of-the-metaverse-uc-alumna-launches-ambitious-ai-start-up"
    },
    {
      title: "Deeptech Startup (Yourstory)",
      description:
        "Learn how a synthetic video of Barack Obama led to the creation of Kroop AI a deeptech startup in the audio-visual content generation space",
      cardImage: require("../assets/ys.jpeg"),
      onClickHandler:()=>window.location.href="https://hr.economictimes.indiatimes.com/news/hrtech/india-inc-get-ready-for-deepfake-hr-managers/88381876"
    },
    {
      title: "DeepFake for HR Managers",
      description:
        "“Synthetic data generation can save time and effort via deepfake HR managers” ",
      cardImage: require("../assets/et.jpeg"),
      onClickHandler:()=>window.location.href="https://hr.economictimes.indiatimes.com/news/hrtech/india-inc-get-ready-for-deepfake-hr-managers/88381876"
    },
  ];
  return (
    <>
      <Features />
      <div style={{ backgroundColor: "#F6FAFD" }}>
        {/* Section 1 */}
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          minHeight="100vh"
          sx={{
            bgcolor: "#F6FAFD",
            p: { xs: 3, md: 5 },
            ml: { md: 4 },
            mr: { md: 4 },
            // m: { md: 4 },
            // mt: { xs: 6, md: 7 },
          }}
        >
          <Grid container spacing={2}>
            <Grid item xs={12} md={7}>
              <Box
                display="flex"
                flexDirection="column"
                justifyContent="center"
                alignItems={{ xs: "center", md: "flex-start" }}
                height="100%"
              >
                <Typography
                  variant="h4"
                  component="h2"
                  sx={{
                    letterSpacing: "0.05em",
                    fontFamily: "Inter",
                    fontWeight: 700,
                    lineHeight: "1.2",
                    fontSize: {
                      xs: "1.45rem",
                      md: "2.7rem",
                    },
                    color: "#3D4151",
                    textAlign: { xs: "left", md: "left" },
                  }}
                >
                  Harness synthetic media generation and detection with endless
                  possibilities.
                </Typography>
                <Typography
                  mt={1}
                  variant="body1"
                  sx={{
                    fontFamily: "Inter",
                    fontWeight: 400,
                    lineHeight: "1.5",
                    fontSize: {
                      xs: "0.75rem",
                      md: "1.2rem",
                    },
                    color: "rgba(61, 65, 81, 0.61)",
                    textAlign: { xs: "left", md: "left" },
                  }}
                >
                  Kroop AI’s tech uses its data analytics and artificial
                  <br /> intelligence to deliver highly customized and relevant
                  <br /> interactions, services, or experiences to meet the
                  <br /> individual needs.
                </Typography>
                <Button
                  variant="contained"
                  sx={{
                    boxShadow: "4px 7px 20px 0 rgba(0,0,0,0.27)",
                    mt: 5,
                    width: "13em",
                    height: "3.5em",
                    borderRadius: "25px",
                    fontSize: "15px",
                    backgroundColor: "#0267FF",
                    border: "#0267FF",
                    textTransform: "capitalize",
                  }}
                  href="https://studio.theartiste.ai/app/t2v"
                >
                  Get Started
                </Button>
              </Box>
            </Grid>
            <Grid item xs={12} md={5}>
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                height="100%"
              >
                <img
                  src={svg1}
                  alt="svg_image"
                  style={{ width: "100%", height: "auto" }}
                />
              </Box>
            </Grid>
          </Grid>
        </Box>

        {/* Section 2 */}
        <Box
          sx={{
            marginTop: { xs: -15 },
            backgroundImage: `url(${bg_image})`,
            backgroundSize: "cover",
            minHeight: "100vh",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
            padding: "2rem",
            backgroundPosition: "center top",
            "@media (max-width: 960px)": {
              backgroundPosition: "center",
            },
          }}
        >
          <Typography
            variant="h4"
            component="h2"
            align="center"
            sx={{
              // letterSpacing: "0.02em",
              fontFamily: "Inter",
              fontWeight: 700,
              fontSize: { xs: "22px", md: "32px" },
              color: "#3D4151",
            }}
          >
            We Solve For You
          </Typography>

          <Typography
            variant="body1"
            align="center"
            sx={{
              // letterSpacing: "0.02em",
              fontFamily: "Inter",
              fontWeight: 400,
              fontSize: { xs: "11px", md: "16px" },
              color: "#5F6D7E",
            }}
            mt={2}
            mb={4}
          >
            Visual Content Generation platform to help you reduce your costs and
            maximize your reach without
            <br /> language and location barriers.
          </Typography>

          <Box
            sx={{
              display: "flex",
              flexDirection: { xs: "column", md: "row" },
              justifyContent: {
                xs: "center",
                md: "space-between",
              },
              alignItems: "center",
              marginTop: "2rem",
            }}
          >
            {featuresCardData.map((data) => (
              <>
                <Card1 {...data} />
              </>
            ))}
          </Box>
        </Box>
    
        {/* Section 4 */}
        <Box
          sx={{
            bgcolor: "#F6FAFD",
            minHeight: "100vh",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
            padding: "2rem",
          }}
        >
          <Typography
            variant="h4"
            component="h2"
            align="center"
            sx={{
              fontFamily: "Inter",
              fontWeight: 700,
              fontSize: { xs: "22px", md: "32px" },
              color: "#3D4151",
            }}
          >
            Hear it form our customers
          </Typography>

          <Typography
            variant="body1"
            align="center"
            sx={{
              // letterSpacing: "0.02em",
              fontFamily: "Inter",
              fontWeight: 400,
              fontSize: { xs: "11px", md: "16px" },
              color: "#5F6D7E",
            }}
            mt={2}
          >
            Visual Content Generation platform to help you reduce your costs and
            maximize your reach without
            <br /> language and location barriers.
          </Typography>

          <Box
            sx={{
              display: "flex",
              flexDirection: { xs: "column", md: "row" },
              justifyContent: {
                xs: "center",
                md: "space-around",
              },
              alignItems: "center",
              marginTop: "2rem",
            }}
          >
            {customersCardData.map((data) => (
              <>
                <Card2 {...data} />
              </>
            ))}
          </Box>
        </Box>

        {/* Section 5 */}
        <Box
          sx={{
            bgcolor: "#FFFFFF",
            backgroundSize: "cover",
            minHeight: "100vh",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
            padding: "2rem",
          }}
        >
          <Typography
            variant="h4"
            component="h2"
            align="center"
            sx={{
              fontFamily: "Inter",
              fontWeight: 700,
              fontSize: { xs: "22px", md: "32px" },
              color: "#3D4151",
            }}
          >
            Kroop AI in News
          </Typography>
          <Box
            sx={{
              display: "flex",
              flexDirection: { xs: "column", md: "row" },
              justifyContent: {
                xs: "center",
                md: "space-around",
              },
              alignItems: "center",
              marginTop: "2rem",
            }}
          >
            {/* Put cards here */}
            {newsCardData.slice(0, 3).map((data) => (
              <NewsCard {...data} />
            ))}
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: { xs: "column", md: "row" },
              justifyContent: {
                xs: "center",
                md: "space-around",
              },
              alignItems: "center",
            }}
          >
            {/* Put cards here */}
            {newsCardData.slice(3, 6).map((data) => (
              <NewsCard {...data} />
            ))}
          </Box>
        </Box>
      </div>
    </>
  );
};

export default LandingPageView;

import React from 'react';
import { Container, Typography, Button, Box } from '@mui/material';
import GetAppIcon from '@mui/icons-material/GetApp';

const IskonCompo = () => {
    return (
        <Container maxWidth="sm" sx={{ textAlign: 'center', marginTop: '5rem' }}>
            <Box
                sx={{
                    padding: '2rem',
                    boxShadow: 3,
                    borderRadius: 2,
                    backgroundColor: 'background.paper',
                }}
            >
                <Typography variant="h4" component="h1" gutterBottom>
                    Download Iskcon Dwarika App
                </Typography>
                <Typography variant="body1" gutterBottom>
                    Click the button below to download the latest version of our Android app.
                </Typography>
                <Button
                    variant="contained"
                    color="primary"
                    size="large"
                    startIcon={<GetAppIcon />}
                    href="https://a2v-synthesis-h6sv37.s3.ap-south-1.amazonaws.com/Android_latest.apk"
                    sx={{ marginTop: '1rem' }}
                >
                    Download Now
                </Button>
            </Box>
        </Container>
    );
};

export default IskonCompo;

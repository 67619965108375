import React from "react";
import { Box, Grid, Typography, CardMedia } from '@mui/material';

export const UseCaseLeft = ({ img, vid, title, subtitle, text }) => {
    return (<>
        <Box
            sx={{ bgcolor: "white", p: { xs: 3, md: 5 }, 
            borderStyle: 'solid', borderWidth: 1, borderRadius: "20px", borderColor: "rgb(0,0,0,0.25)", mb: 5 }}
        >
            <Grid container spacing={1} >
                <Grid item xs={12} md={5}>
                <Box
                        display="flex"
                        flexDirection="column"
                        justifyContent="center"
                        alignItems={{ xs: "center", md: "center" }}
                        height="100%"

                        sx={{
                            bgcolor: "#F5F7FE",
                            borderRadius: "38px",
                            justifyContent: "center",
                            width: "95%",
                            margin: "auto"
                        }}
                    >
                        <img
                            src={img}
                            alt="svg_image"
                            style={{ width: "70%", height: "auto", display: "block" }}
                        />
                        <Box sx={{
                            margin: "auto"
                        }}>
                            <Typography
                                variant="h4"
                                sx={{
                                    fontFamily: "Inter",
                                    fontWeight: 700,
                                    fontSize:{xs:"1.5rem"},
                                    color: "#3D4151",
                                    paddingBottom: "10px",
                                    textAlign: "center"
                                }}>
                                {title}
                            </Typography>
                            <Typography sx={{
                                fontFamily: "Inter",
                                fontWeight: 300,
                                color: "black",
                                fontSize: {xs: "0.7rem", md:"15px"},
                                textAlign: 'center'
                            }}>
                                {subtitle}
                            </Typography>
                        </Box>
                    </Box>
                </Grid>
                <Grid item xs={12} md={7}>
                    <Box>
                        <CardMedia
                            component='video'
                            src={vid}
                            controls
                            sx={{
                                borderRadius: "38px"
                            }}>
                        </CardMedia>
                        <Typography
                            sx={{
                                mt: 1,
                                p: 1,
                                fontSize: {xs: "0.8rem", md:"15px"},
                                fontWeight: 300,
                                color: "#3D4151"
                            }}
                        >
                            {text}
                        </Typography>
                    </Box>
                </Grid>
            </Grid>
        </Box>

    </>)
}

export const UseCaseRight = ({ img, vid, title, subtitle, text }) => {
    return (<>
        <Box
            sx={{ bgcolor: "white", p: { xs: 3, md: 5 }, borderStyle: 'solid', borderWidth: 1, borderRadius: "20px", borderColor: "rgb(0,0,0,0.25)", mb: 5 }}
        >
            <Grid container spacing={1} >
                <Grid item xs={12} md={7}>
                    <Box>
                        <CardMedia
                            component='video'
                            src={vid}
                            controls
                            sx={{
                                borderRadius: "38px"
                            }}>
                        </CardMedia>
                        <Typography
                            sx={{
                                mt: 1,
                                p: 1,
                                fontSize: {xs: "0.8rem", md:"15px"},
                                fontWeight: 300,
                                color: "#3D4151"
                            }}
                        >
                            {text}
                        </Typography>
                    </Box>
                </Grid>
                <Grid item xs={12} md={5}>
                    <Box
                        display="flex"
                        flexDirection="column"
                        justifyContent="center"
                        alignItems={{ xs: "center", md: "center" }}
                        height="100%"

                        sx={{
                            bgcolor: "#F5F7FE",
                            borderRadius: "38px",
                            justifyContent: "center",
                            width: "95%",
                            margin: "auto"
                        }}
                    >
                        <img
                            src={img}
                            alt="svg_image"
                            style={{ width: "70%", height: "auto", display: "block" }}
                        />
                        <Box sx={{
                            margin: "auto"
                        }}>
                            <Typography
                                variant="h4"
                                sx={{
                                    fontFamily: "Inter",
                                    fontSize:{xs:"1.5rem"},
                                    fontWeight: 700,
                                    color: "#3D4151",
                                    paddingBottom: "10px",
                                    textAlign: "center"
                                }}>
                                {title}
                            </Typography>
                            <Typography sx={{
                                fontFamily: "Inter",
                                fontSize: {xs: "0.7rem", md:"15px"},
                                fontWeight: 300,
                                color: "black",
                                textAlign: 'center'
                            }}>
                                {subtitle}
                            </Typography>
                        </Box>
                    </Box>
                </Grid>

            </Grid>
        </Box>

    </>)
}
import React, { useState, useEffect } from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Drawer from "@mui/material/Drawer";
import Menu from "@mui/material/Menu";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import Divider from "@mui/material/Divider";
import ListItemText from "@mui/material/ListItemText";
import Container from "@mui/material/Container";
import Link from "@mui/material/Link";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
// import logo from "../assets/images/logo_images/kroop_logo.png";
import logo from "../assets/images/kroop_logo.jpeg";

import MenuIcon from "@mui/icons-material/Menu";
import useScrollTrigger from "@mui/material/useScrollTrigger";

import { FeaturesMenu, UseCasesMenu } from "../components/Menus";
const pages = [
  { name: "Features", link: "/features" },
  { name: "Use Cases", link: "/use-cases" },
  { name: "Pricing", link: "/pricing-details" },
  { name: "About", link: "/about-us" },
  { name: "Blog", link: "/blog" },
];

function NavbarContainer() {
  const [openDrawer, setOpenDrawer] = React.useState(false);
  const trigger = useScrollTrigger({ threshold: 100 });
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleToggleDrawer = () => {
    setOpenDrawer(!openDrawer);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (anchorEl && !anchorEl.contains(event.target)) {
        handleMenuClose();
      }
    };

    document.addEventListener("click", handleOutsideClick);
    return () => {
      document.removeEventListener("click", handleOutsideClick);
    };
  }, [anchorEl]);

  return (
    <AppBar
      position="fixed"
      sx={{
        paddingTop: "2vh",
        boxShadow: trigger ? "0px 2px 4px rgba(0, 0, 0, 0.1)" : "none",
        bgcolor: "white",
      }}
    >
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <Link href="/">
            <Box component="img" sx={{ height: 54 }} alt="Logo" src={logo} />
          </Link>

          <Box
            sx={{
              flexGrow: 1,
              display: { xs: "none", md: "flex" },
              gap: "1rem",
              marginLeft: "2rem",
            }}
          >
            <FeaturesMenu />
            <UseCasesMenu />
            {/* ))} */}
            {pages.slice(2, 5).map((page) => (
              <Button
                className="navbar-dropdown-text"
                key={page}
                variant="text"
                style={{ color: "#3D4151" }}
                // onMouseOver={(event) => recordButtonPosition(event, page)}
                href={page.link}
              >
                {page.name}
              </Button>
            ))}
          </Box>

          <Stack
            spacing={2}
            direction="row"
            sx={{
              display: { xs: "none", md: "flex" },
              marginLeft: "auto",
            }}
          >
            <Button
              className="btn-rounded"
              variant="contained"
              color="primary"
              href="/contact-us"
              style={{
                backgroundColor: "#0267FF",
                border: "#0267FF",
                textTransform: "capitalize",
                boxShadow: "none",
              }}
            >
              Contact Us
            </Button>
            <Button
              className="btn-rounded"
              variant="outlined"
              color="secondary"
              href="https://studio.theartiste.ai/app/t2v"
            >
              Get Started
            </Button>
          </Stack>

          <Box
            sx={{
              display: { xs: "block", md: "none" },
              marginLeft: "auto",
            }}
          >
            <IconButton
              size="large"
              aria-label="menu"
              color="inherit"
              onClick={handleToggleDrawer}
            >
              <MenuIcon />
            </IconButton>
            <Drawer
              anchor="right"
              open={openDrawer}
              onClose={handleToggleDrawer}
            >
              <Box
                sx={{
                  width: 250,
                  backgroundColor: "#F6FAFD",
                  height: "100%",
                }}
                role="presentation"
                onClick={handleToggleDrawer}
                onKeyDown={handleToggleDrawer}
              >
                <Divider />
                <List>
                  {pages.map((page) => (
                    <ListItem key={page} disablePadding>
                      <ListItemButton
                        sx={{ textAlign: "center" }}
                        href={page.link}
                      >
                        <ListItemText primary={page.name} />
                      </ListItemButton>
                    </ListItem>
                  ))}
                  <Divider />
                  <ListItem>
                    <Button
                      className="btn-rounded"
                      variant="contained"
                      color="primary"
                      fullWidth
                      href="/contact-us"
                    >
                      Contact Us
                    </Button>
                  </ListItem>
                  <ListItem>
                    <Button
                      className="btn-rounded"
                      variant="outlined"
                      color="secondary"
                      fullWidth
                      href="https://studio.theartiste.ai/app/t2v"
                    >
                      Get Started
                    </Button>
                  </ListItem>
                </List>
              </Box>
            </Drawer>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
}

export default NavbarContainer;

import './App.css';
import FooterContainer from './containers/FooterContainer';
import NavbarContainer from './containers/NavbarContainer';
import LandingPageView from './views/LandingPageView';
import FAQ from './views/FAQ';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import AboutUs from './views/AboutUs';
import Features from './views/Features';
import UseCases from './views/UseCases';
import Blogs from './views/Blogs';
import { ContactUs } from './views/ContactUs';
import { TermsAndConditions } from './views/terms';
import BillingDetails from './components/BillingDetails';
import IskonCompo from './components/IskonCompo';

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<>
          <NavbarContainer />
          <LandingPageView />
          <FooterContainer />
        </>} />

        <Route path="/about-us" element={<>
          <NavbarContainer />
          <AboutUs />
          <FooterContainer />
        </>} />
        <Route path="/features" element={<>
          <NavbarContainer />
          <Features />
          <FooterContainer />
        </>} />
        <Route path="/faq" element={<>
          <NavbarContainer />
          <FAQ />
          <FooterContainer />
        </>} />
        <Route path="/use-cases" element={<>
          <NavbarContainer />
          <UseCases />
          <FooterContainer />
        </>} />
        <Route path="/blog" element={<>
          <NavbarContainer />
          <Blogs />
          <FooterContainer />
        </>} />
        <Route path="/contact-us" element={<>
          <NavbarContainer />
          <ContactUs />
          <FooterContainer />
        </>} />
        <Route path="/terms" element={<>
          <NavbarContainer />
          <TermsAndConditions />
          <FooterContainer />
        </>}/>
        <Route path="/iskcon-app" element={<>
          <NavbarContainer />
          <IskonCompo />
          <FooterContainer />
        </>}/>
        
      </Routes>
    </BrowserRouter>
  );
}

// function App() {
//   return (
//     <>
//       <NavbarContainer />
//       <LandingPageView />
//       <FooterContainer />
//     </>
//   );
// }

export default App;

import React from "react";
import { Box, Divider, Grid, Paper, Typography } from "@mui/material";
import { Container } from "reactstrap";
import LeadershipProfile from "../components/LeadershipProfile";
import Card3 from "../components/CardComponent/Card3";

import iskonDwarkaImage from "../assets/images/card_images/Rectangle 34624240.png";

const AboutUs = () => {
    const section1CardData = [
        {
            cardImage: iskonDwarkaImage,
            title: "Ximi Hoque",
            description: "Development",
        },
        {
            cardImage: iskonDwarkaImage,
            title: "Ximi Hoque",
            description: "Development",
        },
        {
            cardImage: iskonDwarkaImage,
            title: "Ximi Hoque",
            description: "Development",
        },
        {
            cardImage: iskonDwarkaImage,
            title: "Ximi Hoque",
            description: "Development",
        },
    ];
    return (
        <div style={{ backgroundColor: "#F6FAFD" }}>
            <Box
                display="flex"
                alignItems="center"
                justifyContent="center"
                minHeight="100vh"
                sx={{
                    bgcolor: "#F6FAFD",
                    p: { xs: 3, md: 5 },
                    m: { md: 4 },
                    mt: { xs: 6, md: 3 },
                }}
            >
                <Grid container spacing={2}>
                    <Grid item xs={12} md={6}>
                        <Box
                            display="flex"
                            flexDirection="column"
                            justifyContent="center"
                            alignItems="flex-start"
                            height="100%"
                        >
                            <Box
                                sx={{
                                    display: "flex",
                                    alignItems: "center",
                                }}
                            >
                                <img
                                    src={require("../assets/images/background_images/aboutUs.png")}
                                    width="100px"
                                    alt=""
                                />
                                <Typography
                                    variant="h3"
                                    sx={{
                                        color: "#3D4151",
                                        fontSize: { xs: "1rem", md: "3rem" },
                                        fontWeight: 500,
                                        ml: 2,
                                    }}
                                >
                                    ABOUT US
                                </Typography>
                            </Box>
                            <Typography
                                sx={{
                                    mt: 2,
                                }}
                            >
                                Kroop AI is an ethical synthetic data solution
                                platform. Our sophisticated audio-visual deep
                                learning based platform <br />
                                "The Artiste" generates high quality videos with
                                digital Avatars with just text as input.
                            </Typography>
                            <Typography
                                sx={{
                                    mt: 2,
                                    color: "gray",
                                }}
                            >
                                With more than 80% of the consumer internet
                                traffic is video and regional content
                                consumption is reaching all-time high, content
                                generation through Kroop AI’s Deep Neural Net
                                technology has a long way to go.
                            </Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Box
                            display="flex"
                            justifyContent="center"
                            alignItems="center"
                            height="100%"
                        >
                            <img
                                src={require("../assets/images/svg_images/5051411 1.png")}
                                alt="svg_image"
                                style={{ width: "100%", height: "auto" }}
                            />
                        </Box>
                    </Grid>
                </Grid>
            </Box>
            <Typography
                variant="h3"
                textAlign="center"
                sx={{
                    // ml: 20,
                    color: "#3D4151",
                    fontSize: { xs: "2rem", md: "4rem" },
                    fontWeight: 500,
                }}
            >
                Leadership
            </Typography>
            <hr style={{margin:"10px"}}/>
            <Box
                display="flex"
                alignItems="center"
                justifyContent="center"
                minHeight="100vh"
                sx={{
                    bgcolor: "#F6FAFD",
                    p: { xs: 0, md: 6 },
                }}
            >
                <Grid container spacing={2}>
                    <Grid item xs={12} md={6}>
                        <LeadershipProfile
                            title="Jyoti Joshi"
                            img={require("../assets/images/Jyoti1.jpg")}
                            desig="CEO and Founder"
                            description="Dr Jyoti Joshi is an AI scientist with a doctorate in affective computing from University of Canberra, Australia. She has done AI research at several academic institutions including University of Waterloo, Australian National University, University of Pittsburgh and Queen Mary University of London."
                        />
                    </Grid>
                    <Grid item xs={12} md={6} sx={{mt:{xs:-10, md:0}}}>
                        <LeadershipProfile
                            title="Naveen Sharma"
                            img={require("../assets/images/naveen.jpg")}
                            desig="Director"
                            description="Naveen Sharma is an energetic, trusted, and detail-oriented operations leader and strategic solutions provider with outstanding project management and conflict management skills having 21+ years of experience across top notch companies. Accomplished leader known and respected for leading successful projects and building credibility with clients as well as staff."
                        />
                    </Grid>
                </Grid>
            </Box>

            {/* <Box
                    sx={{
                        bgcolor: "#F6FAFD",
                        minHeight: "100vh",
                        display: "flex",
                        justifyContent: "center",
                        flexDirection: "column",
                        padding: "2rem",
                        ml:10
                    }}
                >
                    <Typography
                        variant="h4"
                        component="h2"
                        align="left"
                        sx={{
                            fontFamily: "Inter",
                            fontWeight: 700,
                            fontSize: { xs: "22px", md: "32px" },
                            color: "#3D4151",
                        }}
                    >
                        Our Leading, Strong 
                        <br/>
                        and Creative Team
                    </Typography>
                    <hr style={{
                        color: "#2B63D9",
                        backgroundColor: "#2B63D9",
                        height: "2px"}}/>


                    <Box
                        sx={{
                            display: "flex",
                            flexDirection: { xs: "column", md: "row" },
                            justifyContent: { xs: "center", md: "space-between" },
                            alignItems: "center",
                            marginTop: "2rem",
                        }}
                    >
                        {section1CardData.map((data) => (
                            <>
                                <Card3 {...data} />
                            </>
                        ))}
                    </Box>
                </Box> */}
        </div>
    );
};

export default AboutUs;
